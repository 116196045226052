import { useState, useEffect } from 'react';

// Our hook
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(
    () => {
      setIsDebouncing(true);
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setIsDebouncing(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] 
  );

  return [debouncedValue, isDebouncing];
}