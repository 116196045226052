import React, {useContext, useState} from 'react'
import { CartContext } from '../../Contexts/CartContext'
import LicenseTables from '../../Data/LicenseTables'
import LicensePickerTables from './LicensePickerTables'
import LicensePickerPackages from './LicensePickerPackages'
import LicensePickerFooter from './LicensePickerFooter'
import LicensePickerIntro from './LicensePickerIntro'
import DownloadPackage from '../../Data/DownloadPackage'
import Arr from '../../Utils/Arr'
import { useHistory } from "react-router-dom"

export default function LicensePicker(props) {
  const history = useHistory();
  const cart = useContext(CartContext);

  const defaultLicenseTable = LicenseTables.findByName(cart.lastLicenseSettings.license_table);

  const [selectedLicenseTable, setSelectedLicenseTable] = useState(defaultLicenseTable.name);
  const [selectedLicenseQuantity, setSelectedLicenseQuantity] = useState(cart.lastLicenseSettings.quantity);

  const [selectedPackageIds, setSelectedPackageIds] = useState([]);

  const handleClose = ()=> {
    cart.closeLicensePicker();
  }

  const handleChangeLicenseTable = (newLicenseTable)=>{
    setSelectedLicenseTable(newLicenseTable.name);
    setSelectedLicenseQuantity(LicenseTables.findByName(newLicenseTable.name).breakpoints[0].quantity);
  }

  const handleChangeLicenseQuantity = (quantity)=>{
    setSelectedLicenseQuantity(parseFloat(quantity));
  }

  const handleChangePackages = (packages) => {
    setSelectedPackageIds(packages);
  }


  const calculateTotalCost = () => {
    const selectedPackages = DownloadPackage.all.filter((downloadPackage)=>{
      return selectedPackageIds.includes(downloadPackage.id);
    })
    const baseCosts = selectedPackages.map((selectedPackage)=>{
      return selectedPackage.base_cost
    });
    return Arr.sum(baseCosts) * selectedLicenseQuantity;
  }

  const handleAddToCart = ()=>{
    cart.onAddToCart(selectedPackageIds, selectedLicenseTable, selectedLicenseQuantity);
    cart.closeLicensePicker();
    history.push('/checkout');
  }
  
  const totalCost = calculateTotalCost();

  if (cart.licensePickerIsOpen) {
    return (
      <div className='licensePicker'>
        <div 
          className='licensePicker--bg'
          onClick={handleClose}></div>
        <div className='licensePicker--content'>
          <div 
            className='licensePicker--main'>
            <div className='licensePicker--mainInner'>
              <LicensePickerIntro />
              <LicensePickerTables
                selectedLicenseTable={selectedLicenseTable}
                selectedLicenseQuantity={selectedLicenseQuantity}
                onChangeLicenseTable={handleChangeLicenseTable}
                onChangeLicenseQuantity={handleChangeLicenseQuantity} />

              <LicensePickerPackages
                selectedLicenseTable={selectedLicenseTable}
                selectedLicenseQuantity={selectedLicenseQuantity}
                typeface={props.typeface}
                selectedPackageIds={selectedPackageIds}
                onChangePackages={handleChangePackages} />
            </div>
          </div>
          
          <LicensePickerFooter
            totalCost={totalCost}
            selectedPackageIds={selectedPackageIds}
            onAddToCartPress={handleAddToCart} />

          <button
            className='licensePicker--closeBtn btn--normalLink'
            onClick={handleClose}>
            ×
          </button>
        </div>
      </div>
    )
  } else {
    return null;
  }
}
