const main = {
  fields: [
    'id',
    'typeface_id',
    'quantity',
    'license_table',
    'package_id',
    '_destroy'
  ],
  children: []
}
export default main;