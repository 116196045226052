import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import FontTester from './FontTester'
import Typeface from '../../Data/Typeface'
import TypefacePageInfo from './TypefacePageInfo'
import TypefacePageHeader from './TypefacePageHeader'
import TypefacePageStylesIndex from './TypefacePageStylesIndex'
import LicensePicker from '../LicensePicker/LicensePicker'
import TypefacePageIntro from './TypefacePageIntro'
import TypefacePageImages from './TypefacePageImages'
import PageSpotlight from '../Layouts/PageSpotlight'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function TypefacePage(props) {
  const typeface = Typeface.findBySlug(props.slug);
  
  useTrackPageHit(typeface ? typeface.name : 'typefacePage');
  const imageMeta = typeface.metaImagePath ? {image_path: typeface.metaImagePath} : null;
  return (
    <MainLayout 
      meta={{
        title: typeface.name,
        description: typeface.description,
        ...imageMeta
      }}
      pageName={typeface.name}
      className={`typefacePage typefacePage--${typeface.slug}`}>
      {typeface.status === 'spotlight' ? 
        <PageSpotlight
          repeatMessageTimes={5}
          message='Coming<br />2023!' />
      : null}
      <TypefacePageHeader 
        typeface={typeface} />
      <TypefacePageIntro
        typeface={typeface} />
      <TypefacePageStylesIndex typeface={typeface} />
      {typeface.showFontTester ? 
        <FontTester typeface={typeface} />
      : null}
      {typeface.images && (typeface.images.length > 0) ? 
        <TypefacePageImages
          typeface={typeface} />
      : null }
      <TypefacePageInfo
        typeface={typeface} />
      {typeface.salesActive ? 
        <LicensePicker
          typeface={typeface} />
      : null }
    </MainLayout>
  )
}