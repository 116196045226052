import React from 'react'
import Table from '../Tables/Table'
import { Link } from 'react-router-dom'
import DateHelper from '../../Utils/DateHelper'

export default function AdminOrdersList(props) {
  const orderItemsList = (order) => {
    return order.order_items.map((orderItem)=>{
      return (
        <div 
          className='adminOrders--orderItem'
          key={`oi-${orderItem.id}`}>
          <h3 className='adminOrders--orderItem--package'>
            {orderItem.package_name}
          </h3>
          <div className='adminOrders--orderItem--summary'>
            {orderItem.license_type}<br />
            {orderItem.license_quantity_label}
          </div>
        </div>
      )
    })
  }
  const formattedData = props.orders.map((order)=>{
    return {
      ...order,
      richSummary: ()=> {
        return (
          <div className='adminOrders--orderItem--summary'>
            <div className='adminOrders--orderItem--meta'>
              <div className='adminOrders--orderItem---id'>
                #{order.id} • {DateHelper.slashToFull(order.created_at_formatted_short)}
              </div>
              <div className='adminOrders--orderItem---date'>
                
              </div>
            </div>
            {orderItemsList(order)}
          </div>
          
        )
      },
      actions: ()=> {
        return (
          <Link
            className='btn'
            to={order.path}>
            View
          </Link>
        )
      }
    }
  })
  return (
    <Table
      className='adminOrders--ordersList'
      showHeader={false}
      cols={COLUMNS}
      data={formattedData}
      keyCol='id' />
  )
}
// id, date, summary, cost, view, download

const COLUMNS = [
  {
    name: 'richSummary',
    displayName: 'Summary',
    isRich: true
  },
  {
    name: 'total_cost_after_refunds_in_dollars',
    displayName: 'Total',
    prefix: '$',
    wrapWith: 'h3'
  },
  {
    name: 'actions',
    displayName: '',
    isRich: true
  }
]