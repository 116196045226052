import {React, useState} from 'react'
import NarrowLayout from '../Layouts/NarrowLayout'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api'
import ErrorMessage from '../Forms/ErrorMessage'

export default function ResetPasswordPage() {
  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [successful, setSuccessful] = useState(false);

  const handleChange = (v) => {
    setEmail(v);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    Api.post.resetPassword({
      body: {
        email: email
      },
      success: (r)=>{
        if (r.status === 200) {
          setSuccessful(true);
          setErrors(r.errors);
          setSubmitting(false);
        } else {
          setErrors(r.errors);
          setSubmitting(false);
        }
      },
      error: (m, r)=>{
        if (r && r.errors) {
          setErrors(r.errors);
        } else {
          setErrors({form: [m]});
        }
        setSubmitting(false);
      }
    })
  }
  return (
    <NarrowLayout
      className='resetPasswordPage'
      pagename='Reset Password'>
      <h2>Reset Password</h2>
      {successful ? 
        <div className='resetPasswordPage--success'>
          <div className='resetPasswordPage--successMessage'>
            Almost done. You should receive an email from us shortly. Follow the instructions on how to finish the process.
          </div>
        </div>
      : 
        <form onSubmit={handleSubmit}>
          <ErrorMessage 
            name='form'
            errors={errors} />
          <TextInput
            name='email'
            label='Email'
            onChange={handleChange}
            errors={errors} />
            
          <button 
            className='btn btn--fullWidth'
            disabled={submitting}
            errors={errors} >
            Submit
          </button>
        </form>
      }
    </NarrowLayout>
  )
}
