import { useState, useEffect, useMemo, useCallback } from "react"
import {useTimeout} from './UseTimeout'
import { usePrevious } from "./UsePrevious"

export function useIsVisible(ref, rootMargin = "0px", onPositionChange, id) {
  const [isVisible, setIsVisible] = useState(false);
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const [position, setPosition] = useState(null);
  const [initialized, setInitialized] = useState(false);


  const getPosition = useCallback((entry) => {
    const scrollOffset = window.scrollY;
    const targetBottom = entry.boundingClientRect.top + entry.boundingClientRect.height;;

    if (entry.isIntersecting) {
      return 'visible';
    } else if (scrollOffset > targetBottom) {
      return 'above';
    } else {
      return 'below';
    }
  }, []);
  
  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIsVisible(entry.isIntersecting);
        setPosition(getPosition(entry));
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }
    if (isVisible) {
      setHasBeenVisible(true);
    }
    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [ref, rootMargin, isVisible, getPosition]);

  useTimeout(() => {
    setInitialized(true);
  }, 500);

  const prevPosition = usePrevious(position);
  useEffect(() => {
    if (position !== prevPosition) {
      onPositionChange && onPositionChange(position, isVisible);
    }
  }, [position, prevPosition, isVisible, onPositionChange]);

  const lazyClass = useMemo(() => {
    if (initialized) {
      const viewedClass = hasBeenVisible ? 'lazy--viewed' : 'lazy--notViewed';
      return `lazy--ready ${viewedClass} lazy--${position}`; 
    } else {
      return 'lazy--notReady';
    }
  }, [initialized, hasBeenVisible, position]);
  

  const stickyClass = useMemo(() => {
    let className = '';
    if (initialized) {
      if (!isVisible) {
        className = 'sticky--active'
      }
    }
    return className;
  }, [initialized, isVisible]);

  return {
    isVisible, 
    hasBeenVisible, 
    viewportPosition: position, 
    lazyReady: initialized,
    lazyClass: lazyClass,
    stickyClass: stickyClass
  };
}