import React from 'react'
import Slideshow from '../Slideshow/Slideshow'

export default function TypefacePageIntro(props) {
  return (
    <div className='typefacePage--intro'>
      {props.typeface.introSlides && props.typeface.introSlides.length > 0  ? 
        <Slideshow
          slides={props.typeface.introSlides} />
      : null}
    </div>
  )
}
