import React from 'react'

export default function TypefacePageStylesIndex(props) {
  const renderStylesList = () => {
    const fontStyles = props.typeface.fontStyles.filter((typeface) => {
      return !typeface.ignoreInStyleIndex;
    })
    return fontStyles.map((style)=> {
      return (
        <div 
          key={style.name.replace(' ','-')}
          className='typefacePage--stylesIndex--style'
          style={{
            fontFamily: props.typeface.fontFamily,
            ...props.typeface.styleIndexStyle,
            ...style.style
          }}>
          {style.name}<span className='typefacePage--stylesIndex--separator'>,</span>
        </div>
      )
    })
  }
  if (props.typeface.showStylesIndex) {
    return (
      <div className='typefacePage--stylesIndex'>
        <h6>Styles</h6>
        <div className='typefacePage--stylesIndex--list'>
          {renderStylesList()}
        </div>
      </div>
    )
  } else {
    return null;
  }
}
