import React, {useState} from 'react'
import DownloadPackage from '../../Data/DownloadPackage'
import RadioButtonsInput from '../Forms/RadioButtonsInput'
import CheckboxListInput from '../Forms/CheckboxListInput'
import LicenseTables from '../../Data/LicenseTables'
import Numeral from 'numeral'


export default function LicensePickerPackages(props) {

  const [packageType, setPackageType] = useState('');
  const completePackage = DownloadPackage.typefaceCompletePackage(props.typeface.id);
  const individualPackages = DownloadPackage.typefaceIndividualPackages(props.typeface.id);

  const referenceIndividualPackage = individualPackages[0];

  const completeCost = completePackage.base_cost * props.selectedLicenseQuantity;
  const individualCost = referenceIndividualPackage.base_cost * props.selectedLicenseQuantity;

  const licenseTable = LicenseTables.findByName(props.selectedLicenseTable);
  const compareLicenseTable = LicenseTables.findCompareLicenseTable(props.selectedLicenseTable);

  const compareQuantityIndex = licenseTable.breakpoints.findIndex((opt)=> {
    return opt.quantity == props.selectedLicenseQuantity;
  })

  const compareQuantity = compareLicenseTable.breakpoints[compareQuantityIndex].quantity;

  const compareCompleteCost = completePackage.base_cost * compareQuantity;
  const compareIndividualCost = referenceIndividualPackage.base_cost * compareQuantity;

  const compareCompleteSaleCost = completePackage.normal_cost * props.selectedLicenseQuantity;

  const compareIndividualSaleCost = referenceIndividualPackage.normal_cost * props.selectedLicenseQuantity;

  const handleChangePackageType = (value) => {
    setPackageType(value);
    if (value === 'complete') {
      props.onChangePackages([completePackage.id]);
    } else {
      props.onChangePackages([]);
    }
  }

  const handleChangeIndividualPackage = (values)=> {
    props.onChangePackages(values);
  }
  const renderCompleteCostWithDiscount = () => {
    if (completePackage.on_sale) {
      return `<span className='licensePicker--package--onSale'><s>${Numeral(compareCompleteSaleCost).format('$0,0')}</s> ${Numeral(completeCost).format('$0,0')}</span>`
    } else if (licenseTable.compareDiscount) {
      return `<s>${Numeral(compareCompleteCost).format('$0,0')}</s> ${Numeral(completeCost).format('$0,0')}`
    } else {
      return `${Numeral(completeCost).format('$0,0')}`
    }
  }
  const renderIndividualCostWithDiscount = () => {
    if (referenceIndividualPackage.on_sale) {
      return `<span className='licensePicker--package--onSale'><s>${Numeral(compareIndividualSaleCost).format('$0,0')}</s> ${Numeral(individualCost).format('$0,0')}</span>`
    } else if (licenseTable.compareDiscount) {
      return `<s>${Numeral(compareIndividualCost).format('$0,0')}</s> ${Numeral(individualCost).format('$0,0')}`
    } else {
      return `${Numeral(individualCost).format('$0,0')}`
    }
  }
  return (
    <div className='licensePicker--packages'>
      <h5>
        Step 2: Select Package(s)
      </h5>
      <RadioButtonsInput
        value={packageType}
        verticalLayout={true}
        onChange={handleChangePackageType}
        options={[
          {
            label: `Complete: ${renderCompleteCostWithDiscount()}`,
            value: 'complete',
            hint: completePackage.includesVariable ? '(Includes variable font)' : null
          }, 
          {
            label: `Individual: ${renderIndividualCostWithDiscount()}/style`,
            value: 'individual'
          }
        ]}
         />
      {packageType === 'individual' ? 
        <div className='licensePicker--packages--individualOptions'>
          <CheckboxListInput
            value={props.selectedPackageIds}
            onChange={handleChangeIndividualPackage}
            options={individualPackages}
            valueKey='id'
            labelKey='shortName' />
        </div>
      : null}
    </div>
  )
}
