import {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import { UserContext } from '../Contexts/UserContext'


export const useSignedInRedirect = (path) => {
  const user = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user.signedIn) {
      history.push(`${path}`);
    }
  }, [history, user.signedIn, path]);
}
