import React, { useState, useEffect } from 'react'
import Dom from '../Utils/Dom'
import Scroll from '../Utils/Scroll'
import Str from '../Utils/Str'

export const UIContext = React.createContext();

const colorThemes = ['theme--default', 'theme--black', 'theme--peach', 'theme--blue'];

export function UIContextProvider(props) {
  const [themeNavCollapsed, setThemeNavCollapsed] = useState(false);
  const [themeNavHidden, setThemeNavHidden] = useState(false);
  const [colorThemeIndex, setColorThemeIndex] = useState(0);
  const [homeMode, setHomeMode] = useState('flashy');

  const colorTheme = colorThemes[colorThemeIndex];

  const handleThemeNavToggle = () => {
    setThemeNavCollapsed(!themeNavCollapsed);
  }
  const handleChangeThemeNavHidden = (value) => {
    setThemeNavHidden(value);
  }

  const handleChangeHomeMode = (mode) => {
    setHomeMode(mode);
    Scroll.to('body');
  }

  const handleChangeColorTheme = () => {
    const el = Dom.findFirst('html');
    Dom.removeClasses(el, colorThemes.join(' '));

    let nextColorThemeIndex = colorThemeIndex + 1;
    if (nextColorThemeIndex > colorThemes.length - 1) {
      nextColorThemeIndex = 0;
    }
    Dom.addClass(el, colorThemes[nextColorThemeIndex]);
    setColorThemeIndex(nextColorThemeIndex);
  }
  const getMonochromeFilterColor = () => {
    const theme = colorThemes[colorThemeIndex].replace('theme--', '');
    switch(theme) {
      case 'blueText':
        return "3B44AC";
        case 'blue':
          return "F4DAD9";
      case 'black':
        return "ffffff";
      case 'peach':
        return "6749ED";
      default:
        return "160F21";
    }
  }
  const monochromeFilterMatrix = () => {
    const [r, g, b] = Str.hexToRgba(getMonochromeFilterColor());
    return `
      ${1-r} 0 0 0 ${r}
      ${1-g} 0 0 0 ${g}
      ${1-b} 0 0 0 ${b}
      0 0 0 1 0`
  }
  const renderMonochromeFilter = () => {
    return (
      <svg
        className="svg-defs-only"
        aria-hidden="true">
        <filter id="monochrome-filter" colorInterpolationFilters="sRGB"
                x="0" y="0" height="100%" width="100%">
          <feColorMatrix type="matrix"
            values={monochromeFilterMatrix()} />
        </filter>
      </svg>
    )
  }

  useEffect(() => {
    const el = Dom.findFirst('html');
    Dom.addClass(el, colorThemes[0]);
  }, [])
  return (
    <UIContext.Provider value={{
      themeNavCollapsed,
      onThemeNavToggle: handleThemeNavToggle,
      themeNavHidden,
      onChangeThemeNavHidden: handleChangeThemeNavHidden,
      homeMode,
      onChangeColorTheme: handleChangeColorTheme,
      onChangeHomeMode: handleChangeHomeMode,
      colorTheme,
      colorThemeIndex
    }}>
      {renderMonochromeFilter()}
      {props.children }
    </UIContext.Provider>
  )
}
