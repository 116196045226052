import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = props => {
  const renderColumns = ()=>{
    return props.cols.map((col)=>{
      return (
        <th 
          key={col.name}
          className={`td-${col.name}`}>
          {col.displayName}
        </th>
      );
    })
  }
  return (
    <thead>
      <tr>
        {renderColumns()}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  cols: PropTypes.array
};


export default TableHeader;