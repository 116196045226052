import React, {useState} from 'react'
import Api from '../../Utils/Api'
import Url from '../../Utils/Url'
import ErrorMessage from '../Forms/ErrorMessage'
import TextInput from '../Forms/TextInput'
import Scroll from '../../Utils/Scroll'
import CheckboxInput from '../Forms/CheckboxInput'

export default function SignUpForm(props) {

  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    email: null, 
    company: null,
    password: null,
    password_confirmation: null,
    subscribe_to_newsletter_on_create: false
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleError = (e, m) => {
    if (e && e.errors) {
      setErrors(e.errors);
    } else if (m) {
      setErrors({form: [m]})
    } else {
      setErrors({
        form: 'Something went wrong. Please try again.'
      })
    }
    setSubmitting(false);
    Scroll.to('.signUpForm')
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    Api.post.user({
      body: formData,
      success: (r) => {
        if (r.status === 200) {
          if (props.redirectOnSuccess) {
            window.location = props.redirectOnSuccess
          } else if (Url.getUrlParams().r) {
            window.location = Url.getUrlParams().r
          } else {
            window.location = '/'
          }
        } else {
          handleError(r);
        }
      },
      error: (m, r) => {
        handleError(r, m);
      }
    });
  }
  const handleChange = (attr, v) => {
    setFormData({
      ...formData,
      [attr]: v
    })
  }
  return (
    <div className='signUpForm'>
      {props.hideTitle ? 
        null
      : 
        <h3>Sign Up</h3>
      }
      <div className='signInUp--toggleForm form--intro'>
        Already have an account? <button 
          onClick={props.onToggleForm}
          className='btn--normalLink'>Sign In</button>
      </div>
      <form onSubmit={handleSubmit}>
        <ErrorMessage
          name='form'
          errors={errors} />
        
        <div className='form--inputGroup'>
          <div className='form--splitInputRow'>
            <TextInput
              key='first_name'
              name='first_name'
              label='First Name'
              onChange={(v)=>{handleChange('first_name', v)}}
              errors={errors} />
            <TextInput
              key='last_name'
              name='last_name'
              label='Last Name'
              onChange={(v)=>{handleChange('last_name', v)}}
              errors={errors} />
          </div>
          <TextInput
            key='company'
            name='company'
            label='Company Name'
            onChange={(v)=>{handleChange('company', v)}}
            errors={errors} />
          <TextInput
            key='email'
            name='email'
            type='email'
            label='Email'
            onChange={(v)=>{handleChange('email', v)}}
            errors={errors} />
          <TextInput
            key='password'
            name='password'
            label='Password'
            type='password'
            onChange={(v)=>{handleChange('password', v)}}
            errors={errors} />
          <TextInput
            key='password_confirmation'
            name='password_confirmation'
            label='Confirm Password'
            type='password'
            onChange={(v)=>{handleChange('password_confirmation', v)}}
            errors={errors} />
          
          {props.hideNewsletter ? 
            null
          : 
            <CheckboxInput
              name='newsletter'
              inlineLabel='Subscribe to our newsletter for updates on new releases'
              multiline={true}
              onChange={(v) => handleChange('subscribe_to_newsletter_on_create', v)}
              />
          }
        </div>
        <div className='form--actions'>
          <button 
            className='btn'
            type="submit" 
            disabled={submitting}>
            {props.buttonText}
          </button>
        </div>
      </form>
    </div>
  )
}


SignUpForm.defaultProps = {
  buttonText: 'Submit'
}