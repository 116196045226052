import React, { useState } from 'react'
import { uid } from 'uid'

export const GrowlerContext = React.createContext();

let growlerTimer = null;

export function GrowlerContextProvider(props) {
  const [message, setMessage] = useState(null);
  
  const addMessage = (newMessage) => {
    clearTimeout(growlerTimer);
    setMessage({
      message: newMessage,
      uid: uid(8)
    });

    growlerTimer = setTimeout(()=>{
      setMessage(null);
    }, 5000);
  }
  return (
    <GrowlerContext.Provider value={{
      addMessage
    }}>
      {message ? 
        <div 
          className='growler'
          key={`growler-${message.uid}`}
          onClick={()=>{setMessage(null)}}>
          <div className='growler--message'>
            {message.message}
          </div>
        </div>
      : null }
      
      {props.children }
    </GrowlerContext.Provider>
  )
}