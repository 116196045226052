import React, {useState, useContext} from 'react'
import BackendLayout from '../Layouts/BackendLayout'
import { UserContext } from '../../Contexts/UserContext'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api';
import ErrorMessage from '../Forms/ErrorMessage';
import {useSignedOutRedirect} from '../../Hooks/UseSignedOutRedirect'
import { GrowlerContext } from '../../Contexts/GrowlerContext'

export default function AccountPasswordPage() {
  useSignedOutRedirect('/sign-in');
  const user = useContext(UserContext);
  const growler = useContext(GrowlerContext);
  const [formData, setFormData] = useState({
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  })
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleChange = (attr, value) => {
    setFormData({
      ...formData,
      [attr]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    Api.put.user({
      id: user.attributes.id,
      body: formData,
      success: (r)=>{
        if (r.status === 200) {
          growler.addMessage('Save Successful');
        }
        setErrors(r.errors);
        setSubmitting(false);
      },
      error: (message, r)=>{
        setErrors(r.errors);
        setSubmitting(false);
      }
    })
  }

  return (
    <BackendLayout
      mode='account'
      pageName='Password'
      className='accountPassword'>
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <ErrorMessage
          name='form'
          errors={errors} />
        <TextInput
          name='current_password'
          label='Current Password'
          defaultValue={formData.current_password}
          errors={errors}
          type='password'
          onChange={(v)=>{handleChange('current_password', v)}} />
        <TextInput
          key='new_password'
          label='New Password'
          name='new_password'
          defaultValue={formData.new_password}
          errors={errors}
          type='password'
          onChange={(v)=>{handleChange('new_password', v)}} />
        <TextInput
          key='new_password_confirmation'
          label='Confirm New Password'
          name='new_password_confirmation'
          defaultValue={formData.new_password_confirmation}
          errors={errors}
          type='password'
          onChange={(v)=>{handleChange('new_password_confirmation', v)}} />

        <div className='form--actions'>
          <button
            disabled={submitting}
            className='btn'>
            Save
          </button>
        </div>
      </form>
    </BackendLayout>
  )
}
