import React from 'react'
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import MetaTags from 'react-meta-tags';
import AppVars from '../../Utils/AppVars'
import Str from '../../Utils/Str'
import MetaData from '../../Data/MetaData'
const defaultMeta = MetaData.getDefaultMeta();

export default function Meta(props) {
  
  let title;

  if (props.fullTitle) {
    title = props.fullTitle;
  } else if (props.title) {
    title = `${props.title} - ${defaultMeta.title}`;
  } else {
    const segments = window.location.pathname.split('/').filter((s)=>{return !!s}).concat([defaultMeta.title]);
    const segmentTitle = segments.map((segment)=>{
      const unparameterized = Str.replaceAll(segment, '-', ' ');
      return Str.toTitleCase(unparameterized);
    }).join(' - ');
    title = segmentTitle;
  }
  const imagePath = props.image_path ? props.image_path : defaultMeta.image_path;
  const imageUrl = props.image_url ? props.image_url : `${AppVars.assetsUrl}${imagePath}`;
  
  const pageMetaData = {
    ...defaultMeta,
    url: window.location.href,
    ...props,
    title: title,
    image: imageUrl
  };

  return (
    <MetaTags>
      <title>{pageMetaData.title}</title>
      <meta name="description" content={pageMetaData.description} />

      <meta property="og:title" content={pageMetaData.title} />
      <meta property="og:type" content="image" />
      <meta property="og:url" content={pageMetaData.url} />
      <meta property="og:image" content={pageMetaData.image} />
      <meta property="og:site_name" content="Vectro Type" />
      <meta property="og:description" content={pageMetaData.description} />

      <meta name="twitter:card" content={pageMetaData.twitter_card} />
      <meta name="twitter:site" content={`@${AppVars.twitterHandle}`} />
      <meta name="twitter:title" content={pageMetaData.title} />
      <meta name="twitter:image" content={pageMetaData.image} />
      <meta property="twitter:description" content={pageMetaData.description} />
      <meta name="twitter:url" content={pageMetaData.url} />
    </MetaTags>
  );
}
