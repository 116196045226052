import React, {useContext, useState} from 'react'
import BackendLayout from '../Layouts/BackendLayout'
import Api from '../../Utils/Api'
import {UserContext} from '../../Contexts/UserContext'
import AccountOrdersList from './AccountOrdersList'
import {useSignedOutRedirect} from '../../Hooks/UseSignedOutRedirect'
import { usePagination } from '../../Hooks/UsePagination'

export default function AccountOrdersPage() {
  useSignedOutRedirect('/sign-in');
  const user = useContext(UserContext);
  const [ready, setReady] = useState(false);
  const [orders, setOrders] = useState([]);
  

  const fetchData = (page) => {
    Api.get.userOrders({
      id: user.attributes.id,
      params: {
        page: currentPage
      },
      success: (r)=>{
        if (r.status === 200){
          setOrders(r.orders);
          setTotalPages(r.total_pages);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    })
  }
  const {renderPagination, setTotalPages, currentPage} = usePagination(fetchData, 'main');

  return (
    <BackendLayout
      mode='account'
      pageName='Orders'>
      <h2>Orders</h2>
      {ready ?
        <div>
          <AccountOrdersList
            orders={orders} />
          {renderPagination()}
        </div>
      : null}
    </BackendLayout>
  )
}
