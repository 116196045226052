import React from 'react'
import PropTypes from 'prop-types'
import FontTesterStyle from './FontTesterStyle'
import FontTesterVariable from './FontTesterVariable'
import Arr from '../../Utils/Arr'

export default function FontTester(props) {
  const renderStyles = () => {
    return Arr.mapWithIndex(props.typeface.fontStyles, (fontStyle, i)=>{
      if (!fontStyle.ignoreOnTypefacePage) {
        if (fontStyle.variable) {
          return (
            <FontTesterVariable
              key={`f-${fontStyle.name}-${i}`}
              typeface={props.typeface}
              fontStyle={fontStyle} />
          )
        } else {
          return(
            <FontTesterStyle
              key={`f-${fontStyle.name}-${i}`}
              typeface={props.typeface}
              fontStyle={fontStyle}
            />
          )
        }  
      }
    });
  }
  return (
    <div className='fontTester--list'>
      {renderStyles()}
    </div>
  )
}

FontTester.propTypes = {
  fontStyles: PropTypes.array,
  typeface: PropTypes.object
}
FontTester.defaultProps = {
  fontStyles: []
}