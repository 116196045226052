import Obj from './Obj';

function isEqual(arr1, arr2) {
  return Obj.isEqual(arr1, arr2);
}
function shuffle(inA) {
  let a = [].concat(inA);
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
function uniq(inArr) {
  return inArr.filter((elem, pos, newArr) => {
    return newArr.indexOf(elem) === pos;
  });
}
function uniqBy(inArr, prop){
  return inArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}
function times(n, task){
  [...Array(n)].forEach((x,i)=>{
    task(i)
  })
}
function mapTimes(n, task){
  return [...Array(n)].map((x,i)=>{
    return task(i);
  })
}
function sum(inArr){
  if (inArr && inArr.length > 0) {
    const add = (a, b) => {
      return a + b  
    }
    return inArr.reduce(add);
  } else {
    return 0;
  }
}
function eachWithIndex(inArr, callback) {
  let i = 0;
  return inArr.forEach((v)=>{
    callback(v, i);
    i++;
  });
}
function mapWithIndex(inArr, callback) {
  let i = -1;
  return inArr.map((v)=>{
    i = i + 1;
    return callback(v, i);
    
  });
}
function insertAtIndex(inArr, index, value) {
  const length = inArr.length;
  if (index === 0) {
    return [value].concat([...inArr]);
  } else if (index >= length) {
    return inArr.concat([value]);
  } else {
    const first = inArr.slice(0, index);
    const last = inArr.slice(index);
    return first.concat([value]).concat(last);
  }
}
function removeEmpty(arr) {
  return arr.filter((v)=>{
    return (v !== undefined) && (v !== null) && (v !== '');
  })
}
function sliceIntoChunksBySize(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}
function sliceIntoChunks(arr, numChunks) {
  const chunkSize = Math.ceil(arr.length / numChunks);
  return sliceIntoChunksBySize(arr, chunkSize);
}

const opts = {
  isEqual,
  shuffle,
  sum,
  times,
  mapTimes,
  uniq,
  uniqBy,
  eachWithIndex,
  mapWithIndex,
  insertAtIndex,
  removeEmpty,
  sliceIntoChunks,
  sliceIntoChunksBySize
}

export default opts;