import React, {useContext} from 'react'
import { SyntaxConverterUIContext } from '../../../Contexts/SyntaxConverterUIContext'

export default function SyntaxConverterChartSelection() {

  const SyntaxConverterUI = useContext(SyntaxConverterUIContext);

  const renderOptions = () => {
    return SyntaxConverterUI.chartOptions.map((opt)=>{
      const optActive = opt.key === SyntaxConverterUI.selectedChart.key;
      const activeClass = optActive ? 'syntaxConverter--chartOption--active' : '';
      const activeBtnClass = optActive ? '' : 'btn--outline';

      return (
        <div 
          key={opt.key}
          className={`syntaxConverter--chartOption ${activeClass}`}>
          <button 
            className={`btn btn--superSmall ${activeBtnClass}`}
            onClick={()=>{SyntaxConverterUI.onChangeChart(opt.key)}}>{opt.label}</button>
        </div>
      )
    })
  }
  return (
    <div className='syntaxConverter--chartSelection'>
      {renderOptions()}
    </div>
  )
}
