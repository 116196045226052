import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import Meta from './Meta'
import ThemeNav from './ThemeNav'
import SiteScreensaver from '../Screensavers/SiteScreensaver'
import SiteBanner from './SiteBanner'

export default function MainLayout(props) {

  return (
    <div 
      className={`pageWrapper page--${props.pageName} ${props.layoutClassName} ${props.className}`}>
      <Meta {...props.meta} />
      <SiteBanner />
      <div className='headerMainWrap'>
        <Header
          skipSignInRedirect={props.skipSignInRedirect}
          expanded={props.expandedHeader} />
        <main>
          {props.children}
        </main>
      </div>
      <Footer />
      <ThemeNav
        showHomeThemeNav={props.showHomeThemeNav} />
      <SiteScreensaver />
    </div>
  )
}
MainLayout.propTypes = {
  pageName: PropTypes.string,
  className: PropTypes.string,
  expandedHeader: PropTypes.bool
}
MainLayout.defaultProps = {
  pageName: '',
  className: '',
  expandedHeader: false,
  layoutClassName: 'mainLayout'
}