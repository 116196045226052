import Ahoy from "ahoy.js";
import AppVars from '../Utils/AppVars'

Ahoy.configure({
  urlPrefix: AppVars.rootApiUrl,
  cookies: false,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

const event = (name, opts={}) =>{
  // console.log(name, opts)
  Ahoy.track(name, {
    path: window.location.pathname,
    ...opts
  })
}

const pageHit = (opts={}) => {
  // console.log('pageHit', opts);
  Ahoy.track("pageHit", {
    path: window.location.pathname,
    ...opts
  });
}

const main = {
  pageHit,
  event
}

export default main;