let env = process.env.NODE_ENV || 'development';

if ((env === 'production') && (window.location.origin === 'https://vectro-staging.herokuapp.com')) {
  env = 'staging';
}

let attributes = {
  env: env,
  trialDownloadPath: '/trial_downloads/download',
  eulaUrl: 'https://assets.vectrotype.com/files/Vectro-EULA-v1.pdf',
  trialEulaUrl: 'https://assets.vectrotype.com/files/Vectro-TrialFontEULA-V1.pdf',
  twitterHandle: 'vectrotype',
  instagramHandle: 'vectrotype',
  youTubeUrl: 'https://www.youtube.com/@VectroType',
  assetsUrl: 'https://assets.vectrotype.com',
  blogShowDraft: false,
  siteBanner: {
    active: false,
    message: 'Pie Day Sale: 20% off Chartwell Complete!',
    url: '/chartwell'
  }
}

let envAttributes;

if (env === 'production') {
  envAttributes = {
    rootUrl: 'https://www.vectrotype.com',
    rootApiUrl: 'https://www.vectrotype.com',
    stripeKey: 'pk_live_51JylDNIdqj2bVAnEeoY4ZDqbqCaJMiq0iTSUSxE9EZzj0Os8j52K9t4oXlqSJIq3uojQaaFOjaWyYpFmlcLGIZR400U0E86iaa',
    stripeClientId: 'ca_Las45uUsVzVWd9kgJB4AHKnFO2BolcTZ',
    placeholder: false,
    screensaverActive: true
  }
} else if (env === 'staging') {
  envAttributes = {
    rootUrl: 'https://vectro-staging.herokuapp.com',
    rootApiUrl: 'https://vectro-staging.herokuapp.com',
    stripeKey: 'pk_test_51JylDNIdqj2bVAnEUvAusFI5zv2bfLkh1oKfjfZSEeRezzaooTw3KsB1enqZZJGIFu3LXWeNWLfBtlxwdsRD5HpN00fHAteRBZ',
    placeholder: false,
    screensaverActive: true
  }
} else {
  // dev
  envAttributes = {
    rootUrl: 'http://localhost:3000',
    rootApiUrl: 'http://localhost:3001',
    // assetsUrl: 'http://localhost:8000/public',
    stripeKey: 'pk_test_51JylDNIdqj2bVAnEUvAusFI5zv2bfLkh1oKfjfZSEeRezzaooTw3KsB1enqZZJGIFu3LXWeNWLfBtlxwdsRD5HpN00fHAteRBZ',
    stripeClientId: 'ca_Las4cYmeffZYk7907RSd8veE6nHYLxWy',
    placeholder: false,
    screensaverActive: false,
    blogShowDraft: true
  }
}

envAttributes = {
  ...envAttributes,
  trialDownloadUrl: envAttributes.rootApiUrl + attributes.trialDownloadPath
}

const opts = {
  ...attributes,
  ...envAttributes
}

export default opts
