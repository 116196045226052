import OrderSchema from './OrderSchema';
import OrderItemSchema from './OrderItemSchema';

const SCHEMAS = {
  order: OrderSchema,
  order_item: OrderItemSchema
}


function format(args={}) {
  let schema = getSchema(args.customSchema || args.schema);
  return {
    ...getFieldValues(schema.fields, args.data),
    ...getChildrenValues(schema.children, args.data)
  };
}

function getFieldValues(fields, data) {
  let formattedData = {};
  fields.forEach((field)=>{
    formattedData = {
      ...formattedData,
      [field]: data[field]
    }
  })
  return formattedData;
}

function getChildrenValues(children, data) {
  let formattedData = {};
  children.forEach((child)=>{
    if (data[child.name]) {
      formattedData = {
        ...formattedData,
        [child.name + '_attributes']: getChildrenValueList(child.schemaName, data[child.name])
      }
    }
  })
  return formattedData
}

function getChildrenValueList(schemaName, childrenData) {
  let formattedData = {}
  childrenData.forEach((childData, i)=>{
    formattedData = {
      ...formattedData,
      [i]: format({
        data: childData, 
        schema: schemaName
      })
    }
  })
  return formattedData;
}

function getSchema(schemaName) {
  return SCHEMAS[schemaName];
}

const main = {
  format,
  getSchema
}

export default main;