import React from 'react'
import MainLayout from '../Layouts/MainLayout'

export default function StyleGuidePage() {
  return (
    <MainLayout>
      <div className='styleGuide'>
        <h1>This is an h1</h1>
        <h2>This is an h2</h2>
        <h3>This is an h3</h3>
        <h4>This is an h4</h4>
        <h5>This is an h5</h5>
        <h6>This is an h6</h6>

        <p>
          Paragraph before I introduce you to Kablammo, I want to take a second to acknowledge the team involved in this project. As the lead designer, I don’t want it to appear that the decisions and explorations that went into Kablammo happened entirely in my head.
        </p>
        <p>
          First: Lizy Gershenzon, my partner in business and life. As always, Lizy played a large role throughout the entire process, helping with the concept, feedback, and constantly pushing me to make things better, smarter, and more relevant. Without Lizy, this article would have been a heck of a lot shorter due to me settling on the design far too early.
        </p>
      </div>
    </MainLayout>
  )
}
