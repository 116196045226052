import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const Table = props => {
  const renderRows = ()=>{
    return props.data.map((row)=>{
      return (
        <TableRow 
          key={row[props.keyCol]}
          data={row}
          cols={props.cols} />
      );
    })
  }
  return (
    <table className={props.className}>
      {props.showHeader ? 
        <TableHeader cols={props.cols} />
      : null}
      <tbody>
        {renderRows()}
      </tbody>
      {props.renderFooter ? 
        props.renderFooter()
      : null}
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.array.isRequired,
  data: PropTypes.array,
  keyCol: PropTypes.string,
  showHeader: PropTypes.bool
};

Table.defaultProps = {
  showHeader: true
};



export default Table;