import Api from './ApiCore';

export default class Put {
  static putIt (opts){
    opts.method = 'PUT'
    Api.makeRequest(opts);
  }
  static user(opts){
    opts.url = 'users/' + opts.id;
    opts.body = {
      user: opts.body
    }
    this.putIt(opts);
  }
  static userResetStripeTempToken(opts){
    opts.url = 'users/' + opts.id + '/reset_stripe_temp_token';
    opts.body = {
      foundry: opts.body
    }
    this.putIt(opts);
  }
  static userLinkToStripe(opts){
    opts.url = 'users/' + opts.id + '/link_to_stripe';
    opts.body = {
      foundry: opts.body
    }
    this.putIt(opts);
  }
  static resetPassword(opts){
    opts.url = 'reset_password/' + opts.id;
    opts.body = {
      user: opts.body
    }
    opts.credentials = 'same-origin';
    this.putIt(opts);
  }
}
