import React, {useContext} from 'react'
import { UserContext } from '../../Contexts/UserContext'
import AppVars from '../../Utils/AppVars'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'


export default function DesignerAdminPaymentsPage() {
  const user = useContext(UserContext);

  const getStripeTempToken = (callback) => {
    Api.put.userResetStripeTempToken({
      id: user.attributes.id,
      success: (r)=>{
        if (r.stripe_temp_token) {
          callback(r.stripe_temp_token);
        }
      }
    })
  }
  const handleConnectClick = () => {
    getStripeTempToken((token)=>{
      const redirectUri = `${AppVars.rootUrl}/designer-admin/payments/stripe-connect`;
      window.location = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUri}&client_id=${AppVars.stripeClientId}&state=${token}`
    })
  }
  return (
    <BackendLayout 
      mode='designerAdmin'
      expandedHeader={true}
      pageName='designerAdminPayments'
      showHomeThemeNav={true}>
        <h2>Payments</h2>
        <div className='designerAdminPayments--intro'>Manage your connection to Stripe here, which is used to send royalties.
        </div>
        {user.attributes.linked_to_stripe  ? 
              <div>
                <div className='designerAdminPayments--connectedMessage designerAdminPayments--connectedMessage--connected'>
                  Connected to stripe
                </div>
                <button 
                  onClick={handleConnectClick}
                  className='btn'>
                  Reconnect
                 </button>
              </div>
            : 
              <div>
                <div className='designerAdminPayments--connectedMessage designerAdminPayments--connectedMessage--disconnected'>
                  Not connected to stripe
                </div>
                <button 
                  onClick={handleConnectClick}
                  className='btn'>
                  Connect to Stripe
                </button>
              </div>
            }
    </BackendLayout>
  )
}
