import React from 'react'

export default function ErrorMessage(props) {
  const formattedErrorMessage = () => {
    if (Array.isArray(props.errors[props.name])) {  
      return props.errors[props.name].join(', ');
    } else {
      return props.errors[props.name];
    }
  }
  
  if (props.errors && props.errors[props.name]) {
    return (
      <div className={`formErrors formErrors--${props.name} ${props.className}`}>
        {formattedErrorMessage()}
      </div>
    )
  } else {
    return null;
  }
}

ErrorMessage.defaultProps = {
  className: ''
}
