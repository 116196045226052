import Dom from './Dom';

function disable() {
  const htmlEl = Dom.findFirst('html');
  Dom.addClass(htmlEl, 'disableScroll');
}
function enable() {
  const htmlEl = Dom.findFirst('html');
  Dom.removeClass(htmlEl, 'disableScroll');
}
function disableInMobile() {
  const htmlEl = Dom.findFirst('html');
  Dom.addClass(htmlEl, 'disableScrollMobile'); 
}
function enableInMobile() {
  const htmlEl = Dom.findFirst('html');
  Dom.removeClass(htmlEl, 'disableScrollMobile');
}
function to(inTarget, opts={}) {
  const target = Dom.findFirst(inTarget);
  const offset = opts.offset || 0;
  const duration = opts.duration || 300;
  if (target) {
    const targetY = target.getBoundingClientRect().top + window.scrollY;
    animateTo(targetY + offset, duration)
  }
}
function animateTo(targetY, duration) {
  const startingY = window.pageYOffset  
  const diff = targetY - startingY;
  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1)

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}
function parentToChild(parent, child) {
  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  
  const isViewable = (childRect.bottom <= parentRect.bottom) && (childRect.top >= parentRect.top );

  if (!isViewable) {
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        parent.scrollTop += scrollTop;
    } else {
        parent.scrollTop += scrollBot;
    }
  }
}

const main = {
  disable,
  enable,
  disableInMobile,
  enableInMobile,
  to,
  animateTo,
  parentToChild
}

export default main;