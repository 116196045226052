import { useEffect } from 'react';

export function useKeydown(keyCode, action) {
  useEffect(() => {
    function onKeydown(e) {
      if (e.keyCode === keyCode) action()
    }
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [keyCode, action]);
}