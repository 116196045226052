import React, {useContext, useState} from 'react'
import {UserContext} from '../../Contexts/UserContext'
import { Link } from 'react-router-dom'
import TextInput from '../Forms/TextInput'
import ErrorMessage from '../Forms/ErrorMessage'
import Scroll from '../../Utils/Scroll'

export default function SignInForm(props) {
  const user = useContext(UserContext);
  const [formData, setFormData] = useState({
    email: null,
    password: null
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  
  const handleError = (e) => {
    setErrors(e);
    setSubmitting(false);
    Scroll.to('.signInForm');
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    user.onSignIn(formData, {
        redirectOnSuccess: props.redirectOnSuccess,
        onError: handleError
      }
    );
  }
  const handleChange = (attr, v) => {
    setFormData({
      ...formData,
      [attr]: v
    })
  }
  return (
    <div className='signInForm'>
      {props.hideTitle ? 
        null
      : 
        <h3>Sign In</h3>
      }
      <div className='signInUp--toggleForm form--intro'>
        Need an account? <button 
          onClick={props.onToggleForm}
          className='btn--normalLink'>Sign Up</button>
      </div>
      <form onSubmit={handleSubmit}>
        <ErrorMessage 
          name='form'
          errors={errors} />
        
        <div className='form--inputGroup'>
          <TextInput
            key='email'
            name='email'
            label='Email'
            onChange={(v)=>{handleChange('email', v)}}
            errors={errors} />
          <TextInput
            key='password'
            name='password'
            label='Password'
            type='password'
            onChange={(v)=>{handleChange('password', v)}}
            errors={errors} />
          <Link 
            className='signInForm--forgotPasswordLink' 
            to='/reset-password'
            target='_blank'
            rel="noreferrer">
            Forgot Password?
          </Link>
        </div>
        <div className='form--actions'>
          <button 
            className='btn'
            type="submit"
            disabled={submitting}>
            {props.buttonText}
          </button>
        </div>
      </form>
    </div>
  )
}

SignInForm.defaultProps = {
  buttonText: 'Submit'
}