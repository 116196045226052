import React from 'react'
import Arr from '../../Utils/Arr'
import CheckboxInput from './CheckboxInput'

export default function CheckboxListInput(props) {
  const handleChange = (option, checked) => {
    if (checked) {
      props.onChange(props.value.concat([option[props.valueKey]]))
    } else {
      props.onChange(props.value.filter((v)=>{
        return v !== option[props.valueKey]
      }));
    }
  }
  const renderOptions = () => {
    return Arr.mapWithIndex(props.options, (option, i) => {
      return (
        <CheckboxInput
          key={`opt-${i}-${option.value}`}
          inlineLabel={option[props.labelKey]}
          defaultChecked={props.value.includes(option[props.valueKey])}
          onChange={(v)=>{handleChange(option, v)}}
           />
      )
    })
  }
  return (
    <div className={`formInput checkboxList`}>
      <div className='checkboxList--label'>
        {props.label}
      </div>
      <div className='checkboxList--options'>
        {renderOptions()}
      </div>
    </div>
  )
}


CheckboxListInput.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  options: []
}

// <CheckboxListInputOption
// key={`opt-${i}-option.value`}
// option={option}
// valueKey={props.valueKey}
// labelKey={props.labelKey} />