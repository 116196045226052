import React from 'react'
import NewsletterForm from '../NewsletterForm/NewsletterForm'
import ThreeDScreensaver from '../Screensavers/ThreeDScreensaver'
import Meta from '../Layouts/Meta'

export default function PlaceholderPage() {
  return (
    <div className="placeholder">
      <Meta />
      <ThreeDScreensaver />
      <NewsletterForm
        label="GET UPDATES" />
    </div>
  )
}