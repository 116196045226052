import React from 'react'
import RangeInput from '../Forms/RangeInput'

export default function FontTesterControls(props) {
  return (
    <div className='fontTester--controls'>
      <RangeInput
        key='fontSize'
        name='fontSize'
        label='Size'
        min={props.minFontSize}
        max={props.maxFontSize}
        value={props.fontSize}
        className='rangeInput--small'
        onChange={(v)=> props.onChange({fontSize: v})} />
      <RangeInput
        key='lineHeight'
        name='lineHeight'
        label='Leading'
        min={props.minLineHeight}
        max={props.maxLineHeight}
        value={props.lineHeight}
        className='rangeInput--small'
        onChange={(v)=> props.onChange({lineHeight: v})} />
    </div>
  )
}

FontTesterControls.defaultProps = {
  minFontSize: .7,
  maxFontSize: 80,
  minLineHeight: .4,
  maxLineHeight: 3
}