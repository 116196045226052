import Api from './ApiCore';

export default class Get {
  static getIt(opts){
    opts.method = 'get'
    Api.makeRequest(opts);
  }
  static currentUser(opts){
    opts.url = 'users/current';
    this.getIt(opts);
  }
  static userPaymentMethod(opts){
    opts.url = `users/${opts.id}/payment_method`
    this.getIt(opts);
  }
  static userOrders(opts){
    opts.url = `users/${opts.id}/orders`;
    this.getIt(opts);
  }
  static paymentMethodsSetupIntent(opts){
    opts.url = 'payment_methods/setup_intent';
    this.getIt(opts);
  }
  static order(opts){
    opts.url = `orders/${opts.id}`;
    this.getIt(opts);
  }
  static adminOrders(opts){
    opts.url = `admin/orders`;
    this.getIt(opts);
  }
  static adminTrialDownloads(opts){
    opts.url = `admin/trial_downloads`;
    this.getIt(opts);
  }
  static designerOrders(opts){
    opts.url = `designers/${opts.id}/orders`;
    this.getIt(opts);
  }
  static designerTypefaces(opts){
    opts.url = `designers/${opts.id}/typefaces`;
    this.getIt(opts);
  }
  static designerSalesReports(opts){
    opts.url = `designers/sales_reports`;
    this.getIt(opts);
  }
}
