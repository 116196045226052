import React, {useState, useRef} from 'react'
import MainLayout from '../Layouts/MainLayout'
import TextInput from '../Forms/TextInput'
import RangeInput from '../Forms/RangeInput'
import Typeface from '../../Data/Typeface'
import TesterPageFontStyle from './TesterPageFontStyle'
import {useIsVisible } from '../../Hooks/UseIsVisible'
import { useDebounce } from '../../Hooks/UseDebounce'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function TesterPage() {
  useTrackPageHit('Tester');
  
  const [text, setText] = useState();
  const [fontSize, setFontSize] = useState(8);
  const [debouncedFontSize, isDebouncing] = useDebounce(fontSize, 300);

  const ref = useRef();
  const {isVisible, stickyClass} = useIsVisible(ref, "100px");

  const handleChangeText = (v) => {
    setText(v);
  }
  const handleChangeFontSize = (v) => {
    setFontSize(v);
  }
  const renderFontList = () => {
    return Typeface.getAllFontStyles().map((fontStyle)=>{
      return (
        <TesterPageFontStyle
          key={fontStyle.uid}
          typeface={fontStyle.typeface}
          fontStyle={fontStyle.fontStyle}
          text={textWithFallback}
          fontSize={fontSize}
          throttledFontSize={debouncedFontSize}
          isFontSizeThrottling={isDebouncing}  />
      )
    })
  }
  const textWithFallback = text || 'Amazing vibrational waves quickly jumped like the fox. Right behind them a zebra casually followed.';

  const renderControls = (isSticky) => {
    const keyName = isSticky ? 'stickyInput' : 'mainInput'
    return (
      <div
        className='testerPage--controls'>
        <TextInput
          name='text'
          key={`${keyName}--${isVisible}`}
          className='textInput--pill'
          defaultValue={text}
          placeholder='Enter text'
          onChange={handleChangeText}
          autoComplete={false} />
        <RangeInput
          key='fontSize'
          name='fontSize'
          label='Font Size'
          min={1}
          max={25}
          value={fontSize}
          onChange={handleChangeFontSize} />
      </div>
    )
  }
  return (
    <MainLayout 
      expandedHeader={true}
      pageName='tester'
      showHomeThemeNav={true}>
      <div className={`testerPage`}>
        <div
          ref={ref}
          className='testerPage--mainControls'>
          {renderControls(false)}
        </div>
        <div
          className={`testerPage--stickyControls ${stickyClass}`}
          aria-hidden="true">

          {renderControls(true)}
        </div>
        <div className='testerPage--fontList'>
          {renderFontList()}
        </div>
      </div>
    </MainLayout>
  )
}
