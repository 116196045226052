import React, {useContext} from 'react'
import RadioButtonsInput from '../Forms/RadioButtonsInput'
import { CartContext } from '../../Contexts/CartContext'
import TextInput from '../Forms/TextInput'
import CheckboxInput from '../Forms/CheckboxInput'
import AppVars from '../../Utils/AppVars'

export default function CheckoutLicensee(props) {
  const cart = useContext(CartContext);

  const renderEulaLabel = () => {
    return (
      <span>
        I have read and agree to <a href={`${AppVars.eulaUrl}`} target="_blank" rel="noreferrer">End User License Agreement (EULA)</a> on behalf of the license holder named above, and represent and warrant that I am authorized by the license holder to do so.
      </span>
    )
  }

  if (props.isActive) {
    return (
      <div className='checkout--licensee'>
        <RadioButtonsInput
          value={cart.fontsFor}
          options={options}
          onChange={cart.onChangeFontsFor} />
      {cart.fontsFor === 'other' ? 
        <TextInput
          name='other_licensee'
          label='Company Name*'
          onChange={cart.handleChangeOtherLicensee}
          hint='Personal names are acceptable if a company name isn’t applicable.'  />
      : null }
      <CheckboxInput
        name='agreed'
        renderInlineLabelContents={renderEulaLabel}
        multiline={true}
        onChange={props.onChangeAgreedToEula} />
      </div>
    )
  } else {
    return null;
  }
  
}
const options = [
  {
    label: 'The fonts are for myself',
    value: 'self'
  },
  {
    label: 'The fonts are for someone else',
    value: 'other'
  }
]