import React, { useContext} from 'react'
import { Link, NavLink } from 'react-router-dom'
import { CartContext } from '../../Contexts/CartContext'
import { UserContext } from '../../Contexts/UserContext'
import Dropdown from '../Dropdown/Dropdown'
import {ReactComponent as CartIcon} from '../../Svg/Cart-Icon.svg';
import {ReactComponent as ProfileIcon} from '../../Svg/Profile-Icon.svg';
import Url from '../../Utils/Url'


export default function Header(props) {
  const user = useContext(UserContext);
  const cart = useContext(CartContext);
  
  const signInRedirectUrl = props.skipSignInRedirect ? '' : Url.addParamsToCurrentUrl({r: Url.removeParamFromUrl(window.location.href, 'r')});

  const adminOptions = () => {
    if (user.roles.includes('admin')) {
      return [
        {
          href: '/admin/orders',
          label: 'Admin'
        }
      ]
    } else {
      return [];
    }
  }
  const designerOptions = () => {
    if (user.roles.includes('designer')) {
      return [
        {
          href: '/designer-admin/typefaces',
          label: 'Admin'
        }
      ]
    } else {
      return [];
    }
  }
  const dropdownOptions = [
    {
      href: '/account/orders',
      label: 'Orders'
    },
    {
      href: '/account/account',
      label: 'Account'
    },
    ...designerOptions(),
    ...adminOptions(),
    {
      onClick: user.onSignOut,
      label: 'Sign Out'
    }
  ]

  // const fontsActiveClass = Url.anySegmentsActiveInPath(['','tester','fonts'], location.pathname) ? 'active' : '';
  
  return (
    <header>
      
      <nav>
        <Link 
          to='/'
          className='nav--logo'>
          Vectro
        </Link>
        <NavLink 
          key='home'
          to='/fonts'>
          <div className='nav--radioBtn'></div>Fonts
        </NavLink>
        <NavLink 
          key='tester'
          to='/tester'>
          <div className='nav--radioBtn'></div>Tester
        </NavLink>
        <NavLink 
          key='blog'
          to='/blog'>
          <div className='nav--radioBtn'></div>Blog
        </NavLink>
        <NavLink 
          key='studio'
          to='/studio'>
          <div className='nav--radioBtn'></div>Studio
        </NavLink>
        <div className='nav--account'>
          {user.signedIn ?
            <Dropdown
              options={dropdownOptions}>
              <ProfileIcon 
                className='nav--accountIcon icon' />
            </Dropdown>
          : 
            <Link 
              key='sign-in' 
              to={`/sign-in${signInRedirectUrl}`}><ProfileIcon 
              className='nav--accountIcon icon' /></Link>
          }
        </div>
        {cart.items.length > 0 ?
          <Link
            to='/checkout'
            className='nav--cartBtn' >
            <CartIcon
              className='nav--cartIcon icon' />
              {cart.items.length}
          </Link>
        : null }
      </nav>
      
    </header>
  )
}
