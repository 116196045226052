import LicenseTables from './LicenseTables.json'

const findByName = (name) => {
  return LicenseTables.find((table)=> {
    return table.name === name
  })
}
const licenseTables = LicenseTables.map((table) => {
  return {
    label: table.label,
    name: table.name
  }
});

const allLive = LicenseTables.filter((table)=> {
  return table.live;
})

const licenseTablesForForms = LicenseTables.map((table) => {
  return {
    label: table.label,
    value: table.name
  }
});

const breakpointSummary = (tableName, quantity) => {
  const table = findByName(tableName);
  const breakpoint = table.breakpoints.find((bp)=>{
    return bp.quantity === quantity;
  })
  return `${table.label}, ${breakpoint.label}`
}

const findCompareLicenseTable = (tableName) => {
  const table = findByName(tableName);
  const compareTable = findByName(table.compareDiscountTo);
  return compareTable;
}

const main = {
  all: LicenseTables,
  allLive: allLive,
  findByName,
  licenseTables,
  licenseTablesForForms,
  breakpointSummary,
  findCompareLicenseTable
}

export default main;