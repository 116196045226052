import { useEffect, useState } from 'react';
import Pagination from '../Components/Pagination/Pagination';
import Url from '../Utils/Url';

export const usePagination = (onChange, scrollToOnChange, showIfUnnecessary) => {
  const paramsCurrentPage = Url.getUrlParams().page || 1;
  const [currentPage, setCurrentPage] = useState(0);

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (paramsCurrentPage !== currentPage) {
      onChange && onChange(paramsCurrentPage);
      setCurrentPage(paramsCurrentPage);
    }
  }, [onChange, currentPage, paramsCurrentPage]);

  const renderPagination = () => {
    if ((totalPages > 1) || showIfUnnecessary) {
      return (
        <Pagination 
          page={currentPage}
          scrollToElOnChange={scrollToOnChange}
          totalPages={totalPages} />
      )
    }
  }

  return {
    renderPagination,
    setTotalPages,
    currentPage
  }

}