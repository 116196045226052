import MetaDataData from './MetaData.json'

const ALL = MetaDataData;

const findByName = (name) => {
  return ALL.find((page)=> {
    return page.name === name;
  }).meta;
}
const getDefaultMeta = () => {
  return findByName('default');
}

const main = {
  all: ALL,
  findByName,
  getDefaultMeta
}

export default main;
