import {useEffect, useState} from 'react'
import {useHistory, withRouter} from 'react-router-dom'


export const ScrollState = (router) => {
  const history = useHistory();
  const [prevRoute, setPrevRoute] = useState(router.location);

  useEffect(() => {
    const unlisten = history.listen((route) => {
      const skip = prevRoute && prevRoute.pathname === route.pathname;
      if (!skip) {
        window.scrollTo(0, 0);
      }
      setPrevRoute(route)
    });
    return () => {
      unlisten();
    }
  }, [history, setPrevRoute, prevRoute]);

  return (null);
}

export default withRouter(ScrollState);