import React from 'react'

export default function CheckoutCurrentPaymentMethod(props) {
  return (
    <div className='checkout--currentPaymentMethod'>
      {props.paymentMethod ? 
        <div className='checkout--currentPaymentMethod--existingCardDetails'>
          <div className='checkout--currentPaymentMethod--existingBrandAndEnding'>
            <span className='checkout--currentPaymentMethod--existingBrand'>{props.paymentMethod.brand}</span> <span className='checkout--currentPaymentMethod--existingEnding'>ending in {props.paymentMethod.last4}</span>
          </div>
          <div className='checkout--currentPaymentMethod--existingExpires'>
            Expires {props.paymentMethod.exp_month}/{props.paymentMethod.exp_year}
          </div>
        </div>
      : null}
      <button 
        className='btn--normalLink'
        onClick={props.onChangePaymentMethodClick}>
        Use a different card
      </button>
    </div>
  )
}
