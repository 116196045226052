import React from 'react'

export default function CheckoutSection(props) {
  const activeClass = () => {
    if (props.activeStep === props.step) {
      return 'checkout--section--active';
    } else if (props.activeStep > props.step) {
      return 'checkout--section--previous';
    } else {
      return ''
    }
  }
  
  return (
    <div className={`checkout--section checkout--section${props.step} ${activeClass()}`}>
      <h3>{props.step}. {props.title}</h3>
      {(props.activeStep >= props.step) ? 
        React.cloneElement(props.children, {
          step: props.step,
          isActive: props.step === props.activeStep
        })
      : null }
      
    </div>
  )
}
