import React, {useState} from 'react'
import TextInput from '../Forms/TextInput'
import CheckboxInput from '../Forms/CheckboxInput'
import AppVars from '../../Utils/AppVars'
import Url from '../../Utils/Url'
import Scroll from '../../Utils/Scroll'

export default function TrialFontsForm() {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    potential_use: null,
    newsletter: false,
    agreed: false
  })
  const readyToSubmit = formData.agreed && formData.potential_use;

  const handleChange = (attr, value) => {
    setFormData({
      ...formData,
      [attr]: value
    })
  }
  const handleSubmit = (e)=> {
    e.preventDefault();
    Scroll.to('body')
    setSubmitted(true);
    const urlParams = Url.encodeParams({
      potential_use: formData.potential_use,
      newsletter: formData.newsletter
    })
    window.location = AppVars.trialDownloadUrl + urlParams
  }
  const renderAgreementLabel = () => {
    return (
      <span>
        I have read and agree to the <a href={`${AppVars.trialEulaUrl}`} target="_blank" rel="noreferrer">Trial Font End User License Agreement (EULA)</a>
      </span>
    )
  }
  return (
    <div className='trialFonts--form'>
      <form onSubmit={handleSubmit}>
        {submitted ?
          <div className='trialFonts--formSubmitted'>
            <div className='form--intro'>
              Thanks, your download should start shortly!
            </div>
          </div>
        :
          <div className='trialFonts--formUnsubmitted'>
            <div className='form--intro'>
            <p>Trial fonts are for mock up and evaluation purposes only. The included font files have a limited character set, and missing features that are included in the full paid version.
  </p>

    <p>Student work for school is permitted, but not the student’s commercial work.</p>

    <p>Social media for personal projects is also permitted with attribution of the font and a shoutout to @vectrotype.</p>
              </div>
            <div className='form--inputGroup'>
              <TextInput
                label='Potential Use'
                name='potential_use'
                hint='Project or reason for downloading trials'
                onChange={(v)=>handleChange('potential_use', v)}
                />
              <CheckboxInput
                key='agreed'
                name='agreed'
                multiline={true}
                renderInlineLabelContents={renderAgreementLabel}
                onChange={(v)=>handleChange('agreed', v)}  />
              <CheckboxInput
                key='newsletter'
                name='newsletter'
                multiline={true}
                inlineLabel='Sign me up to the newsletter to get updates about new releases and other happenings at Vectro'
                onChange={(v)=>handleChange('newsletter', v)}  />
            </div>
            <button
              type='submit'
              className='btn btn--fullWidth'
              disabled={!readyToSubmit} >
              Download All Trial Fonts
            </button>
          </div>
        }
      </form>
    </div>
  )
}
