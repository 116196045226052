import React from 'react'
import HomeCard from './HomeCard'
import Typeface from '../../Data/Typeface'
import Arr from '../../Utils/Arr'

export default function HomeCards() {
  const renderCards = () => {
    return Arr.shuffle(Typeface.allHomeFlashy).map((typeface)=> {
      return (
        <HomeCard
          key={typeface.id}
          typeface={typeface} />
      )
    })
  }
  return (
    <div className="home--cards">
      {renderCards()}
    </div>
  )
}
