import React, {useContext} from 'react'
import { SyntaxConverterUIContext } from '../../../Contexts/SyntaxConverterUIContext'
import SyntaxConverterPreviewChart from './SyntaxConverterPreviewChart'

export default function SyntaxConverterPreview() {
  const SyntaxConverterUI = useContext(SyntaxConverterUIContext);

  return (
    <div className='syntaxConverter--preview'>
      <SyntaxConverterPreviewChart
        altFormulaGroupedValues={SyntaxConverterUI.altFormulaGroupedValues}
        selectedChartKey={SyntaxConverterUI.selectedChartKey}
        separateCoreValues={SyntaxConverterUI.shouldSeparateCoreValuesSelected}
        formula={SyntaxConverterUI.formula} />
    </div>
  )
}
