import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import ErrorMessage from './ErrorMessage'
import TextInput from './TextInput'
import { uid } from 'uid'
import {useKeydown} from '../../Hooks/UseKeydown'
import Arr from '../../Utils/Arr'
import Dom from '../../Utils/Dom'
import Scroll from '../../Utils/Scroll'
import { useClickOutside } from '../../Hooks/UseClickOutside'

export default function SelectInputPlus(props) {
  const wrapperEl = useRef(null);
  const prevHighlightIndexRef = useRef(0);
  const [searchVal, setSearchVal] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleFocus = () => {
    setIsSearching(true);
  }
  const handleSearchChange = (v) => {
    setIsSearching(true);
    setSearchVal(v);
  }
  const [inputKey, setInputKey] = useState(uid(8));
  const [highlightIndex, setHighlightIndex] = useState(0);

  const handleArrowUp = () => {
    if (isSearching && (highlightIndex > 0)) {
      setHighlightIndex(highlightIndex - 1);
    }
  }
  const handleArrowDown = () => {
    if (isSearching && (highlightIndex < (filteredOptions()).length - 1)) {
      setHighlightIndex(highlightIndex + 1);
    }
  }
  const handleEnterPress = () => {
    if (isSearching) {
      handleSelect(filteredOptions()[highlightIndex]);
    }
  }
  useKeydown(38, handleArrowUp);
  useKeydown(40, handleArrowDown);
  useKeydown(13, handleEnterPress);

  const scrollToHighlighted = () => {
    if (isSearching) {
      const el = Dom.findByClass('.selectInputPlus--option')[highlightIndex];
      Scroll.parentToChild(wrapperEl.current, el, highlightIndex);
    }
  }

  const handleSelect = (option) => {
    props.onChange && props.onChange(option.code);
    handleCloseOptions();
  }
  const filteredOptions = () => {
    return props.options.filter((option)=> {
      return option[props.searchKey].toLowerCase().startsWith(searchVal.toLowerCase());
    })
  }
  const handleCloseOptions = () => {
    setInputKey(uid(8));
    setIsSearching(false);
    setHighlightIndex(0);
  }
  const renderOptions = () => {
    return Arr.mapWithIndex(filteredOptions(), (option, i)=>{
        const highlightClass = (i === highlightIndex) ? 'selectInputPlus--option--highlighted' : '';
        return (
          <li 
            key={`opt-${option[props.valueKey]}`}
            className={`selectInputPlus--option ${highlightClass}`}
            onClick={()=>{handleSelect(option)}}>
            {option[props.labelKey]}
          </li>
        )
    });
  }
  const selectedOption = props.options.find((option) => {
    return option[props.valueKey] === props.value;
  })
  const selectedValue = selectedOption ? selectedOption[props.labelKey] : null;

  const clickOutsideRef = useRef(null);
  useClickOutside(clickOutsideRef, handleCloseOptions);

  useEffect(() => {
    if (highlightIndex !== prevHighlightIndexRef.current) {
      prevHighlightIndexRef.current = highlightIndex;
      scrollToHighlighted();
    }
  });

  return (
    <div 
      ref={clickOutsideRef}
      className='formInput selectInputPlus'>
      <label htmlFor={props.name}>{props.label}</label>
      <div className='selectInputPlus--inputWrapper'>
        <TextInput 
          key={`${inputKey}-${props.value}`}
          defaultValue={selectedValue}
          name={`selectSearch--${props.name}`}
          onChange={handleSearchChange}
          onFocus={handleFocus}
          tabIndex={props.tabindex}
          autoComplete={false} />
        {isSearching ? 
          <ul 
            ref={wrapperEl}
            className='selectInputPlus--options'>
            {renderOptions()}
          </ul>
        : null}
      </div>

      <ErrorMessage 
        name={props.name}
        errors={props.errors} />
    </div>
  )
}

SelectInputPlus.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  size: PropTypes.string
}
SelectInputPlus.defaultProps = {
  options: [],
  valueKey: 'value',
  labelKey: 'label',
  searchKey: 'label'
}
