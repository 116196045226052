import React, {useState, useEffect, useCallback} from 'react'
import PrintLayout from '../Layouts/PrintLayout'
import Api from '../../Utils/Api'
import Table from '../Tables/Table'
import {useSignedOutRedirect} from '../../Hooks/UseSignedOutRedirect'
import Typeface from '../../Data/Typeface'
import { Link } from 'react-router-dom'
import AppVars from '../../Utils/AppVars'
import Vat from '../../Data/Vat'

export default function OrderPrintPage(props) {
  useSignedOutRedirect('/sign-in');
  const [order, setOrder] = useState(null);
  const [ready, setReady] = useState(false);

  const fetchData = useCallback(() => {
    Api.get.order({
      id: props.match.params.orderId,
      success: (r)=>{
        setReady(true);
        setOrder(r.order);
      },
      error: (m,r)=>{
        setReady(true);
      }
    })
  }, [props.match.params.orderId]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formattedData = ()=> {
    if (order) {
      return order.order_items.map((orderItem)=>{
        const typeface = Typeface.find(orderItem.typeface_id);
        return {
          ...orderItem,
          richSummary: () => {
            return (
              <div>
                <h3>
                  <Link
                    to={typeface.path}>
                      {orderItem.package_name}
                  </Link>
                </h3>
                {orderItem.license_type}<br />
                {orderItem.license_quantity_label}
              </div>
            )
          },
          cost: () => {
            return (
              <h3>${orderItem.total_cost}</h3>
            )
          }
        }
      })
    } else {
      return [];
    }
  }
  const renderFooter = () => {
    return (
      <tfoot>
        <tr>
          <td colSpan="2" className='td-total_cost'>
            {order.refund_total > 0 ?
              <div className='orderPage--refundTotals'>
                Subtotal ${order.total_cost_in_dollars}<br />
                Refund Amount: ${order.refund_total_in_dollars}
              </div>
            :  
              null
            }
            <h3>Total ${order.total_cost_after_refunds_in_dollars}</h3>
          </td>
          <td></td>
        </tr>
      </tfoot>
    )
  }
  const shouldRenderVat = (order && order.payment_method) ? Vat.shouldCollect(order.payment_method.country) : null;

  return (
    <PrintLayout
      pageName='Order'
      className='orderPage'>
        {ready ? 
          <div>
            <div className='orderPage--titleRow'>
              <h2>Order Invoice</h2>
            </div>
            {order ? 
              <div className='orderPage--main'>
                <div className='orderPage--info'>
                  <div className='orderPage--infoSection'>
                    <h4>Order ID</h4>
                    <p>
                      #{order.id}
                    </p>

                    <h4>Date</h4>
                    <p>
                      {order.created_at_formatted}
                    </p>

                    <h4>Status</h4>
                    <p>Paid in full</p>

                    <h4>Licensed To</h4>
                    <p>{order.licensee}
                    </p>

                    <h4>Customer Email</h4>
                    <p>{order.user.email}</p>
                  </div>
                  <div className='orderPage--infoSection'>
                    
                    <h4>Billed To</h4>
                    <p>
                      {order.payment_method.name}<br />
                      {order.payment_method.address_1}<br />
                      {order.payment_method.address_2 ? 
                        <span>{order.payment_method.address_2}<br />
                        </span>
                      : null}
                      {order.payment_method.city}, {order.payment_method.state} {order.payment_method.zip}<br />
                      {order.payment_method.country}
                      {order.payment_method.vat ? 
                        <span><br />VAT {order.payment_method.vat}</span>
                      : null}
                    </p>

                    <h4>Payment Method</h4>
                    <p>
                    {order.payment_method.brand.toUpperCase()} ending in ***{order.payment_method.last4}<br />
                    Exp. {order.payment_method.exp_month}/{order.payment_method.exp_year}
                      


                    </p>
                  </div>
                  <div className='orderPage--vectroInfo'>
                    <div className='orderPage--vectroInfoInnerWrap'>
                      Vectro Type Foundry<br />
                      4621 NE 76th Ave<br />
                      Portland, OR 97218<br />
                      United States
                      {shouldRenderVat ? 
                        <span><br />VAT EU372006503</span> 
                      : null}
                    </div>
                  </div>
                </div>
                <Table
                  cols={COLUMNS}
                  data={formattedData()}
                  keyCol='id'
                  showHeader={false}
                  renderFooter={renderFooter} />
                
              </div>
            : 
              <div>
                Sorry, we can’t find your order.
              </div>
            }
          </div>
        : null }
      
    </PrintLayout>
  )
}

const COLUMNS = [
  {
    name: 'richSummary',
    isRich: true
  },
  {
    name: 'cost',
    prefix: '$',
    isRich: true
  }
]