import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import PrivacyContent from '../../Data/Legal/Privacy.md'
import Markdown from '../Markdown/Markdown'


export default function StudioPage() {
  return (
    <MainLayout
      pageName='privacy'>
      <div className='basicPage'>
        <div className='privacyContent basicPage--content'>
          <Markdown file={PrivacyContent} />
        </div>
      </div>
    </MainLayout>
  )
}
