import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'
import {SyntaxConverterContextProvider} from '../../Contexts/SyntaxConverterUIContext'
import SyntaxConverter from './SyntaxConverter/SyntaxConverter'


export default function StudioPage() {
  useTrackPageHit('Studio');
  return (
    <SyntaxConverterContextProvider>
      <MainLayout
        pageName='chartwellTools'>
        <div className='chartwellToolsPage'>
          <div className='chartwellToolsPage--overview'>
            <h3>Chartwell Tools</h3>
          </div>
          <div className="chartwellToolsPage--main">
            <SyntaxConverter />
          </div>
        </div>
      </MainLayout>
    </SyntaxConverterContextProvider>
  )
}
