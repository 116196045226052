import React from 'react'
import {Link} from 'react-router-dom'
import BlogPost from '../../Data/BlogPost'
import Arr from '../../Utils/Arr'

export default function HomeScroller() {
  const post = BlogPost.allWithStatus('live')[0];
  const renderLinks = () => {
    return Arr.mapTimes(20, (i) => {
      return (
        <Link 
          key={`l-${i}`}
          to={post.path}
          className='home--scroller--message'>
          ←Read: {post.title}
        </Link>
      )
    });
  }
  return (
    <div className='home--scroller'>
      <div className='home--scroller--links'>
        {renderLinks()}
      </div>
    </div>
  )
}
