import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import CustomMarkdownRenderers from './MarkdownRenderers'

export default function Markdown(props) {
  const [fileContent, setFileContent] = useState(null);
  const getContent = (file)=>{
    if (file) {
      fetch(file)
      .then((res) => res.text())
      .then((md) => {
        setFileContent(md);
      })
    }
  }

  useEffect(() => {
    getContent(props.file);
  }, [props.file]);

  return (
    <ReactMarkdown
      className={props.className}
      rehypePlugins={[rehypeRaw]}
      linkTarget={props.linkTarget}
      components={CustomMarkdownRenderers}
      file={props.file}>
        
      {fileContent || props.children}

    </ReactMarkdown>
  )
}

Markdown.defaultProps = {
  className: '',
  linkTarget: "_blank"
}