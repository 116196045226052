import React, {useContext} from 'react'
import { CartContext } from '../../Contexts/CartContext'
import Table from '../Tables/Table'
import Numeral from 'numeral'

export default function Cart() {
  const cart = useContext(CartContext);

  const formattedData = cart.items.map((item)=>{
    return {
      ...item,
      packageName: ()=>{
        return (
          <div>
            <div className='cart--packageName'>
              {cart.itemPackageName(item)}
            </div>
            <div className='cart--licenseSummary'>
              {cart.itemLicenseSummary(item)}
            </div>
          </div>
        )
      },
      totalCost: cart.itemCost(item),
      remove: () => {
        return(
          <button
            onClick={()=>{cart.onRemoveFromCart(item)}}
            className='btn--normalLink'>
            ×
          </button>
        );
      },
      cost: cart.itemCost(item)
    }
  })
  const renderFooter = ()=>{
    return (
      <tfoot>
        <tr>
          <td
            className='cart--totalLabel'>
            Total
          </td>
          <td className='cart--cost'>
              {Numeral(cart.totalCost).format('$0,0')}
          </td>
          <td></td>
        </tr>
      </tfoot>
    )
  }
  return (
    <div className='cart'>
      <h3 className='cart--title'>Your Cart</h3>
      { cart.hasItems ?
        <div>
          <Table
            className='cart--items'
            cols={COLUMNS}
            data={formattedData}
            showHeader={false}
            renderFooter={renderFooter}
            keyCol='uid' />

        </div>
      :
        <div className='cart--noItems'>
          You have no items in your cart.
        </div>
      }
    </div>
  )
}

const COLUMNS = [
  {
    name: 'packageName',
    displayName: 'Item',
    isRich: true
  },
  {
    name: 'totalCost',
    displayName: 'Cost',
    format: '$0,0'
  },
  {
    name: 'remove',
    displayName: '',
    isRich: true
  },
]
