import React from 'react'
import Numeral from 'numeral'

export default function LicensePickerFooter(props) {
  return (
    <div className='licensePicker--footer'>
      <div className='licensePicker--footer--totals'>
        <div className='licensePicker--footer--totals--label'>
          Total
        </div>
        <div className='licensePicker--footer--totals--total'>
          {Numeral(props.totalCost).format('$0,0')} USD
        </div>
      </div>
      <button 
        className='btn btn--fullWidth btn--pill'
        disabled={props.selectedPackageIds.length === 0}
        onClick={props.onAddToCartPress}>
        Add to Cart
      </button>
    </div>
  )
}
