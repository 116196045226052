import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import AppVars from '../../Utils/AppVars'
import CardFormFields from './CardFormFields';

const stripePromise = loadStripe(AppVars.stripeKey);

export default function CardForm(props) {
  
  return (
    <div className='cardForm'>
      <Elements 
        options={{
          fonts: [{
            family: 'VCTR Mono Web',
            weight: '400',
            src: `url(${AppVars.assetsUrl}/fonts/VCTR-Mono-v0.10-Regular.woff2)`
          }]
        }}
        stripe={stripePromise}>
        <CardFormFields
          onSuccess={props.onSuccess} />    
      </Elements>
    </div>
  )
}
