import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import FontTesterControls from './FontTesterControls'
import Arr from '../../Utils/Arr'

export default class FontTesterStyle extends Component {
  constructor(props) {
    super(props)
    const initialContent = Arr.mapTimes((props.fontStyle.numInputs || 1), (i) => {
      if (i === 0) {
        return props.fontStyle.text;
      } else {
        return props.fontStyle[`text${i+1}`]
      }
    })
    this.state = {
      fontSize: props.fontStyle.initialFontSize || props.typeface.testerFontSize || 50,
      lineHeight: props.fontStyle.initialLineHeight || props.typeface.testerLineHeight || 1.1,
      focused: false,
      content: initialContent
   }
  }
  setFocus(value) {
    this.setState({
      focused: value
    })
  }
  handleControlsChange(v){
    this.setState(v)
  }
  handleTextChange(i, e){
    const cleanContent = e.target.value.replace(/(<[^>]+) style=".*?"/ig, "");
    let newContent = this.state.content;
    newContent[i] = cleanContent;

    this.setState({
      content: newContent
    })
  }
  renderInputs() {
    return Arr.mapTimes(this.props.fontStyle.numInputs || 1, (i) => {
      return (
        <div 
          key={`inputs-${i}`}
          className='fontTester--inputWrapper'>
          <div 
            className='fontTester--input'
            style={{
              fontFamily: this.props.typeface.fontFamily,
              fontSize: `${this.state.fontSize}vw`,
              lineHeight: `${this.state.lineHeight}`,
              top: this.props.typeface.testerVerticalOffset || 0,
              ...this.props.fontStyle.style,
              ...this.props.extraStyle
            }}>
            <ContentEditable
              key={`main-input-${i}`}
              className='fontTester--input--contentEditable'
              html={this.state.content[i]}
              onChange={(v)=>{this.handleTextChange(i, v)}}
              onFocus={()=> {this.setFocus(true)}}
              onBlur={()=> {this.setFocus(false)}} />
          </div>
          {this.props.fontStyle.layered ? 
            <ContentEditable
              key={`layer2-{i}`}
              className='fontTester--inputLayer2 fontTester--input--contentEditable'
              style={{
                fontFamily: this.props.typeface.fontFamilyLayered,
                fontSize: `${this.state.fontSize}vw`,
                lineHeight: `${this.state.lineHeight}em`,
                top: this.props.typeface.testerVerticalOffset || 0,
                ...this.props.fontStyle.style,
                ...this.props.extraStyle
              }}
              html={this.state.content[i]} />
          : null}
        </div>
      );
    });
  }
  render() {
    const focusedClass = this.state.focused ? 'fontTester--focused' : '';

    return (
      <div 
        spellCheck={false}
        className={`fontTester ${focusedClass} fontTester--numInputs-${this.props.fontStyle.numInputs || 1}`}>
        <div className='fontTester--header'>
          <h6 className='fontTester--styleName'>
            {this.props.typeface.name}&nbsp;{this.props.fontStyle.name}
          </h6>
          <FontTesterControls
            fontSize={this.state.fontSize}
            lineHeight={this.state.lineHeight}
            onChange={(v)=>this.handleControlsChange(v)}
            typeface={this.props.typeface}
            fontStyle={this.props.fontStyle} />
        </div>
        <div className='fontTester--inputsWrapper'>
          {this.renderInputs()}
        </div>
      </div>
    )
  }
}