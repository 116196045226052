import Api from './ApiCore';

export default class Delete {
  static deleteIt (opts){
    opts.method = 'DELETE'
    Api.makeRequest(opts);
  }
  static session(opts){
    opts.url = 'sessions';
    this.deleteIt(opts);
  }
}
