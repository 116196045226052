import React, {useContext} from 'react'
import NarrowLayout from '../Layouts/NarrowLayout'
import { UserContext } from '../../Contexts/UserContext'
import SignInUpForm from '../SignInUp/SignInUpForm'
import TrialFontsForm from './TrialFontsForm'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function TrialFontsPage() {
  const user = useContext(UserContext);
  useTrackPageHit('Trial Fonts');
  return (
    <NarrowLayout
      pageName='trialFonts'>
      <h1>Trial Fonts</h1>
      {user.signedIn ?
        <div className='trialFonts--signedIn'>
          <TrialFontsForm />
        </div>
      :
        <div className='trialFonts--signedOut'>
          <div className='trialFonts--signedOutMessage'>
          <p>Trial fonts are for mock up and evaluation purposes only. They may also be used for student work. However, please consider purchasing a personal license (50% discount) if you’re able to afford it.</p>

  <p>Social media for personal projects is also permitted with attribution of the font and a shoutout to @vectrotype.</p>
            </div>
          <SignInUpForm
            hideTitle={true}
            redirectOnSuccess={window.location.href}
            hideNewsletter={true} />
        </div>
      }
    </NarrowLayout>
  )
}
