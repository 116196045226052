import React, {useState, useRef} from 'react'
import { Link } from 'react-router-dom';
import { useClickOutside } from '../../Hooks/UseClickOutside';

export default function Dropdown(props) {
  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  }
  const handleToggle = () => {
    setOpen(!open);
  }
  const renderOptions = () => {
    return props.options.map((option) => {
      return (
        <li key={`opt-${option.label}`}>
          {option.href ? 
            <Link
              to={option.href} >
              {option.label}
            </Link>
          : 
            <button
              className='btn--normalLink'
              onClick={option.onClick}>
              {option.label}
            </button>
          }
        </li>
      )
    })
  }
  const clickOutsideRef = useRef(null);
  useClickOutside(clickOutsideRef, handleClose);
  
  const activeClass = open ? 'dropdown--active' : '';

  return (
    <div 
      ref={clickOutsideRef}
      className={`dropdown ${activeClass}`}>
      <button 
        onClick={handleToggle}
        className='dropdown--trigger btn--normalLink'> 
        {props.children}
      </button>
      <ul className='dropdown--options'>
        {renderOptions()}
      </ul>
    </div>
  )
}