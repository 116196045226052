import React, {useContext, useRef} from 'react'
import {Link} from 'react-router-dom'
import { CartContext } from '../../Contexts/CartContext'
import { useIsVisible } from '../../Hooks/UseIsVisible'
import Scroll from '../../Utils/Scroll'
import {ReactComponent as DownloadIcon} from '../../Svg/Download-Icon.svg'


export default function TypefacePageHeader(props) {
  const cart = useContext(CartContext);
  const ref = useRef();
  const {stickyClass} = useIsVisible(ref, "100px");

  const handleAddToCartClick = (e) => {
    e.preventDefault();
    cart.openLicensePicker();
  }
  const handleClickTitle = (version) => {
    if (version === 'sticky') {
      Scroll.to('body');
    }
  }
  const renderAdditionalHeaderLinks = (btnClass) => {
    return props.typeface.additionalHeaderLinks.map((l)=> {
      return (
        <a 
          key={l.title}
          className={`btn btn--outline ${btnClass}`}
          href={l.url}
          target='_blank'
          rel="noreferrer">
          {l.title} {l.download ? <DownloadIcon /> : null}
        </a>
      )
    })
  }
  const renderHeader = (version) => {
    const btnClass = version === 'sticky' ? 'btn--small' : null;
    const ariaProps = version === 'sticky' ? {
      "aria-hidden": "true"
    } : null;
    return (
      <div 
        className={`typefacePage--headerContents typefacePage--header--${version}`}
        {...ariaProps}>
        <h1 onClick={()=>{handleClickTitle(version)}}>{props.typeface.name}</h1>
        <div className='typefacePage--header--right'>
          <button 
            onClick={()=>{Scroll.to('.typefacePage--info', {duration: 500})}}
            className={`btn btn--outline ${btnClass}`}>
            Info
          </button>
          {props.typeface.hideTrialBtn ?
            null
          :
            <Link
              to='/trial-fonts'
              className={`btn btn--outline ${btnClass}`}>
              Trial <DownloadIcon />
            </Link> 
          }
          {props.typeface.miniSiteUrl ? 
            <a 
              className={`btn btn--outline ${btnClass}`}
              href={props.typeface.miniSiteUrl}
              target='_blank'
              rel="noreferrer">
              Mini-site
            </a>
          : null }
          {props.typeface.merchUrl ? 
            <a 
              href={props.typeface.merchUrl}
              className={`btn btn--outline ${btnClass}`}
              target="_blank"
              rel="noreferrer">
              Merch
            </a>
          : null}
          { props.typeface.additionalHeaderLinks ? renderAdditionalHeaderLinks(btnClass) : null }
          {props.typeface.externalCta && !props.typeface.salesActive ? 
            <a 
              href={props.typeface.externalCta.url}
              target='_blank'
              className={`btn ${btnClass} typefacePage--header--externalCtaBtn`}
              rel="noreferrer" >
              {props.typeface.externalCta.label}
            </a>
          : null}
          {props.typeface.salesActive ? 
            <button 
              className={`btn ${btnClass} typefacePage--header--ctaBtn`}
              onClick={handleAddToCartClick}>Buy</button>
          : 
            null
          }
        </div>
      </div>
    )
  }
  return (
    <div 
      ref={ref}
      className={`typefacePage--header ${stickyClass}`}>
        
      {renderHeader('normal')}
      {renderHeader('sticky')}
    </div>
  )
}
