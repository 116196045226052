import DownloadPackages from './DownloadPackages.json'
import Typeface from './Typeface'



const all = DownloadPackages.map((downloadPackage)=> {
  const typefaceName = Typeface.find(downloadPackage.typeface_id).name;
  return {
    ...downloadPackage,
    shortName: downloadPackage.name.replace(typefaceName, '')
  }
});

const find = (id) => {
  return all.find((downloadPackage)=> {
    return downloadPackage.id === id
  })
}

const findBySlug = (slug) => {
  return all.find((downloadPackage)=> {
    return downloadPackage.slug === slug
  })
}

const allWithTypefaceId = (typefaceId) => {
  return all.filter((downloadPackage)=>{
    return downloadPackage.typeface_id === typefaceId
  })
}

const typefaceIndividualPackages = (typefaceId) => {
  return allWithTypefaceId(typefaceId).filter((downloadPackage)=>{
    return !downloadPackage.complete;
  })
}

const typefaceCompletePackage = (typefaceId) => {
  return allWithTypefaceId(typefaceId).find((downloadPackage)=>{
    return downloadPackage.complete;
  })
}

const main = {
  all,
  allWithTypefaceId,
  findBySlug,
  find,
  typefaceCompletePackage,
  typefaceIndividualPackages
}

export default main;
