import Typeface from '../../Data/Typeface'
import SignInPage from '../SignInUp/SignInPage'
import SignUpPage from '../SignInUp/SignUpPage'
import HomePage from '../Home/HomePage'
import TesterPage from '../TesterPage/TesterPage'
import FontsPage from '../FontsPage/FontsPage'
import TypefacePage from '../Typefaces/TypefacePage'
import CheckoutPage from '../Checkout/CheckoutPage'
import OrderPage from '../Orders/OrderPage'
import OrderPrintPage from '../Orders/OrderPrintPage'
import AccountOrdersPage from '../Account/AccountOrdersPage'
import AccountAccountPage from '../Account/AccountAccountPage'
import AccountPasswordPage from '../Account/AccountPasswordPage'
import ResetPasswordPage from '../ResetPassword/ResetPasswordPage'
import ResetPasswordCompletePage from '../ResetPassword/ResetPasswordCompletePage'
import BlogIndexPage from '../Blog/BlogIndexPage'
import BlogPostPage from '../Blog/BlogPostPage'
import StudioPage from '../Info/StudioPage'
import TrialFontsPage from '../TrialFonts/TrialFontsPage'
import StyleGuidePage from '../StyleGuide/StyleGuidePage'
import LicensingPage from '../Licensing/LicensingPage'
import AdminOrdersPage from '../Admin/AdminOrdersPage'
import ChartwellToolsPage from '../ChartwellTools/ChartwellToolsPage'
import DesignerAdminPaymentsPage from '../DesignerAdmin/DesignerAdminPaymentsPage'
import DesignerAdminTypefacesPage from '../DesignerAdmin/DesignerAdminTypefacesPage'
import DesignerAdminTypefaceSalesPage from '../DesignerAdmin/DesignerAdminTypefaceSalesPage'
import DesignerAdminTypefaceOrdersPage from '../DesignerAdmin/DesignerAdminTypefaceOrdersPage'
import DesignerAdminStripeConnectPage from '../DesignerAdmin/DesignerAdminStripeConnectPage'
import AdminTrialDownloadsPage from '../Admin/AdminTrialDownloadsPage'
import TermsPage from '../Legal/TermsPage'
import PrivacyPage from '../Legal/PrivacyPage'

const typefaces = Typeface.all.map((typeface)=>{
  return {
    path: typeface.path,
    component: TypefacePage,
    name: typeface.slug,
    roles: '',
    extraProps: {
      slug: typeface.slug
    }
  }
})
const allRoutes = [
  ...typefaces,
  { 
    path: '/sign-in',
    component: SignInPage,
    name: 'sign-in',
    roles: ''
  },
  { 
    path: '/sign-up',
    component: SignUpPage,
    name: 'sign-up',
    roles: ''
  },
  { 
    path: '/fonts',
    component: FontsPage,
    name: 'fonts',
    roles: ''
  },
  { 
    path: '/tester',
    component: TesterPage,
    name: 'tester',
    roles: ''
  },
  { 
    path: '/checkout',
    component: CheckoutPage,
    name: 'checkout',
    roles: ''
  },
  { 
    path: '/orders/:orderId/print',
    component: OrderPrintPage,
    name: 'orderPrint',
    roles: 'user'
  },
  { 
    path: '/orders/:orderId',
    component: OrderPage,
    name: 'order',
    roles: 'user'
  },
  { 
    path: '/account/account',
    component: AccountAccountPage,
    name: 'account',
    roles: 'user'
  },
  { 
    path: '/account/password',
    component: AccountPasswordPage,
    name: 'account-password',
    roles: 'user'
  },
  { 
    path: '/account/orders',
    component: AccountOrdersPage,
    name: 'acccount-orders',
    roles: 'user'
  },
  { 
    path: '/reset-password/complete',
    component: ResetPasswordCompletePage,
    name: 'reset-password-complete',
    roles: ''
  },
  { 
    path: '/reset-password',
    component: ResetPasswordPage,
    name: 'reset-password',
    roles: ''
  },
  { 
    path: '/blog/:slug',
    component: BlogPostPage,
    name: 'blog-post',
    roles: ''
  },
  { 
    path: '/blog',
    component: BlogIndexPage,
    name: 'blog',
    roles: ''
  },
  { 
    path: ['/studio', '/info'],
    component: StudioPage,
    name: 'studio',
    roles: ''
  },
  { 
    path: '/terms',
    component: TermsPage,
    name: 'terms',
    roles: ''
  },
  { 
    path: '/privacy',
    component: PrivacyPage,
    name: 'privacy',
    roles: ''
  },
  { 
    path: '/trial-fonts',
    component: TrialFontsPage,
    name: 'trial-fonts',
    roles: ''
  },
  { 
    path: '/licensing',
    component: LicensingPage,
    name: 'licensing',
    roles: ''
  },
  { 
    path: '/style-guide',
    component: StyleGuidePage,
    name: 'style-guide',
    roles: ''
  },
  { 
    path: '/designer-admin/payments/stripe-connect',
    component: DesignerAdminStripeConnectPage,
    name: 'designer-admin-stripe-connect',
    roles: 'designer'
  },
  { 
    path: '/designer-admin/payments',
    component: DesignerAdminPaymentsPage,
    name: 'designer-admin-payments',
    roles: 'designer'
  },
  { 
    path: '/designer-admin/typefaces/:id/sales',
    component: DesignerAdminTypefaceSalesPage,
    name: 'designer-admin-typeface-sales',
    roles: 'designer'
  },
  { 
    path: '/designer-admin/typefaces/:id/orders',
    component: DesignerAdminTypefaceOrdersPage,
    name: 'designer-admin-typeface-orders',
    roles: 'designer'
  },
  { 
    path: ['/designer-admin/typefaces', '/designer-admin/'],
    component: DesignerAdminTypefacesPage,
    name: 'designer-admin-typefaces',
    roles: 'designer'
  },
  { 
    path: '/admin/orders',
    component: AdminOrdersPage,
    name: 'admin-orders',
    roles: 'admin'
  },
  { 
    path: '/admin/trial-downloads',
    component: AdminTrialDownloadsPage,
    name: 'admin-trial-downloads',
    roles: 'admin'
  },
  { 
    path: '/admin',
    component: AdminOrdersPage,
    name: 'admin',
    roles: 'admin'
  },
  { 
    path: '/chartwell-tools',
    component: ChartwellToolsPage,
    name: 'chartwell-tools',
    roles: 'admin'
  },
  { 
    path: '/',
    component: HomePage,
    name: 'home',
    roles: ''
  }
];

const findRoute = (name)=>{
  return allRoutes.find((route)=>{
    return route.name === name;
  })
}

const findPath = (name)=>{
  return findRoute(name).path;
}

const whitelistedRoutes = ['signIn', 'signUp', 'resetPassword', 'resetPasswordComplete'];

const canAccessRoute = (route, roles) => {
  if (whitelistedRoutes.includes(route.name)) {
    return true;
  }
  let routeRoles = route.roles;
  const isUnrestricted = !routeRoles;
  const isAdmin = roles.includes('admin');
  const anyMatchingRoles = (routeRoles.split(',').some((role)=>{return roles.includes(role)}));
  return isUnrestricted || isAdmin || anyMatchingRoles;
}

const allWithPermissions = (roles)=>{
  return allRoutes.map((route)=>{
    return {
      ...route,
      canAccess: canAccessRoute(route, roles)
    }
  })
}

const main = {
  findRoute: findRoute,
  findPath: findPath,
  allWithPermissions: allWithPermissions
};

export default main;