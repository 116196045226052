import React, {useContext} from 'react'
import TextInput from '../../Forms/TextInput'
import { SyntaxConverterUIContext } from '../../../Contexts/SyntaxConverterUIContext'
import SyntaxConverterFieldsHeader from './SyntaxConverterFieldsHeader'

export default function SyntaxConverterFields() {
  const SyntaxConverterUI = useContext(SyntaxConverterUIContext);
  const handleChange = (v) => {
    SyntaxConverterUI.onChangeFormula(v);
  }
  return (
    <div className='syntaxConverter--fields'>
      <h2>{SyntaxConverterUI.selectedChart.label}</h2>
      <SyntaxConverterFieldsHeader />
      <TextInput
        key={`f-${SyntaxConverterUI.uid}`}
        name='simpleFormula'
        placeholder={SyntaxConverterUI.formula}
        label='Enter Values'
        defaultValue={null}
        onChange={handleChange} />
      <div className='syntaxConverter--altSyntax'>
        <TextInput
          key='altSyntax'
          name='altSyntax'
          label='Alt Syntax'
          controlled={true}
          readOnly={true}
          value={SyntaxConverterUI.altFormula} />
        
      </div>
      
    </div>
  )
}
