import React from 'react'
import SyntaxConverterChartSelection from './SyntaxConverterChartSelection'
import SyntaxConverterFields from './SyntaxConverterFields'
import SyntaxConverterPreview from './SyntaxConverterPreview'

export default function SyntaxConverter() {
  return (
    <div className='syntaxConverter'>
      <SyntaxConverterChartSelection />
      <div className='syntaxConverter--main'>
        <SyntaxConverterFields />
        <SyntaxConverterPreview />
      </div>
    </div>
  )
}
