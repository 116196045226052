
export const validatePresence = (fields, data) => {
  let errors = {};
  fields.forEach((field)=> {
    if (!data[field]) {
      errors = {
        ...errors,
        [field]: 'Can’t be blank'
      }
    }
  })
  return {
    valid: Object.keys(errors).length === 0,
    errors: errors
  };
}