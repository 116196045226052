import React, {useContext, useEffect, useState, useCallback} from 'react'
import { UserContext } from '../../Contexts/UserContext'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'
import { Link } from 'react-router-dom'


export default function DesignerAdminPaymentsPage() {
  const user = useContext(UserContext);
  const [typefaces, setTypefaces] = useState([]);
  const [ready, setReady] = useState(false);

  const fetchTypefaces = useCallback(() => {
    Api.get.designerTypefaces({
      id: user.attributes.id,
      success: (r)=>{
        if (r.status === 200) {
          setTypefaces(r.typefaces);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    });
  }, [user.attributes.id]);

  useEffect(()=>{
    fetchTypefaces();
  }, [fetchTypefaces])

  const renderTypefaces = () => {
    return typefaces.map((typeface)=>{
      return (
        <div 
          key={`t-${typefaces.id}`}>
          {typeface.name} {' '}
          <Link to={`/designer-admin/typefaces/${typeface.id}/sales`}>
            Sales
          </Link>{' '}
          <Link to={`/designer-admin/typefaces/${typeface.id}/orders`}>
            Orders
          </Link>
        </div>
      )
    })
  }

  return (
    <BackendLayout 
      mode='designerAdmin'
      expandedHeader={true}
      pageName='designerAdminTypefaces'
      showHomeThemeNav={true}>
        <h2>Typefaces</h2>
        {ready ? 
          <div>
            { typefaces.length > 0 ? 
              renderTypefaces()
            :
              <div>
                No typefaces yet
              </div>
            }
          </div>
        : null }
        
    </BackendLayout>
  )
}
