import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import Meta from './Meta'
import ThemeNav from './ThemeNav'
import SiteScreensaver from '../Screensavers/SiteScreensaver'
import SiteBanner from './SiteBanner'

export default function PrintLayout(props) {

  return (
    <div 
      className={`pageWrapper page--${props.pageName} ${props.layoutClassName} ${props.className}`}>
      <Meta {...props.meta} />
      <div className='headerMainWrap'>
        <div className='printHeader'>
          VECTRO
        </div>
        <main>
          {props.children}
        </main>
      </div>
    </div>
  )
}
PrintLayout.propTypes = {
  pageName: PropTypes.string,
  className: PropTypes.string,
  expandedHeader: PropTypes.bool
}
PrintLayout.defaultProps = {
  pageName: '',
  className: '',
  expandedHeader: false,
  layoutClassName: 'PrintLayout'
}