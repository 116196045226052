import React from 'react'
import AppVars from '../../Utils/AppVars'
import Arr from '../../Utils/Arr'
import { Link } from 'react-router-dom'

export default function SiteBanner() {
  function renderMessage() {
    return Arr.mapTimes(10, (i)=>{
      return (
        <span key={`m-${i}`}>
          {AppVars.siteBanner.message}&nbsp;&nbsp;*&nbsp;&nbsp;
        </span>
      )
    })
  }
  
  if (AppVars.siteBanner.active) {
    return (
      <div className='siteBanner'>
        <Link 
          className='siteBanner--scroller'
          to={AppVars.siteBanner.url}>
          {renderMessage()}
        </Link>
      </div>
    )
  } else {
    return null;
  }
}
