import React, {useState} from 'react'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'
import AdminOrdersList from './AdminOrdersList'
import { usePagination } from '../../Hooks/UsePagination'


export default function AdminOrdersPage() {
  const [orders, setOrders] = useState([]);
  const [ready, setReady] = useState(false);

  const fetchData = (page) => {
    Api.get.adminOrders({
      params: {
        page: page
      },
      success: (r)=>{
        if (r.status === 200){
          setOrders(r.orders);
          setTotalPages(r.total_pages);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    })
  }
  
  const {renderPagination, setTotalPages} = usePagination(fetchData, 'main');

  return (
    <BackendLayout 
      mode='admin'
      expandedHeader={true}
      pageName='adminOrders'
      showHomeThemeNav={true}>
        <h2>Orders</h2>
        {ready ? 
          <div className='adminOrders--main'>
            <AdminOrdersList
              orders={orders} />
            {renderPagination()}
          </div>
        : null}
    </BackendLayout>
  )
}
