import React, { useState, useContext } from 'react'
import Cookies from '../Utils/Cookies'
import { uid } from 'uid'
import Numeral from 'numeral'
import Arr from '../Utils/Arr'
import DownloadPackage from '../Data/DownloadPackage'
import LicenseTables from '../Data/LicenseTables'
import Api from '../Utils/Api'
import { UserContext } from './UserContext'
import Formatter from '../Formatters/Formatter'
import Scroll from '../Utils/Scroll'
import Track from '../Utils/Track'


const cartCookieKey = 'vectro_cart';
const defaultCartValue = {
  items: []
}

const initialCartValue = () => {
  let cartData = Cookies.get(cartCookieKey);
  if (cartData) {
    cartData = JSON.parse(cartData)
  } else {
    cartData = defaultCartValue;
  }
  setCookieValue(cartData);
  return cartData;
}
const setCookieValue = (value) => {
  Cookies.set(cartCookieKey, JSON.stringify(value), 7);  
}

export const CartContext = React.createContext(defaultCartValue);

export function CartContextProvider(props) {
  const user = useContext(UserContext);
  const [items, setItems] = useState(initialCartValue().items);
  const [licensePickerIsOpen, setLicensePickerIsOpen] = useState(false);
  const [fontsFor, setFontsFor] = useState('self');
  const [otherLicensee, setOtherLicensee] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);

  const handleChangeFontsFor = (v) => {
    setFontsFor(v)
  }
  const handleChangeOtherLicensee = (v) => {
    setOtherLicensee(v);
  }

  const handleGetPaymentMethod = (callback) => {
    if (user.signedIn) {
      Api.get.userPaymentMethod({
        id: user.attributes.id,
        success: (r)=>{
          if (r.payment_method) {
            setPaymentMethod(r.payment_method);
            setPaymentMethodLoaded(true);
            callback && callback();
          } else {
            setPaymentMethod(null);
            setPaymentMethodLoaded(true);
          }
        },
        error: (m,r)=>{
          setPaymentMethod(null);
          setPaymentMethodLoaded(true);
        }
      })
    } else {
      setPaymentMethodLoaded(true);
    }
  }

  const clearPaymentMethod = () => {
    setPaymentMethod(null);
  }

  const mergeAndSyncCookies = (value) => {
    const newValues = {
      items,
      ...value
    };
    setCookieValue(newValues);
  }

  // functions
  const handleClear = () => {
    updateAllCartValues(defaultCartValue)
  }
  const updateItems = (value) => {
    setItems(value);
    mergeAndSyncCookies({items: value});
  }
  const updateAllCartValues = (values) => {
    setItems(values.items);
    mergeAndSyncCookies(values);
  }
  const handleAddToCart = (packageIds, licenseTableName, quantity) => {
    
    const newItems = packageIds.map((packageId)=>{
      const downloadPackage = DownloadPackage.find(packageId)
      return ({
        uid: uid(8),
        typeface_id: downloadPackage.typeface_id,
        package_id: packageId,
        license_table: licenseTableName,
        quantity: quantity
      });
    })
    updateItems(items.concat(newItems));
  }
  const handleRemoveFromCart = (itemToRemove)=> {
    const newItems = items.filter((item) => {
      return item.uid !== itemToRemove.uid
    });
    updateItems(newItems);
  }
  const handleOpenLicensePicker = () => {
    setLicensePickerIsOpen(true);
    Track.event('Click Buy', {page: window.location.pathname })
    Scroll.disable();
  }
  const handleCloseLicensePicker = () => {
    setLicensePickerIsOpen(false);
    Scroll.enable();
  }

  // info helpers
  const getLastLicenseSettings = ()=> {
    if (items.length > 0) {
      const lastItem = items[items.length -1]
      return {
        quantity: lastItem.quantity,
        license_table: lastItem.license_table
      }
    } else {
      const defaultTable = LicenseTables.all[0]
      return {
        quantity: defaultTable.breakpoints[0].quantity,
        license_table: defaultTable.name
      }

    }
  }
  const lastLicenseSettings = getLastLicenseSettings();
  const hasItems = items.length > 0;
  const itemCost = (item) => {
    const downloadPackage = DownloadPackage.find(item.package_id);
    return downloadPackage.base_cost * item.quantity
  }
  const itemBaseCost = (item) => {
    const downloadPackage = DownloadPackage.find(item.package_id);
    return downloadPackage.base_cost;
  }
  const calculateTotalCost = () => {
    const totals = items.map((item)=>{
      return itemCost(item);
    })
    if (totals.length > 0) {
      return Arr.sum(totals);
    } else {
      return 0;
    }
  }
  const totalCost = calculateTotalCost();
  const totalCostInDollars = Numeral(totalCost).format('$0,0');
  const itemPackageName = (item) => {
    return DownloadPackage.find(item.package_id).name;
  }
  const itemLicenseSummary = (item) => {
    return LicenseTables.breakpointSummary(item.license_table, item.quantity);
  }

  const itemsForSubmission = items.map((item)=>{
    // in case there's need for renaming variables
    return {
      ...item,
      base_cost: itemBaseCost(item)
    }
  })

  const getLicensee = () => {
    if (fontsFor === 'self') {
      if (user.signedIn) {
        return user.attributes.company || user.attributes.full_name;
      } else {
        return '';
      }
    } else {
      return otherLicensee;
    }
  }
  const licensee = getLicensee();

  const readyForPayment = user.signedIn && paymentMethod && (items.length > 0) && licensee;

  const formattedDataForSubmission = Formatter.format({
    schema: 'order', 
    data: {
      payment_method_id: paymentMethod && paymentMethod.id,
      order_items: itemsForSubmission,
      fonts_for: fontsFor,
      licensee: licensee
    }
  });

  return (
    <CartContext.Provider value={{
      items,
      itemsForSubmission,
      hasItems,
      itemCost,
      itemPackageName,
      itemLicenseSummary,
      totalCost,
      totalCostInDollars,
      licensePickerIsOpen,
      lastLicenseSettings,
      fontsFor,
      licensee,
      otherLicensee,
      readyForPayment,
      paymentMethod,
      paymentMethodLoaded,
      formattedDataForSubmission,
      getPaymentMethod: handleGetPaymentMethod,
      clearPaymentMethod,
      handleChangeOtherLicensee,
      onChangeFontsFor: handleChangeFontsFor,
      openLicensePicker: handleOpenLicensePicker,
      closeLicensePicker: handleCloseLicensePicker,
      onAddToCart: handleAddToCart,
      onRemoveFromCart: handleRemoveFromCart,
      clear: handleClear
    }}>
      {props.children}
    </CartContext.Provider>
  )
}