import Obj from './Obj'
import AppVars from './AppVars'

export default class Url {
  static encodeParams(data) {
    if (data) {
      const p = Object.keys(data).map((i) => {
        if (data[i]) {
          return i+'='+data[i];
        } else {
          return '';
        }
      })
      const p2 = p.filter((v)=>{
        return v !== '';
      }).join('&');
      return `?${p2}`;
    } else {
      return '';
    }
  }
  static mergeAndEncode(data, params) {
    const newData = {
      ...this.parseParams(params),
      ...data
    };
    return this.encodeParams(newData);
  }
  static parseParams(params) {
    let data = {};

    if (params) {
      const query = params.split('?').slice(1).join('?');
      const args = query.split('&');

      args.forEach((arg)=>{
        const item = arg.split('=');
        const key = item[0];
        const value = item.slice(1).join('=');
        if (item.length >= 2) {
          data = {
            ...data,
            [key]: value
          }
        }
      })
    }

    return data;
  }
  static parseRouterParams(props) {
    return this.parseParams(props.router.location.search)
  }
  static getUrlParams(){
    return this.parseParams(window.location.search);
  }
  static parameterize(){
  }
  static safeUrl(url){
    return encodeURI(url);
  }
  static superSafeUrl(url){
    return this.safeUrl(url).replace(/\./g, '%2E');
  }
  static redirectUrlFromPath() {
    return this.getUrlParams().r;
  }
  static addParamsToCurrentUrl(newParams) {
    return this.mergeAndEncode(newParams, window.location.search);
  }
  static removeParamFromUrl(url, key) {
    const params = this.parseParams(url);
    const newParams = Obj.removeKeyFromObject(params, key);
    return url.split('?')[0] + this.encodeParams(newParams);
  }
  static anySegmentsActiveInPath(targetSegments, routerPathname) {
    let isActive = false;
    let currentPath = routerPathname;
    while(currentPath.charAt(0) === '/') {
      currentPath = currentPath.substring(1);
    }
    const currentSegments = currentPath.split('/');
    
    targetSegments.forEach((targetSegment)=> {
      if ((targetSegment === '') && (currentSegments.length === 0)) {
        isActive = true;
      } else if (currentSegments.indexOf(targetSegment) > -1) {
        isActive = true;
      }
    })
    
    return isActive;
  }
  static getProtocol(inUrl) {
    let url = null;
    try {
      url = new URL(inUrl);
    } catch (_) {
      return null;
    }
    return url.protocol;
  }
  static assetUrlFallback(inUrl) {
    const imgProtocol = Url.getProtocol(inUrl)
    const url = imgProtocol ? inUrl : `${AppVars.assetsUrl}${inUrl}`;
    return url;
  }
}