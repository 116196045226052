import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import AppVars from '../../Utils/AppVars'
import NewsletterForm from '../NewsletterForm/NewsletterForm'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'
import { Link } from 'react-router-dom'


export default function StudioPage() {
  useTrackPageHit('Studio');
  return (
    <MainLayout
      pageName='info'>
      <div className='infoPage'>
        <div className='infoPage--overview'>
          <div className='infoPage--overviewSection'>
            <h3>INQUIRIES</h3>
            <a href='mailto:hello@vectrotype.com'>hello@vectrotype.com</a>
          </div>
          <div className='infoPage--overviewSection'>
            <h3>ELSEWHERE</h3>
            <a href='https://www.futurefonts.xyz/vectro' target='_blank' rel='noreferrer'>Future Fonts</a><br />
            <a href={`https://www.instagram.com/${AppVars.instagramHandle}/`} target='_blank' rel='noreferrer'>Instagram</a><br />
            <a href={`https://www.twitter.com/${AppVars.twitterHandle}`} target='_blank' rel='noreferrer'>
              Twitter
            </a><br />
            <a href={AppVars.youTubeUrl} target='_blank' rel='noreferrer'>YouTube</a>
          </div>
          <div className='infoPage--overviewSection'>
            <h3>Newsletter</h3>
            Occassional font and product releases
            <NewsletterForm
              label={null}
              buttonText='Submit' />
          </div>
          <div className='infoPage--overviewSection'>
            <h3>HQ</h3>
            Portland, Oregon
          </div>

        </div>
        <div className='infoPage--main'>
          <p>
            Vectro Type Foundry is a Portland type design studio with a curiosity for experimentation and technology. It was founded by Travis Kochel and Lizy Gershenzon, the entrepreneurial duo behind <a href='https://www.futurefonts.xyz' target='_blank' rel='noreferrer'>Future&nbsp;Fonts</a>, who have been design partners for nearly two decades. With their experience in the type and design industries, they established Vectro Type Foundry to showcase their typefaces, type services and further assist other designers in releasing their work.
          </p>
          <p>
            Vectro offers commercial retail fonts and specializes in custom typeface design, creative font scripting, icon design, and font production services.
          </p>
         
          <h3>Our Services</h3>
          <ul className='asteriskList'>
            <li>Custom type design </li>
            <li>Expertise in variable fonts and advanced OpenType features</li>
            <li>Wordmark and logotype design</li>
            <li>Font production tool development</li>
            <li>Font production assistance</li>
            
          </ul>
          <h3>Leadership</h3>
          <p>
            <strong>Travis Kochel</strong><br />
            <em>Lead Type Designer and Co-Founder</em>
          </p>
          <p>Travis leads type design and direction for Vectro. He is also a founder and owner of Future Fonts. During the last 10 years he has been a partner at Scribble Tone focusing on type design and development. He’s also an Adjunct Professor teaching Type Design at Portland State University.
          </p>
          <p>
            <strong>Lizy Gershenzon</strong><br />
            <em>Creative Director and Co-Founder</em>
          </p>
          <p>Lizy leads marketing and product strategy for Vectro. She is also a founder and owner of Future Fonts. During the last 10 years she has been a partner at Scribble Tone focusing on digital product design and strategy. She also contracts as a digital UX and product designer.
          </p>
          
          <h3>With Typeface Releases By</h3>

          <ul className='asteriskList'>
            <li>
              <a href='https://www.philippneumeyer.com/' target="_blank" rel='noreferrer'>Philipp Neumeyer</a> <em>(<Link to='/rainer'>Rainer</Link>)</em>
            </li>
          </ul>

          <h3>Collaborators and Contributors</h3>

          <ul className='asteriskList'>
            <li>
              <a href='http://dariapetrova.com' target="_blank" rel='noreferrer'>Daria Cohen</a> <em>(Cyrillic, Type Production)</em>
            </li>
            <li>
              <a href='https://ethancohenstudio.com' target="_blank" rel='noreferrer'>Ethan Cohen</a> <em>(Cyrillic, Type Production)</em>
            </li> 
            <li>
              <a href='https://www.fiskprojects.com' target="_blank" rel='noreferrer'>FISK</a> <em>(Marketing)</em>
            </li>
            <li>
              <a href='https://www.instagram.com/fun.weirdo/' target="_blank" rel='noreferrer'>Leah Maldonado</a> <em>(Type Production)</em>
            </li>
            <li>
              <a href='https://oof.studio/' target="_blank" rel='noreferrer'>Savannah Julian</a> <em>(Type Production)</em>
            </li>
          </ul>
        </div>
      </div>
    </MainLayout>
  )
}
