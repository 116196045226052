import React from 'react'
import PropTypes from 'prop-types'


export default function SelectInput(props) {
  const renderOptions = () => {
    return props.options.map((option) => {
      return (
        <option 
          key={`opt-${option[props.valueKey]}`}
          value={option[props.valueKey]}>
          {option[props.labelKey]}
        </option>
      )
    })
  }
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }
  return (
    <div className='formInput selectInput'>
      <label htmlFor={props.name}>{props.label}</label>
      <select 
        name={props.name}
        onChange={handleChange}
        defaultValue={props.defaultValue}
        size={props.size}
        disabled={props.disabled}>
        <option  
          value=""
          disabled={true}> -- Select an option -- </option>
        {renderOptions()}
      </select>
    </div>
  )
}

SelectInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  size: PropTypes.string
}
SelectInput.defaultProps = {
  options: [],
  valueKey: 'value',
  labelKey: 'label'
}