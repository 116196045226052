import React from 'react'
import LicenseTables from '../../Data/LicenseTables'
import LicensePickerTable from './LicensePickerTable'
import {Link} from 'react-router-dom'

export default function LicensePickerType(props) {
  const renderTables = ()=> {
    return LicenseTables.allLive.map((licenseTable)=>{
      return (
        <LicensePickerTable
          key={licenseTable.name}
          licenseTable={licenseTable}
          selectedLicenseTable={props.selectedLicenseTable}
          selectedLicenseQuantity={props.selectedLicenseQuantity}
          onChangeLicenseQuantity={props.onChangeLicenseQuantity}
          onChangeLicenseTable={props.onChangeLicenseTable} />
      )
    })
  }
  
  return (
    <div className='licensePicker--type'>
      <h5>
        Step 1: Select License Type <Link className='helpBtn' to='/licensing' target="_blank">?</Link>
      </h5>
      <div className='licensePicker--tables'>
        {renderTables()}
      </div>
    </div>
  )
}
