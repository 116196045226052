import React, {useContext} from 'react'
import { SyntaxConverterUIContext } from '../../../Contexts/SyntaxConverterUIContext'


export default function SyntaxConverterChangeChartWarning() {
  const SyntaxConverterUI = useContext(SyntaxConverterUIContext)
  const renderChangeChartWarning = ()=>{
    if (SyntaxConverterUI.showChangeChartWarning) {
      return (
        <div className='syntaxConverter--changeChartWarning'>
          Syntax might not translate to different chart styles. <button className='btn--normalLink' onClick={SyntaxConverterUI.onReset}>Reset to example values</button>
        </div>
      )
    } else {
      return null;
    }
  }
  return (
    <div className='syntaxConverter--fieldsHeader'>
      {SyntaxConverterUI.formulaHasBeenUpdated ? 
      <button 
        onClick={SyntaxConverterUI.onReset}
        className='btn--normalLink'>Reset</button>
      : null}
      {renderChangeChartWarning()}
    </div>
  )
}
