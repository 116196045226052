import React, {useContext} from 'react'
import { UserContext } from '../../Contexts/UserContext'
import SignInUpForm from '../SignInUp/SignInUpForm'
import { Link } from 'react-router-dom'

export default function CheckoutAccount(props) {
  const user = useContext(UserContext);
  if (user.signedIn) {
    return (
      <div className='checkout--account--summary'>
        <p>
          {user.attributes.full_name} <br />
          {user.attributes.company}
          {user.attributes.company ? 
            <br />
          : null }
          {user.attributes.email}
          
        </p>
        <Link to='account/account'>
          Edit Info
        </Link>
        <br />
        <button 
          className='btn--normalLink'
          onClick={()=>{user.onSignOut()}}>Sign Out</button>
      </div>
    )
  } else {
    return (
      <SignInUpForm
        hideTitle={true}
        redirectOnSuccess={'/checkout'}
        buttonText='Next' />
    )
  }
  
}
