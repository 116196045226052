import React from 'react'
import SelectInput from '../Forms/SelectInput'

export default function LicensePickerTable(props) {
  const isActive = props.selectedLicenseTable === props.licenseTable.name;
  const activeClass = isActive ? 'licensePicker--table--active' : '';
  const showHelperTextClass = props.licenseTable.showHelperTextInPicker ? 'licensePicker--table--showHelperText' : 'licensePicker--table--hideHelperText';
  const handleSelect = () => {
    if (!isActive) {
      props.onChangeLicenseTable(props.licenseTable);
    }
  }
  return (
    <div 
      className={`licensePicker--table ${activeClass} licensePicker--table--${props.licenseTable.name} ${showHelperTextClass}`}
      onClick={handleSelect}>
      <div className='licensePicker--table--label'>
        {props.licenseTable.label}
      </div>
      {props.licenseTable.helperText && props.licenseTable.showHelperTextInPicker ? 
        <div className='licensePicker--table--helperText'>
          {props.licenseTable.helperText}
        </div>
      : null}
      {props.licenseTable.showBreakpoints ? 
        <div className='licensePicker--table--breakpoints'>
          <SelectInput
              key={`input-${props.licenseTable.name}-${isActive}`}
              valueKey='quantity'
              defaultValue={String(props.selectedLicenseQuantity)}
              options={props.licenseTable.breakpoints}
              onChange={props.onChangeLicenseQuantity}
              disabled={!isActive} />
        </div>
      : null}
    </div>
  );
}
