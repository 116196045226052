import React, {useState, useContext, useEffect} from 'react'
import { CartContext } from '../../Contexts/CartContext'
import ErrorMessage from '../Forms/ErrorMessage'
import Api from '../../Utils/Api'
import {useHistory} from 'react-router-dom'
import Scroll from '../../Utils/Scroll'
import {loadStripe} from '@stripe/stripe-js'
import AppVars from '../../Utils/AppVars'

export default function CheckoutSubmit(props) {
  const history = useHistory();
  const [stripe, setStripe] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const cart = useContext(CartContext);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const canSubmit = cart.readyForPayment && props.agreedToEula && !submitting;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!submitting) {
      setSubmitting(true);
      confirmPayment(cart.formattedDataForSubmission, (intent)=> {
        createOrder(cart.formattedDataForSubmission, intent);
      });
    }
  }
  const confirmPayment = (formattedData, handleSuccessfulPayment) => {
    Api.post.orderConfirmPayment({
      body: formattedData,
      success:(r)=>{
        if (r.status === 200 && r.intent) {
          handleConfirmation(r.intent, formattedData, handleSuccessfulPayment)
        } else {
          handleError(r)
        }
      },
      error:(m, r)=>{
        handleError(r);
      }
    });
  }
  const createOrder = (formattedData, intent) => {
    Api.post.order({
      body: {
        ...formattedData,
        stripe_charge_id: intent.charges.data[0].id
      },
      success:(r)=>{
        if (r.status === 200) {
          handleOrderSuccess(r.order);
        } else {
          handleError(r);
        }
      },
      error:(m, r)=>{
        handleError(r);
      }
    })
  }
  const handleConfirmation = (intent, formattedData, handleSuccessfulPayment) => {

    if (intent.status === 'succeeded') {
      handleSuccessfulPayment(intent);
    } else if ((intent.status === 'requires_action') && (intent.next_action.type === 'use_stripe_sdk')) {

      stripe.handleCardAction(
        intent.client_secret
      ).then((r)=>{
        
        if (r.error) {
          handleError({errors: {form: 'We are unable to authenticate your payment. Please add a different credit card and try again.'}})
        } else if (r.paymentIntent && r.paymentIntent.status === 'succeeded') {
          handleSuccessfulPayment()
        } else if (r.paymentIntent && r.paymentIntent.status === 'requires_confirmation') {
          confirmPayment(
            {
              ...formattedData,
              stripe_payment_intent_id: r.paymentIntent.id
            }, 
            handleSuccessfulPayment
          );
        } else {
          handleError();
        }
      });
    } else {
      handleError();
    }
  }
  const handleError = (r) => {
    const errors = (r && r.errors) ? r.errors : {form: 'Something went wrong, please try again.'};
    setErrors(errors);
    setSubmitting(false);
    Scroll.to('checkout--submit');
  }
  const handleOrderSuccess = (order) => {
    history.push(`/orders/${order.id}`);
    cart.clear();
  }
  useEffect(()=>{
    if (!stripe && !stripeLoading) {
      setStripeLoading(true);
      loadStripe(AppVars.stripeKey).then((s)=>{
        setStripe(s)
      })
    }
    if (stripe && stripeLoading) {
      setStripeLoading(false);
    }
  }, [stripe, stripeLoading])
  return (
    <form className='checkout--submit'
      onSubmit={handleSubmit}>
      <ErrorMessage
        name='form'
        errors={errors} />
      <button 
        className='btn btn--fullWidth'
        disabled={!canSubmit} >
        Charge {cart.totalCostInDollars} USD
      </button>
    </form>
  )
}
