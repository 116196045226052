import React from 'react'
import Table from '../Tables/Table'
import DateHelper from '../../Utils/DateHelper'
import Numeral from 'numeral'

export default function DesignerAdminOrdersList(props) {
  const orderItemsList = (order) => {
    return order.order_items.map((orderItem)=>{
      return (
        <div 
          className='adminOrders--orderItem'
          key={`oi-${orderItem.id}`}>
          <h3 className='adminOrders--orderItem--package'>
            {orderItem.package_name}
          </h3>
          <div className='adminOrders--orderItem--summary'>
            License: {orderItem.short_license_summary}<br />
            Cost: {Numeral(orderItem.total_cost).format('$0,0')} <br />
            Royalties: {Numeral(orderItem.royalty_total).format('$0,0')}
          </div>
        </div>
      )
    })
  }
  const formattedData = props.orders.map((order)=>{
    const totalRoyalties = order.order_items.reduce((total, oi) => {
      return total + parseFloat(oi.royalty_total);
    }, 0);
    return {
      ...order,
      totalRoyalties: () => {
        return(
          <div className='designerAdminOrders--total'>
            <h4 className='designerAdminOrders--total--title'>
              Total Royalties
            </h4>
            
            {Numeral(totalRoyalties).format('$0,0')}
          </div>
          
        )
      },
      richSummary: ()=> {
        return (
          <div className='adminOrders--orderItem--summary'>
            <div className='adminOrders--orderItem--meta'>
              <div className='adminOrders--orderItem---id'>
                #{order.id} • {DateHelper.slashToFull(order.created_at_formatted_short)}
              </div>
            </div>
            {orderItemsList(order)}
          </div>
          
        )
      },
      actions: ()=> {
        return (
          <div>

          </div>
        )
      }
    }
  })
  return (
    <Table
      className='adminOrders--ordersList'
      showHeader={false}
      cols={COLUMNS}
      data={formattedData}
      keyCol='id' />
  )
}

// id, date, summary, cost, view, download
const COLUMNS = [
  {
    name: 'richSummary',
    displayName: 'Summary',
    isRich: true
  },
  {
    name: 'totalRoyalties',
    displayName: 'Total Royalties',
    isRich: true
  },
  {
    name: 'actions',
    displayName: '',
    isRich: true
  }
]