import React, { useState } from 'react'
import AltSyntaxTranslator from '../Utils/AltSyntaxTranslator'
import ChartwellSyntaxOptions from '../Data/ChartwellSyntaxOptions.json';

export const SyntaxConverterUIContext = React.createContext();

const CHART_OPTIONS = ChartwellSyntaxOptions;
const DEFAULT_KEY = 'bars-3d';
const FIND_CHART_BY_KEY = (key) => {
  return CHART_OPTIONS.find((opt)=>{
    return opt.key === key;
  })
}
const GET_DEFAULT_FORMULA = (key)=> {
  const chart = FIND_CHART_BY_KEY(key);
  const f = chart.defaultFormula;
  return f;
}

export function SyntaxConverterContextProvider(props) {
  const [uid, setUID] = useState(0);
  const [selectedChartKey, setSelectedChartKey] = useState(DEFAULT_KEY);
  const [formula, setFormula] = useState(GET_DEFAULT_FORMULA(DEFAULT_KEY));
  const [formulaHasBeenUpdated, setFormulaHasBeenUpdated] = useState(false);
  const [showChangeChartWarning, setShowChangeChartWarning] = useState(false);

  const handleReset = ()=> {
    setFormula(GET_DEFAULT_FORMULA(DEFAULT_KEY));
    setFormulaHasBeenUpdated(false);
    setShowChangeChartWarning(false);
    setUID(uid+1);
  }
  const handleChangeChart = (key) => {
    setSelectedChartKey(key);
    if (!formulaHasBeenUpdated) {
      setFormula(GET_DEFAULT_FORMULA(key))
    } else if (key !== selectedChartKey) {
      setShowChangeChartWarning(true);
    }
  }
  const handleChangeFormula = (v) => {
    setFormula(v)
    setFormulaHasBeenUpdated(true);
    setShowChangeChartWarning(false)
  }
  const selectedChart = CHART_OPTIONS.find((opt)=>{
    return opt.key === selectedChartKey
  })
  const altFormula = () => {
    return AltSyntaxTranslator.translate(selectedChartKey, formula);
  }
  const altFormulaGroupedValues = AltSyntaxTranslator.groupedValues(altFormula(), true);

  const shouldSeparateCoreValues = (key)=>{
    const chart = FIND_CHART_BY_KEY(key);
    return chart.separateCoreValues;
  }

  const shouldSeparateCoreValuesSelected = shouldSeparateCoreValues(selectedChartKey);

  return (
    <SyntaxConverterUIContext.Provider value={{
      selectedChartKey,
      selectedChart,
      formula,
      altFormula: altFormula(),
      altFormulaGroupedValues,
      chartOptions: CHART_OPTIONS,
      onChangeChart: handleChangeChart,
      onChangeFormula: handleChangeFormula,
      FIND_CHART_BY_KEY,
      shouldSeparateCoreValues,
      shouldSeparateCoreValuesSelected,
      onReset: handleReset,
      uid,
      showChangeChartWarning,
      formulaHasBeenUpdated
    }}>
      {props.children}
    </SyntaxConverterUIContext.Provider>
  )
}
