export default class Dom {
  static getProperty(el, property) {
    return window.getComputedStyle(el, null).getPropertyValue(property);
  }
  static find(selector) {
    switch (selector[0]) {
      case '.':
        return this.findByClass(selector);
      case '#':
        return this.findById(selector);
      default: 
        return this.findByTag(selector);
    }
  }
  static findFirst(selector) {
    const results = this.find(selector);
    if (results && (results.constructor === Array)) {
      return results[0];
    } else {
      return results;
    }
  }
  static findByClass(className) {
    return [...document.getElementsByClassName(className.replace('.', ''))];
  }
  static findById(id) {
    return document.getElementById(id.replace('#', ''));
  }
  static findByTag(tag) {
    return [...document.getElementsByTagName(tag)];
  }
  static hasClass(el, className) {
    if (el) {
      if (el.classList) {
        return el.classList.contains(className);
      } else {
        debugger
        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
      }
    }
  }
  static addClass(el, className) {
    if (el) {
      if (el.classList) {
        el.classList.add(className);
      } else if (!this.hasClass(el, className)) {
        el.className += " " + className;
      }
    }
  }
  static removeClasses(el, classNames) {
    classNames.split(' ').forEach((className) => {
      this.removeClass(el, className);
    })
  }
  static removeClass(el, className) {
    if (el && className) {
      if (el.classList) {
        el.classList.remove(className);
      } else if (this.hasClass(el, className)) {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
        el.className=el.className.replace(reg, ' ');
      }
    }
  }
}