import Auth from '../Auth';
import AppVars from '../AppVars';

export default class Api {
  static makeRequest(opts){
    const url = '/api/v1/' + opts.url + this.toQueryString(opts.params);
    let request = new XMLHttpRequest();
    request.onreadystatechange = (r)=>(this.handleResponse(request, opts));
    request.open(opts.method, url);
    this.setHeaders(request, opts);
    request.send(JSON.stringify(opts.body));
  }
  static setHeaders(request, opts) {
    const headers = {
      "Content-Type": "application/json", 
      "Accept": "application/json",
      "apiKey": Auth.getSessionCookie(),
      ...opts.headers
    }
    Object.keys(headers).forEach((key)=>{
      request.setRequestHeader(key, headers[key])
    });
    return request;
  }
  static handleResponse(request, opts){
    if (request.readyState === 4) {
      if ((request.status === 200)) {
        const r = JSON.parse(request.response);

        if (r.status === 200) {
          this.handleSuccess(opts, r);
        } else {
          this.handleFormError(opts, r)
        }
      } else {
        this.handleServerError(opts, request.response);
      }
    }
  }
  static toQueryString(paramsObject) {
    if (paramsObject) {
      return "?" + Object
        .keys(paramsObject)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
        .join('&')
      ;
    } else {
      return ''
    }
  }
  static handleSuccess(opts, r) {
    opts.success && opts.success(r);  
  }
  static handleFormError(opts, res){
    opts.error && opts.error('There were errors in your form.', res, res.errors); 
  }
  static handleServerError(opts, res){
    const message = 'Something went wrong. Please try again in a few minutes.'
    const errors = {form: message}
    if (AppVars.env !== 'production') {
      console.log('server error', res)
    }
    opts.error && opts.error(message, res, errors); 
  }
}
