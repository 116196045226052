import React from 'react'
import { useMousePosition } from '../../Hooks/UseMousePosition'
import Dom from '../../Utils/Dom'
import { useScrollPosition } from '../../Hooks/UseScrollPosition'
import Markdown from '../Markdown/Markdown'
import Arr from '../../Utils/Arr'
import BrowserSniffer from '../../Utils/BrowserSniffer'

export default function PageSpotlight(props) {
  const bgOpacity = BrowserSniffer.isTouchDevice() ? .96 : .98;
  const mousePosition = useMousePosition(-500, -500)
  let position = ({x: -500, y: -500});
  if (!BrowserSniffer.isTouchDevice()) {
    position = mousePosition;
  }
  const scrollPosition = useScrollPosition();
  const spotlightRadius = 150;
  // const spotlightRadius = 1;
  const el = Dom.findFirst('.pageSpotlight');
  const elOffset = el ? el.getBoundingClientRect().top : 0;
  const yOffset = elOffset * -1 + (scrollPosition * 0); // scrollPosition is just there to trigger update on scroll

  //dark 
  // rgb(22,15,33
  
  //purple
  // rgb(103,73,237

  // red
  // rgb(241,71,125

  const gradientStyle = {
    "background": `radial-gradient(circle ${spotlightRadius}px at ${position.x}px ${position.y+yOffset}px, rgb(22,15,33,0) 0%, rgb(22,15,33,0) 99%, rgb(22,15,33,${bgOpacity}) 100%)`
  }
  const clipMask = () => {
    return (
      <svg 
        width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath 
            clipRule='evenodd'
            id="spotlightClipPath">
            <path 
              d={`M0 0v40000h4000V0Zm${position.x-spotlightRadius},${position.y+yOffset}a${spotlightRadius},${spotlightRadius} 0 1,0 ${spotlightRadius*2},0a${spotlightRadius},${spotlightRadius} 0 1,0 ${spotlightRadius*-2},0Z`} />
          </clipPath>
        </defs>
      </svg>
    )
  }
  const renderMessage = ()=> {
    return Arr.mapTimes(props.repeatMessageTimes, (i)=> {
      return (
        <div 
          key={`m-${i}`}
          className='pageSpotlight--message--item'>
          <Markdown>
            {props.message}
          </Markdown>
        </div>
      );
    })
    
  }
  return (
    <div 
      className='pageSpotlight'>
      <div 
        className='pageSpotlight--bg'
        style={gradientStyle}></div>
      {props.message ? 
        <div className='pageSpotlight--message'>
          {clipMask()}
          <div className='pageSpotlight--message--content'>
            {renderMessage()}
          </div>
        </div>
      : null}
    </div>
  )
}


/* <path 
            fillRule='evenodd'
            d="M0 0v400h4000V0Zm444,222a100,100 0 1,0 200,0a100,100 0 1,0 -200,0Z"/> */

            // <rect width="50%" height="100%" mask="url(#hole)" />