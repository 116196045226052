import React, {useState} from 'react'
import PropTypes from 'prop-types'
import RadioButtonInputOption from './RadioButtonInputOption'
import Arr from '../../Utils/Arr'

export default function RadioButtonsInput(props) {
  const findSelectedOptionFromValue = (value) => {
    if (value) {
      return props.options.find((option) => {
        return option.value === value;
      })
    }
  }
  const [selectedOption, setSelectedOption] = useState(findSelectedOptionFromValue(props.value));

  const handleChange = (option) => {
    setSelectedOption(option);
    props.onChange(option.value);
  }
  const renderOptions = () => {
    return Arr.mapWithIndex(props.options, (option, i) => {
      return (
        <RadioButtonInputOption
          key={`opt-${i}-${option.value}`}
          option={option}
          i={i}
          currentValue={selectedOption && selectedOption.value}
          onChange={handleChange} />
      );
    })
  }
  
  return (
    <div className={`formInput radioButtons`}>
      <div className='radioButtons--label'>
        {props.label}
      </div>
      <div className='radioButtons--options'>
        {renderOptions()}
      </div>
    </div>
  )
}

RadioButtonsInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func
}