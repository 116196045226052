import { useEffect, useState } from "react";

export const useMousePosition = (initialX, initialY) => {
  const [position, setPosition] = useState({ x: initialX || 0, y: initialY || 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return position;
};