import React from 'react'
import { Link } from 'react-router-dom'

export default function LicensePickerIntro() {
  return (
    <div className='licensePicker--intro'>
      <h4>Universal License</h4>
      <p>The pricing of our universal licenses are based on the number of employees in the company that the license will belong to. If these fonts will be used for client work, the license should be in the client’s name, and based on their size. In return, this license option puts no limits on desktop, web, broadcast, and app usage. <Link 
          to='/licensing' target='_blank' rel='noreferrer'>Read&nbsp;more</Link>
      </p>
      <p>
        Need help or something custom? Email us: <a href='mailto:hello@vectrotype.com'>hello@vectrotype.com</a>
      </p>
    </div>
  )
}
