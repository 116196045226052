import React, {useState, useContext} from 'react'
import BackendLayout from '../Layouts/BackendLayout'
import { UserContext } from '../../Contexts/UserContext'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api';
import {useSignedOutRedirect} from '../../Hooks/UseSignedOutRedirect'
import { GrowlerContext } from '../../Contexts/GrowlerContext'


export default function AccountAccountPage() {
  useSignedOutRedirect('/sign-in');
  const user = useContext(UserContext);
  const growler = useContext(GrowlerContext);


  const getInitialUserState = ()=> {
    if (user.signedIn) {
      return {
        first_name: user.attributes.first_name,
        last_name: user.attributes.last_name,
        company: user.attributes.company,
        email: user.attributes.email
      }
    } else {
      return null;
    }
  }
  const [formData, setFormData] = useState(getInitialUserState())
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleChange = (attr, value) => {
    setFormData({
      ...formData,
      [attr]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    Api.put.user({
      id: user.attributes.id,
      body: formData,
      success: (r)=>{
        if (r.status === 200) {
          user.onMergeAttributes(r.user);
          growler.addMessage('Save Successful');
        }
        setErrors(r.errors);
        setSubmitting(false);
      },
      error: (message, r)=>{
        setErrors(r.errors);
        setSubmitting(false);
      }
    })
  }

  return (
    <BackendLayout
      mode='account'
      pageName='Account'
      className='accountAccount'>
      <h2>Account</h2>
      <form onSubmit={handleSubmit}>
        <TextInput
          name='first_name'
          label='First Name'
          defaultValue={formData.first_name}
          errors={errors}
          onChange={(v)=>{handleChange('first_name', v)}} />
        <TextInput
          name='last_name'
          label='Last Name'
          defaultValue={formData.last_name}
          errors={errors}
          onChange={(v)=>{handleChange('last_name', v)}} />
        <TextInput
          name='company'
          label='Company'
          defaultValue={formData.company}
          errors={errors}
          onChange={(v)=>{handleChange('company', v)}} />
        <TextInput
          key='email'
          label='Email'
          name='email'
          defaultValue={formData.email}
          errors={errors}
          onChange={(v)=>{handleChange('email', v)}} />

        <div className='form--actions'>
          <button
            disabled={submitting}
            className='btn'>
            Save
          </button>
        </div>
      </form>

    </BackendLayout>
  )
}
