import React from 'react'
import Markdown from '../Markdown/Markdown'
import {Link} from 'react-router-dom'

export default function TypefacePageInfo(props) {
  const renderCredits = () => {
    return props.typeface.credits.map((credit) => {
      return (
        <div 
          key={`credit-${credit.title}`}
          className='typefacePage--info--credit'>
          <div className='typefacePage--info--creditTitle'>{credit.title}
          </div>
          <Markdown
            className='typefacePage--info--creditContent'>{credit.content}</Markdown>
        </div>
      );
    })
  }
  const renderAdditionalLinks = () => {
    return props.typeface.additionalLinks.map((l)=>{
      return (
        <div key={l.title}>
          {l.url ? 
            <a href={l.url}>
              {l.title}
            </a>
          : 
            <span>{l.title}</span>
          }
        </div>
      )
    })
  }
  const renderAwardsAndPressLinks = () => {
    return props.typeface.awardsAndPress.map((l)=>{
      return (
        <div key={l.title}>
          {l.url ? 
            <a href={l.url}>
              {l.title}
            </a>
          : 
            <span>{l.title}</span>
          }
        </div>
      )
    })
  }
  return (
    <div className='typefacePage--info'>
      <div className='typefacePage--info--section'>
        <h6>About</h6>
        <div className='typefacePage--info--topContent'>
          <div className='typefacePage--info--description'>
            <Markdown>{props.typeface.description}</Markdown>
            {props.typeface.processUrl ?
              <a 
                className="typefacePage--processUrl"
                href={props.typeface.processUrl}>Read about the process</a>
            : null}
            
          </div>
          <div className='typefacePage--credits'>
            <h3>Credits</h3>
            {renderCredits()}

          </div>
        </div>
      </div>
      <div className='typefacePage--info--section'>
        <h6>Typeface Information</h6>
        <div className='typefacePage--info--bottomContent'>

          <div className='typefacePage--info--bottomCol'>
            <h3>Specs</h3>
            {props.typeface.releaseDateExtended ? 
              <div>
                <Markdown>{props.typeface.releaseDateExtended}</Markdown>
              </div>
            : 
              <div>
                Released {props.typeface.releaseDate}
              </div>
            }

            {props.typeface.specimenPDF ? 
              <div className='typefacePage--specimenLink'><a 
                href={props.typeface.specimenPDF}
                rel="noreferrer"
                target="_blank">{props.typeface.specimenPDFTitle ? props.typeface.specimenPDFTitle : "Detailed Specimen PDF"} ⤓</a>
              </div>
            : null}
            {props.typeface.additionalLinks ?
              <div className='typefacePage--additionalLinks'>
                {renderAdditionalLinks()}
              </div>
            : null}
          </div>

          <div className='typefacePage--info--bottomCol'>
            <h3>OpenType Features</h3>
            <Markdown>{props.typeface.opentypeFeatures}</Markdown>
          </div>

          {props.typeface.variable ? 
            <div className='typefacePage--info--bottomCol'>
              <h3>Variable</h3>
              <Markdown>{props.typeface.variableNotes}</Markdown>
            </div>
          : null }

          {props.typeface.awardsAndPress ? 
            <div className='typefacePage--info--bottomCol'>
              <h3>Awards and Press</h3>
              {renderAwardsAndPressLinks()}
            </div>
          : null }
        </div>
      </div>
    </div>
  )
}
