import { useEffect, useState } from "react"
import Track from "../Utils/Track"

export function useTrackPageHit(pageName, opts={}) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      Track.pageHit({
        page: pageName,
        ...opts
      }); 
      setMounted(true);
    }
  }, [pageName, opts, mounted])
  
};