import React, {useState} from 'react'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'
import Table from '../Tables/Table'
import DateHelper from '../../Utils/DateHelper'
import { usePagination } from '../../Hooks/UsePagination'
import AppVars from '../../Utils/AppVars'

export default function AdminTrialDownloadsPage() {
  const [trialDownloads, setTrialDownloads] = useState([]);
  const [ready, setReady] = useState(false);

  const fetchData = (page) => {
    Api.get.adminTrialDownloads({
      params: {
        page: page
      },
      success: (r)=>{
        if (r.status === 200){
          setTrialDownloads(r.trial_downloads);
          setTotalPages(r.total_pages);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    })
  }

  const {renderPagination, setTotalPages} = usePagination(fetchData, 'main');

  const formattedData = trialDownloads.map((trialDownload)=>{
    return {
      ...trialDownload,
      date: DateHelper.slashToFull(trialDownload.created_at_formatted_short),
      user: () => {
        return (
          <div>
            {trialDownload.user.full_name} <br />
            {trialDownload.user.email}
          </div>
        )
      },
      numOrders: trialDownload.user.orders_count
    }
  })

  return (
    <BackendLayout 
      mode='admin'
      expandedHeader={true}
      pageName='home'
      showHomeThemeNav={true}>
        <h2>Trial Downloads</h2>
        <div className='adminTrialDownloads--testBtn'>
          <a 
            href={AppVars.trialDownloadUrl}
            className='btn btn--superSmall btn--outline'>
            Test Trial Download
          </a>
        </div>
        {ready ? 
          <div>
            <Table
              className='adminTrialDownloads--list'
              cols={COLUMNS}
              data={formattedData}
              keyCol='id' />
            {renderPagination()}
          </div>
        : null}
    </BackendLayout>
  )
}
const COLUMNS = [
  {
    name: 'date',
    displayName: 'Date'
  },
  {
    name: 'user',
    displayName: 'User',
    isRich: true
  },
  {
    name: 'potential_use',
    displayName: 'Potential Use',
  },
  {
    name: 'numOrders',
    displayName: '# orders'
  }
]