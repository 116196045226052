import React, {useState, useEffect} from 'react'
import MainLayout from '../Layouts/MainLayout'
import BlogPostData from '../../Data/BlogPost'
import Markdown from '../Markdown/Markdown'
import DateHelper from '../../Utils/DateHelper'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'
import {Link} from 'react-router-dom'
import Url from '../../Utils/Url'

export default function BlogPostPage(props) {
  const [content, setContent] = useState('');
  const blogPost = BlogPostData.findBySlug(props.match.params.slug);

  useTrackPageHit(blogPost ? `Blog: ${blogPost.title}` : 'Blog Post 404')

  useEffect(()=>{
    if (blogPost) {
      blogPost.getContent((c)=>{
        setContent(c);
      })
    }
    
  }, [blogPost, setContent]);
  const metaData = () => {
    if (blogPost) {
      return {
        title: blogPost.title,
        description: blogPost.thumbnailContent,
        image: Url.assetUrlFallback(blogPost.coverImgPath)
      }
    }
  }
  const imageSizeClass = blogPost.imageSizeUseNatural ? 'md--naturalSizedImages' : '';
  return (
    <MainLayout 
      meta={metaData()}
      pageName='blogPage'>
      <div className={`blogPage blogPost--${blogPost.id} blogPost--${blogPost.slug} ${imageSizeClass}`}>
        {blogPost ? 
          <div className='blogPage--main'>    
            <h1>{blogPost.title}</h1>
            <div className='blogPage--credits'>
                <span>{blogPost.author}</span> — {DateHelper.slashToFull(blogPost.date)}
            </div>
            {blogPost.coverImgPath ? 
              <div className="blogPage--coverImg">
                <a href={Url.assetUrlFallback(blogPost.coverImgPath)}
                  target="_blank"
                  rel="noref">
                  <img 
                    src={Url.assetUrlFallback(blogPost.coverImgPath)} />
                </a>
              </div>
            : null}
            <Markdown className='blogPage--content'>
              {content}
            </Markdown>
          </div>
        :
          <div className='blogPage--main'> 
            <h1>Blog Post Not found</h1>
            <div className='blogPage--content'>
              <p>Sorry, we couldn't find what you were looking for {':('} 
              <br />
              <Link to='/blog'>Back to the blog</Link></p>
            </div>
          </div>
        }
        
      </div>
    </MainLayout>
  )
}