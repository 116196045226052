import React, {useRef, useContext} from 'react'
import {ReactComponent as Logo} from '../../Svg/Vectro-Logo-B&W.svg';
import { Link } from 'react-router-dom'
import AppVars from '../../Utils/AppVars'
import NewsletterForm from '../NewsletterForm/NewsletterForm'
import { useIsVisible } from '../../Hooks/UseIsVisible';
import { UIContext } from '../../Contexts/UIContext';
import { UserContext } from '../../Contexts/UserContext'
import Scroll from '../../Utils/Scroll'
import Typeface from '../../Data/Typeface'

export default function Footer() {
  const user = useContext(UserContext);
  const ref = useRef();
  const ui = useContext(UIContext);

  const handlePositionChange = (position, isVisible) => {
    ui.onChangeThemeNavHidden(isVisible);
  }
  useIsVisible(ref, "0px", handlePositionChange);

  const renderTypefaces = ()=> {
    return Typeface.all.map((typeface)=> {
      return (
        <li key={`t-${typeface.id}`}>
          <Link to={typeface.path}>
            {typeface.name}
          </Link>
        </li>
      )
    })
  }

  return (
    <footer
      ref={ref}>
      <button
        className='btn--noStyle footer--backToTopBtn'
        onClick={()=>{Scroll.to('body')}}>
          Back to Top ↑
      </button>
      <div
        className='footer--logo'>
        <Logo />
      </div>
      <div className='footer--main'>
        <div className='footer--main--links'>
          <div className='footer--typefaces'>
            <div className='footer--header'>
              Fonts:
            </div>
            <ul>
              {renderTypefaces()}
            </ul>
          </div>
          <div className='footer--sitemap'>
            <div className='footer--header'>
              Sitemap:
            </div>
            <ul>
              <li>
                <Link to='/fonts'>
                  Fonts
                </Link>
              </li>
              <li>
                <Link to='/tester'>
                  Tester
                </Link>
              </li>
              <li>
                <Link to='/blog'>
                  Blog
                </Link>
              </li>
              <li>
                <Link to='/studio'>
                  Studio
                </Link>
              </li>
              {user.signedIn ?
                <li>
                  <Link to='/account'>
                    Account
                  </Link>
                </li>
              : null}
            </ul>
          </div>
          <div className='footer--contact'>
            <div className='footer--header'>
              Get in Touch:
            </div>
            <ul>
              <li>
                <a href="mailto:hello@vectrotype.com">Email</a>
              </li>
              <li>
                <a
                  href={`https://www.instagram.com/${AppVars.instagramHandle}/`}
                  target='_blank'
                  rel="noreferrer">Instagram</a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/${AppVars.instagramHandle}`}
                  target='_blank'
                  rel="noreferrer">Twitter</a>
              </li>
              <li>
                <a
                  href={AppVars.youTubeUrl}
                  target='_blank'
                  rel="noreferrer">YouTube</a>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer--newsletter'>
          <NewsletterForm />
        </div>
      </div>
      <div className='footer--bottom'>
        <div className='footer--bottom--links'>
          <ul className='asteriskList'>
            <li>
              <Link to="/trial-fonts">
                Download Trial Fonts
              </Link>
            </li>
            <li>
              <Link to='/licensing'>
                Read Licensing
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer--bottom--legal'>
          <ul className='asteriskList'>
            <li>
              <Link to="/terms">
                Terms of use
              </Link>
            </li>
            <li>
              <Link to='/privacy'>
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer--bottom--colophon'>
          Website Font: <Link to='vctr-mono'>VCTR MONO</Link>
        </div>
      </div>
    </footer>
  )
}
