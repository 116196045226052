import React, {useState} from 'react'

export default function CheckboxInput(props) {
  const [checked, setChecked] = useState(props.defaultChecked);

  const handleClick = (e) => {
    setChecked(!checked);
    props.onChange(!checked);
  }
  const renderInlineLabelContents = () => {
    if (props.renderInlineLabelContents) {
      return props.renderInlineLabelContents();
    } else {
      return props.inlineLabel;
    }
  }
  const multilineClass = props.multiline ? 'checkboxInput--multiline' : '';
  const checkedClass = checked ? 'checkboxInput--checked' : '';
  return (
    <div className={`formInput checkboxInput ${multilineClass} ${checkedClass}`}>
      {props.label ? 
        <div className='checkboxInput--label'>
          {props.label}
        </div>
      : null}
      <button 
        type="button"
        className='checkboxInput--input btn--normalLink'
        onClick={handleClick}>
        <span className='checkboxInput--indicator'></span>
      </button>
      <div 
        htmlFor={props.name}
        onClick={handleClick}
        className='checkboxInput--inlineLabel'>{renderInlineLabelContents()}</div>
    </div>
  )
}
