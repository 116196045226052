export default class Str {
  static toCamelCase(str, initialLower) {
    if (str) {
      str = str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function(match) {
          return match.charAt(match.length-1).toUpperCase();
      });
      if (initialLower) {
        str = this.replaceAtIndex(str, 0, str.charAt(0).toLowerCase());
      }
    } 
    return str;
  }
  static toTitleCase(str) {
    const skipWords = ['a', 'an', 'the', 'for', 'and', 'nor', 'but', 'or', 'so', 'with', 'at', 'from', 'into', 'of', 'to', 'in', 'on', 'by'];
    return str.split(' ').map((word)=>{
      if (skipWords.indexOf(word) === -1) {
        return word.charAt(0).toUpperCase() + word.substring(1, word.length);
      } else {
        return word;
      }
    }).join(' ');
  }
  static replaceAtIndex(str, index, replacement) {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  }
  static replaceAll(str, pattern, replacement) {
    if (str) {
      return str.split(pattern).join(replacement);  
    } else {
      return str;
    }
  }
  static pluralize(count, singular, plural){
    return (parseInt(count) === 1) ? singular : plural;
  }
  static hexToRgba(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map(x => (parseInt(x, 16)) / 255);
    return [r, g, b, alpha];
  }
}