import React, {useEffect, useState} from 'react'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'
import Url from '../../Utils/Url'
import { Link } from 'react-router-dom'

export default function DesignerAdminStripeConnectPage() {
  const [ready, setReady] = useState(false);

  const linkToStripe = () => {
    const params = Url.getUrlParams();
    if (params && params.code) {
      Api.put.userLinkToStripe({
        id: params.state,
        params: {
          by_stripe_temp_token: true,
          stripe_code: params.code
        },
        success:(r)=>{
          window.location = '/designer-admin/payments';
        },
        error: (r)=>{
          setReady(true)
        }
      })
    } else {
      this.setState({
        ready: true
      });
    }
  }

  useEffect(() => {
    linkToStripe()
  }, []);

  return (
    <BackendLayout 
      mode='designerAdmin'
      expandedHeader={true}
      pageName='designerAdminStripeConnect'
      showHomeThemeNav={true}>
        <h2>Connect to Stripe</h2>
        {ready ? 
          <div>
            <p>
            Sorry, we couldn't link to your Stripe account.
            </p>
            <p>
              <Link to='/designer-admin/payments'>Try again</Link> or let Travis know you’re having trouble.
            </p>
          </div>
        : 
          <p>
            Loading...
          </p>
        }
    </BackendLayout>
  )
}
