import React, {useState} from 'react'
import FontTesterStyle from './FontTesterStyle'
import RangeInput from '../Forms/RangeInput'
import {ReactComponent as RefreshIcon} from '../../Svg/Refresh-Icon.svg'
import {ReactComponent as PlayIcon} from '../../Svg/Play-Icon.svg'
import {ReactComponent as PauseIcon} from '../../Svg/Pause-Icon.svg'
import {useInterval} from '../../Hooks/UseInterval'

export default function FontTesterVariable(props) {
  const getInitialAxisValues = () => {
    let axes = [];
    if (props.fontStyle.defaultVariableAxisValues) {
      axes = props.fontStyle.defaultVariableAxisValues
    } else {
      axes = props.typeface.variableAxes.map((axis) => {
        return {
          tag: axis.tag,
          value: axis.default
        }
      });
    }
    return axes.map((axis)=> {
      const axisDefinition = props.typeface.variableAxes.find((a)=> {
        return axis.tag === a.tag;
      })
      return {
        ...axis,
        min: axisDefinition.min,
        max: axisDefinition.max,
        range: axisDefinition.max - axisDefinition.min,
        direction: 'up',
        animating: false
      }
    })
  };
  const getInitialAxisValue = (axisTag) => {
    const initialAxis = getInitialAxisValues().find((axis)=> {
      return axis.tag === axisTag
    })
    return initialAxis.value;
  }
  const [axisValues, setAxisValues] = useState(getInitialAxisValues());

  const updateAxis = (axisTag, attributes) => {
    let newAxisValues = [].concat(axisValues);
    var index = newAxisValues.findIndex((axis)=> {
      return axis.tag === axisTag;
    });
    newAxisValues[index] = {
      ...newAxisValues[index],
      ...attributes
    }
    setAxisValues(newAxisValues);
  }

  const handleChangeAxisValue = (targetAxis, newValue) => {
    updateAxis(targetAxis.tag, {
      value: newValue,
      animating: false
    })
  }
  const handleResetAxis = (targetAxis) => {
    updateAxis(targetAxis.tag, {
      value: getInitialAxisValue(targetAxis.tag),
      direction: 'up',
      animating: false
    })
  }
  const getAxis = (axisTag) => {
    return axisValues.find((axis)=> {
      return axisTag === axis.tag;
    })
  }
  const getAxisValue = (tag) => {
    return getAxis(tag).value;
  }
  const getAxisValueAsPct = (tag) => {
    const axis = getAxis(tag);
    return (axis.value - axis.min) / axis.range;
  }
  const getRoundedAxisValue = (tag) => {
    return Math.round(getAxisValue(tag) * 100) / 100;
  }

  const toggleAnimateAxis = (targetAxis) => {
    const axis = getAxis(targetAxis.tag)
    updateAxis(targetAxis.tag, {
      ...axis,
      animating: !axis.animating
    })
  }
  const getNextAxisValueAndDirection = (axis) => {
    if (axis.animating) {
      const interval = axis.direction === 'up' ? 1 : -1;
      const pct = Math.round(getAxisValueAsPct(axis.tag) * 100);
      let nextPct = pct + interval;
      let nextDirection = axis.direction;
      if (nextPct > 100) {
        nextPct = 100;
        nextDirection = 'down';
      } else if (nextPct < 0) {
        nextPct = 0;
        nextDirection = 'up';
      }
      const nextValue = ((nextPct / 100) * axis.range) + axis.min;
      return {
        value: nextValue,
        direction: nextDirection
      }
    } else {
      return {
        value: axis.value,
        direction: axis.direction
      }
    }
  }
  const incrementAxes = () => {
    const newAxisValues = axisValues.map((axis) => {
      return {
        ...axis,
        ...getNextAxisValueAndDirection(axis)
      };
    })
    setAxisValues(newAxisValues);
  }

  const animatingAxes = axisValues.filter((axis)=>{
    return axis.animating;
  })
  useInterval(() => {
    incrementAxes()
  }, (animatingAxes.length > 0) ? 50 : null);
  
  const renderExtraControlsBefore = (axis) => {
    return(
      <div className='fontTester--variableAxis--extraControlsBefore'>
        <button
          className='btn--noStyle fontTester--variableAxis--animationBtn'
          onClick={()=>{toggleAnimateAxis(axis)}}>
          {getAxis(axis.tag).animating ? 
            <PauseIcon
              className='icon' />
          : 
            <PlayIcon
              className='icon' />
          }
        </button>  
      </div>
    )
  }
  const renderExtraControlsAfter = (axis) => {
    return (
      <div className='fontTester--variableAxis--extraControlsAfter'>
        <button 
          className='btn--noStyle fontTester--variableAxis--resetBtn'
          onClick={()=>{handleResetAxis(axis)}}>
          <RefreshIcon
            className='icon' />
        </button>
      </div>
    )
  }
  const renderAxisLabel = (axis) => {
    return (
      <label>
        {axis.label}({axis.tag}) <span className='fontTester--variableAxis--value'>{getRoundedAxisValue(axis.tag)}</span>
      </label>
    )
  }
  const renderAxes = () => {
    return props.typeface.variableAxes.map((axis) => {
      const modified = getAxisValue(axis.tag) !== getInitialAxisValue(axis.tag);
      const modifiedClass = modified ? 'input--modified' : '';
      return (
        <div 
          key={axis.tag}
          className={`fontTester--variableAxis ${modifiedClass}`}>
          <RangeInput
            name={axis.tag}
            renderLabel={()=>{return renderAxisLabel(axis)}}
            min={axis.min}
            max={axis.max}
            onChange={(v)=>{handleChangeAxisValue(axis, v)}}
            renderBeforeInput={()=> {return renderExtraControlsBefore(axis)}}
            renderAfterInput={()=>{return renderExtraControlsAfter(axis)}}
            value={getAxisValue(axis.tag)} />
        </div>
      );
    })
  }
  const getVariableStyles = () => {
    const values = axisValues.map((axis)=> {
      return `'${axis.tag}' ${axis.value}`;
    })
    return {
      fontVariationSettings: values.join(', ')
    }
  }
  return (
    <div className={`fontTester--variable fontTester--variable--numAxes-${axisValues.length}`}>
      <FontTesterStyle
        typeface={props.typeface}
        fontStyle={props.fontStyle}
        extraStyle={getVariableStyles()} />

      <div className='fontTester--variableControls'>
        <div className='fontTester--variableControls--axes'>
          {renderAxes()}
        </div>
      </div>
    </div>
  )
}
