import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import AppVars from '../../Utils/AppVars'
import { useIsVisible } from '../../Hooks/UseIsVisible'

export default function HomeTesterFontStyle(props) {
  const ref = useRef();
  const {lazyClass} = useIsVisible(ref, "0px", ()=>{}, props.typeface.slug);

  const fontSize = props.typeface.useThrottledFontSize ? props.throttledFontSize : props.fontSize;
  const scaledFontSize = props.typeface.testerFontSizeMultiplier ? (props.typeface.testerFontSizeMultiplier * fontSize) : fontSize;
  const sharedStyles = {
    fontFamily: props.typeface.fontFamily,
    fontSize: `${scaledFontSize}vw`,
    top: props.typeface.testerPageTesterVerticalOffset || props.typeface.testerVerticalOffset || 0,
    lineHeight: props.typeface.testerLineHeight || 1,
    ...props.fontStyle.style
  };
  const throttlingClass = (props.typeface.useThrottledFontSize && props.isFontSizeThrottling) ? 'fontSize--isThrottling' : '';
  const renderTextString = () => {
    return (
      <div 
        className="testerPage--fontStyle--textWrapper">
        <div 
          className='testerPage--fontStyle--text'
          style={sharedStyles}>
          {props.text}
        </div>
        {props.fontStyle.layered ? 
          <div 
            className='testerPage--fontStyle--text--layer2'
            style={{
              ...sharedStyles,
              fontFamily: props.typeface.fontFamilyLayered
            }}>
            {props.text}
          </div>
        : null }
      </div>
    )
  }
  const renderImg = () => {
    return (
      <div 
        style={sharedStyles}
        className='testerPage--fontStyle--img'>
        <img 
          className='monochromeImg'
          src={`${AppVars.assetsUrl}${props.fontStyle.img}`}
          alt={props.typeface.name} />
      </div>
    );
  }
  return (
    <Link 
      ref={ref}
      to={props.typeface.path}
      className={`testerPage--fontStyle ${throttlingClass} ${lazyClass}`}>
      <div className='testerPage--fontStyle--title'>
        <h6>{props.typeface.name} {props.fontStyle.name}</h6>
      </div>
      
        {props.fontStyle.useImg ? 
          renderImg()
        : 
          renderTextString() 
        }
    </Link>
  )
}
