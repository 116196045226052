import React from 'react'
import ErrorMessage from './ErrorMessage'
import { uid } from 'uid'

export default function TextInput(props) {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }
  const fieldClassName = `formInput--${props.name}`;
  const hasError = props.errors && props.errors[props.name];
  const errorClass = hasError ? 'formInput--hasError' : ''
  const valueProp = (props.value || props.controlled) ? {value: props.value} : {};
  const defaultValueProp = (props.controlled ? {} : {defaultValue: props.defaultValue})
  return (
    <div className={`formInput textInput ${props.className} ${fieldClassName} ${errorClass}`}>
      {props.label ? 
        <label htmlFor={props.name}>{props.label}</label>
      : null }
      <input 
        type={props.type}
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        onChange={handleChange}
        tabIndex={props.tabindex}
        autoComplete={props.autoComplete ? "on" : `off-${uid(8)}`}
        onFocus={()=>{props.onFocus && props.onFocus()}}
        readOnly={props.readOnly}
        {...defaultValueProp}
        {...valueProp} />

      {props.hint ? 
        <div className='formInput--hint'>
          {props.hint}
        </div>
      : null}
      
      <ErrorMessage 
        name={props.name}
        errors={props.errors} />
    </div>
  )
}

TextInput.defaultProps = {
  value: '',
  defaultValue: '',
  type: 'text',
  className: '',
  autoComplete: true,
  value: undefined,
  controlled: false,
  readOnly: false
}
