export default class AltSyntaxTranslator {
  static translate(chartStyle, inSyntax) {
    
    switch (chartStyle) {
      case 'areas':
      case 'areas-ranges':
      case 'lines':
      case 'steps':
        return this.lines(inSyntax);
      case 'bars':
      case 'histograms':
        return this.bars(inSyntax);
      case 'pies':
        return this.pies(inSyntax);
      case 'progress-bars':
        return this.progressBars(inSyntax);
      case 'radar':
        return this.radar(inSyntax);
      case 'rose':
        return this.rose(inSyntax);
      case 'rings':
        return this.rings(inSyntax);
      default:
        return inSyntax;
    }
  }

  // styles
  static pies(inSyntax){
    const values = inSyntax.split('+');
    let sum = 0;
    let newValues = [];
    values.forEach((v)=> {
      if (v[0] === 'x') {
        newValues.push(v);
      } else {
        const cleanVal = this.cleanVal(v);
        if (cleanVal) {
          if (sum > 0) {
            newValues.push(`${cleanVal}_${sum}`)
          } else {
            newValues.push(cleanVal)
          }
          sum+= Math.round(cleanVal);
        }
      }
    })
    return newValues.join('+');
  }
  static lines(inSyntax){
    return this.parseLineValues(inSyntax).concattedValues.join('+');
  }
  static bars(inSyntax){
    //10+30|20|10+30
    const parsedValues = this.parseLineValues(inSyntax);
    const newCoreValues = parsedValues. coreValues.map((fullValue)=>{

      const barValues = fullValue.split('|');
      let prevTop = 0;
      
      const newBarValues = [];
      barValues.forEach((v,i)=>{
        if (v) {
          const rangedVal = v.split(',');
          if (i===0 || rangedVal.length > 1) {
            prevTop = parseInt(rangedVal[rangedVal.length -1]);
            newBarValues.push(v);
            
          } else {
            const newTop = prevTop + parseInt(v);
            newBarValues.push(`${prevTop},${newTop}`);
            prevTop = newTop;
          }
        }
      })
      
      return newBarValues.join('|');
    })
    const concattedValues = parsedValues.gridValues.concat(newCoreValues).concat(parsedValues.refValues);
    return concattedValues.join('+')
  }
  // static areasRanges(inSyntax){
    
  //   const parsedValues = this.parseLineValues(inSyntax);
  //   const newCoreValues = parsedValues. coreValues.map((fullValue)=>{
  //   }
  // }
  static radar(inSyntax){
    const parsedValues = this.parseLineValues(inSyntax)
    let newCoreValues = [];
    const numValues = parsedValues.coreValues.length;
    parsedValues.coreValues.forEach((val,i)=>{
      const isLast = (numValues - 1) === i;
      const nextIndex = isLast ? 0 : i + 1;

      newCoreValues.push(`${val},${parsedValues.coreValues[nextIndex]}_${i+1}/${numValues}`);
    })
    const concattedValues = parsedValues.gridValues.concat(newCoreValues)
    return concattedValues.join('+');
  }
  static rose(inSyntax){
    const parsedValues = this.parseLineValues(inSyntax)
    const newCoreValues = parsedValues.coreValues.map((val,i)=>{
      return `${val}_${i+1}/${parsedValues.coreValues.length}`;
    })
    const concattedValues = parsedValues.gridValuesUnformatted.concat(newCoreValues)
    return concattedValues.join('+');
  }
  static rings(inSyntax){
    const parsedValues = this.parseLineValues(inSyntax)
    let isReversed = false;
    const newGridValues = parsedValues.gridValuesUnformatted.map((v) => {
      if (v.indexOf('r') > -1) {
        isReversed = true;
      }
      if (v.indexOf('s') > -1) {
        return v;
      } else {
        return `${v}s`
      }
    });
    const newCoreValues = parsedValues.coreValues.map((val,i)=>{
      if (isReversed) {
        return `${val}_${10 - i}`;
      } else {
        return `${val}_${i+1}`;
      }
      
    })
    
    const concattedValues = newGridValues.concat(newCoreValues)
    return concattedValues.join('+');
  }
  static progressBars(inSyntax){
    const splitValues = inSyntax.split('+');
    let addRoundToStart = false;
    let addRoundToEnd = false;
    let newValues = [];
    
    splitValues.forEach((v,i)=>{
      newValues.push(v);
      if ((i===0) && (v.indexOf('r') >= 0) && splitValues.length > 1) {
        addRoundToEnd = true;
      } else if ((i+1 === splitValues.length) && (v.indexOf('r') >= 0) && splitValues.length > 1) {
        addRoundToStart = true;
      }
    })
    if (addRoundToStart) {
      const gridOptions = ['a','b','c','d','e','f','r'];
      if (gridOptions.indexOf(newValues[0][0]) >= 0) {
        // append to grid value
        newValues[0] = `${newValues[0]}r`
      } else {
        newValues = ['r'].concat(newValues);
      }
    }
    if (addRoundToEnd) {
      const gridOptions = ['a','b','c','d','e','f','r'];
      const lastIndex = newValues.length - 1;
      if (gridOptions.indexOf(newValues[lastIndex][0]) >= 0) {
        // append to grid value
        newValues[lastIndex] = `${newValues[lastIndex]}r`
      } else {
        newValues.push('r');
      }
    }
    return newValues.join('+')
  }

  // helpers
  static cleanVal(v) {
    return v.replace(/[^\d.|,-]/g, '');
  }
  static groupedValues(inSyntax, skipClean) {
    const gridOptions = ['a','b','c','d','e','f','x','r'];
    const values = inSyntax.split('+');
    let gridValues = [];
    let coreValues = [];
    let refValues = [];

    values.forEach((v,i)=>{
      if ((i === 0) && gridOptions.indexOf(v[0]) >= 0) {
        gridValues.push(v)
      } else if ((i === values.length - 1) && v[0] === 'x') {
        refValues.push(v);
      } else {
        if (skipClean) {
          coreValues.push(v);
        } else if (this.cleanVal(v)) {
          coreValues.push(this.cleanVal(v));
        }
      }
    })
    return {
      gridValues,
      coreValues,
      refValues
    }
  }
  static parseLineValues(inSyntax) {
    const v = this.groupedValues(inSyntax);
    const gridValues = v.gridValues;
    const coreValues = v.coreValues;
    const refValues = v.refValues;
  
    const newGridValues = gridValues.map((v)=>{
      return `${v}_${coreValues.length}`
    })
    const newRefValues = refValues.map((v)=>{
      return `${v}_${coreValues.length}`;
    })
    const concattedValues = newGridValues.concat(coreValues).concat(newRefValues);

    return {
      gridValues: newGridValues,
      coreValues,
      refValues: newRefValues,
      concattedValues,
      gridValuesUnformatted: gridValues,
      refValuesUnformatted: refValues
    }
  }
  
}
