import React from 'react'
import {useHistory} from 'react-router-dom'
import Url from '../../Utils/Url'
import Scroll from '../../Utils/Scroll'

export default function Pagination(props) {
  const history = useHistory();
  const pageParam = props.pageParam || 'page'
  const currentPage = props.page || Url.getUrlParams()[pageParam] || 1;
  
  const prepUrl = (params) => {
    const newParams = Url.mergeAndEncode(params, history.location.search);
    return `${history.location.pathname}${newParams}`;
  }
  const navToNewParams = (params)=>{
    history.push(prepUrl(params));
    if (props.scrollToElOnChange) {
      setTimeout(()=>{
        Scroll.to(props.scrollToElOnChange);
      }, 1);
    }
  }
  const newPageNumber = (direction) => {
    if (direction === 'prev') {
      return parseInt(currentPage) - 1
    } else {
      return parseInt(currentPage) + 1
    }
  }
  const handlePageChange = (direction) => {
    const pageNum = {
      prev: parseInt(currentPage) - 1,
      next: parseInt(currentPage) + 1
    };
    navToNewParams({
      [pageParam]: pageNum[direction]
    })
    props.onChange && props.onChange(newPageNumber(direction))
  }

  const preventNavPrev = (currentPage <= 1);
  const preventNavNext = (currentPage >= props.totalPages);
  const displayNumberCurrent = (currentPage === 0 ? 1 : currentPage);
  const displayNumberTotal = (props.totalPages === 0 ? 1 : props.totalPages);
  
  return (
    <div className={`pagination`}>

      <div className='pagination--nav'>
        <button 
          onClick={()=>{handlePageChange('prev')}}
          disabled={preventNavPrev}
          className='btn btn--outline pagination--btnPrev'>
          <span>←</span>
        </button>
        <span className='pagination--currentLocation'>
          {displayNumberCurrent} of {displayNumberTotal}
        </span>
        <button 
          onClick={()=>{handlePageChange('next')}}
          disabled={preventNavNext}
          className='btn btn--outline pagination--btnNext'>
          <span>→</span>
        </button>
      </div>
    </div>
  );
}


