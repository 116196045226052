import React from 'react'
import { NavLink } from 'react-router-dom'

export default function BackendNav(props) {
  const renderOptions = () => {
    return options[props.mode].map((option) => {
      return (
        <li
          key={`opt-${option.label}`}>
          <NavLink
            to={option.path}>
            {option.label}  
          </NavLink>
        </li>
      );
    })
  }
  return (
    <ul className='backendNav'>
      {renderOptions()}
    </ul>
  )
}

const options = {
  'account': [
    {
      label: 'Orders',
      path: '/account/orders'
    },
    {
      label: 'Account',
      path: '/account/account'
    },
    {
      label: 'Password',
      path: '/account/password'
    }
  ],
  'admin': [
    {
      label: 'Orders',
      path: '/admin/orders'
    },
    {
      label: 'Trials',
      path: '/admin/trial-downloads'
    }
  ],
  'designerAdmin': [
    {
      label: 'Typefaces',
      path: '/designer-admin/typefaces'
    },
    {
      label: 'Payments',
      path: '/designer-admin/payments'
    }
  ]
}