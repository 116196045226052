import { useEffect, useState } from "react"
import { useDebounce } from './UseDebounce'

export const useScrollPosition = (throttle=10) => {
  const [position, setPosition] = useState(0);
  const [debouncedPosition, isDebouncing] = useDebounce(position, throttle);

  useEffect(() => {
    const handleScroll = (e)=> {
      if (!isDebouncing) {
        setPosition(window.scrollY);
      }
    }
    
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [setPosition, isDebouncing]);

  return debouncedPosition;
};


// , function(e) {
//   lastKnownScrollPosition = window.scrollY;

//   if (!ticking) {
//     window.requestAnimationFrame(function() {
//       doSomething(lastKnownScrollPosition);
//       ticking = false;
//     });

//     ticking = true;
//   }
// }