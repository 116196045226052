import React from 'react'
import MainLayout from './MainLayout'

export default function NarrowLayout(props) {
  return (
    <MainLayout 
      {...props}
      layoutClassName='narrowLayout' />
  )
}
