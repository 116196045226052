import React, {useState} from 'react'
import Api from '../../Utils/Api'
import BackendLayout from '../Layouts/BackendLayout'
import { usePagination } from '../../Hooks/UsePagination'
import DesignerAdminTypefaceReportsList from './DesignerAdminTypefaceReportsList'
import Typeface from '../../Data/Typeface'

export default function DesignerAdminTypefaceSalesPage(props) {
  const [ready, setReady] = useState(false);
  const [typefaceReports, setTypefaceReports] = useState([]);
  const [currentTypefaceReport, setCurrentTypefaceReport] = useState(null);
  const typeface = Typeface.find(props.match.params.id);

  const fetchData = (page) => {
    Api.get.designerSalesReports({
      params: {
        page: page,
        typeface_id: props.match.params.id
      },
      success: (r)=>{
        if (r.status === 200){
          setTypefaceReports(r.typeface_reports);
          setCurrentTypefaceReport(r.current_typeface_report);
          setTotalPages(r.total_pages);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    })
  }
  const {renderPagination, setTotalPages} = usePagination(fetchData, 'main');
  return (
    <BackendLayout 
      mode='designerAdmin'
      expandedHeader={true}
      pageName='designerAdminTypefaceSales'
      showHomeThemeNav={true}>
        <h2>{typeface.name} Sales</h2>
        {ready ? 
          <div className='designerAdminTypefaceSales--main'>
            <DesignerAdminTypefaceReportsList
              currentTypefaceReport={currentTypefaceReport}
              typefaceReports={typefaceReports} />
            {renderPagination()}
          </div>
        : null}
    </BackendLayout>
  )
}
