import React from 'react'
import {
  BrowserRouter as Router, 
  Switch, 
  Route
} from 'react-router-dom'
import './Styles/main.scss'
import AppVars from './Utils/AppVars'

import {CartContextProvider} from './Contexts/CartContext'
import {UserContextProvider} from './Contexts/UserContext'
import {GrowlerContextProvider} from './Contexts/GrowlerContext'
import { UIContextProvider } from './Contexts/UIContext'

import PlaceholderPage from './Components/Placeholder/PlaceholderPage'
import ScrollToTop from './Components/ScrollState/ScrollState'

import CurrentRoute from './Components/Routing/CurrentRoute'

import BrowserSniffer from './Utils/BrowserSniffer'
BrowserSniffer.init();

function App() {
  return (
    <Router>
      <UIContextProvider>
        <GrowlerContextProvider>
          <UserContextProvider>
            <ScrollToTop />
            <CartContextProvider>
              {AppVars.placeholder ? 
                <Switch>
                  <Route path="/">
                    <PlaceholderPage />
                  </Route>
                </Switch>
              : 
                <CurrentRoute />
              }
            </CartContextProvider>
          </UserContextProvider>
        </GrowlerContextProvider>
      </UIContextProvider>
    </Router>
  );
}

export default App;




