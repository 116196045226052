import React from 'react'
import Arr from '../../Utils/Arr'
import AppVars from '../../Utils/AppVars'

export default function TypefacePageImages(props) {
  const renderImages = () => {
    return Arr.mapWithIndex(props.typeface.images, (image, i) => {
      const monochromeClass = image.monochrome ? 'monochromeImg' : '';
      return (
        <div 
          key={`ti-${i}`}
          className={`typefacePage--image typefacePage--image--size-${image.size}`}>
          <h6 className='typefacePage--image--caption'>
            {image.caption}
          </h6>
          <img 
            className={monochromeClass}
            src={`${AppVars.assetsUrl}${image.url}`}
            alt={image.caption} />
        </div>
      )
    })
  }
  return (
    <div className='typefacePage--images'>
      {renderImages()}
    </div>
  )
}
