export default class DateHelper {
  static slashToAbbreviated(slashedDate) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const splitDate = slashedDate.split('/');
    return `${splitDate[1]} ${months[parseInt(splitDate[0]-1)]} ${splitDate[2]}`;
  }
  static slashToFull(slashedDate) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    const splitDate = slashedDate.split('/');
    return `${splitDate[1]} ${months[parseInt(splitDate[0]-1)]} ${splitDate[2]}`;
  }
}