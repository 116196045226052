import Chartwell from './Typefaces/Chartwell.json'
import Analog from './Typefaces/Analog.json'
import Kicker from './Typefaces/Kicker.json'
import VctrMono from './Typefaces/VctrMono.json'
import Wildberry from './Typefaces/Wildberry.json'
import Whoa from './Typefaces/Whoa.json'
import Kablammo from './Typefaces/Kablammo.json'
import Rainer from './Typefaces/Rainer.json'

// see typefaces.rb for ID numbers

const TYPEFACES = [
  Chartwell,
  Kablammo,
  Rainer,
  VctrMono,
  Analog,
  Whoa,
  Kicker,
  Wildberry
]

// const allRandomized = Arr.shuffle(TYPEFACES);

const find = (id) => {
  return TYPEFACES.find((typeface)=> {
    return typeface.id === parseInt(id)
  })
}

const findBySlug = (slug) => {
  return TYPEFACES.find((typeface)=> {
    return typeface.slug === slug
  })
}

const allHomeFlashy = TYPEFACES.filter((typeface)=>{
  return typeface.includeHomeFlashy;
})

const getAllFontStyles = () => {
  const fontStyles = [];
  TYPEFACES.forEach((typeface) => {
    typeface.fontStyles.forEach((fontStyle, i)=>{
      if (!fontStyle.hideFromHomeTester) {
        fontStyles.push({
          typeface: typeface,
          fontStyle: fontStyle,
          uid: `${typeface.id}-${i}`
        })
      }
    })
  });
  return fontStyles;
}

const main = {
  all: TYPEFACES,
  findBySlug,
  find,
  allHomeFlashy,
  getAllFontStyles
}

export default main;
