import React, {useState} from 'react'
import AppVars from '../../Utils/AppVars'
import ThreeDScreensaver from './ThreeDScreensaver'
import { useIdleTimer } from 'react-idle-timer'

export default function SiteScreensaver() {
  const [screensaverActive, setScreensaverActive] = useState(false);
  const handleOnIdle = () => {
    setScreensaverActive(true);
  }
  const handleOnActive = () => {
    setScreensaverActive(false);
  }
  useIdleTimer({
    timeout: 1000 * 60 * 3,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 1000
  });
  
  if (AppVars.screensaverActive && screensaverActive) {
    return (
      <ThreeDScreensaver />
    )
  }
  return null;
}
