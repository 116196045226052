import React from 'react'

export default function SyntaxConverterPreviewChart(props) {
  const renderGrids = ()=>{
    return props.altFormulaGroupedValues.gridValues.map((v,i)=> {
      const finalValue = `${v}+`
      return (
        <span className='chartwellChart--grid' key={`cg-${i}`}>{finalValue}</span>
      )
    })
  }
  const renderWithBars3DTopper = ()=>{
    
    if (props.formula.slice(-1) === '=') {
      return (
        <span className='chartwellChart--bars3DTopper'>=</span>
      )
    } else {
      return null
    }
  }
  const renderSubValues = ()=> {
    return props.altFormulaGroupedValues.coreValues.map((v,i)=>{
      const isLastCoreValue = (i >= (props.altFormulaGroupedValues.coreValues.length - 1));
      const anyRefValues = (props.altFormulaGroupedValues.refValues.length > 0)
      const plus = (!isLastCoreValue || anyRefValues) ? '+' : '';

      const subValues = v.split('|');
      return subValues.map((subv, subi)=>{
        const isLastSubVal = ((subValues.length - 1) === subi)
        const barGlyph = isLastSubVal ? '' : '|';
        
        let safeV = subv.replace('=', ''); // bars 3D topper
        const finalValue = `${safeV}${barGlyph}${isLastSubVal ? plus : null}`;

        return (
          <span className={`chartwellChart--coreValue chartwellChart--subValue-${subi}`} key={`cv-${i}-${subi}`}>{finalValue}</span>
        );
      })
    })
  }
  const renderCoreValues = ()=>{
    if (props.separateCoreValues) {
      return renderSubValues();
    } else {
      const lastPlus = (props.altFormulaGroupedValues.refValues.length > 0) ? '+' : '';
      return (
        <span className={`chartwellChart--coreValue chartwellChart--subValue-0`} key={`cv-0`}>{props.altFormulaGroupedValues.coreValues.join('+')}${lastPlus}</span>
      );
    }
  }
  const renderRefs = ()=>{
    return props.altFormulaGroupedValues.refValues.map((v,i)=> {
      return (
        <span className='chartwellChart--ref' key={`cr-${i}`}>{v}</span>
      )
    })
  }
  return (
    <div className={`syntaxConverterPreview--chart chartwellChart chartwellChart--${props.selectedChartKey}`}>
      {renderGrids()}{renderCoreValues()}{renderRefs()}{renderWithBars3DTopper()}
    </div>
  )
}
