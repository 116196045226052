import React, {useState, useContext, useEffect} from 'react'
import SignUpForm from './SignUpForm'
import SignInForm from './SignInForm'
import { UserContext } from '../../Contexts/UserContext'
import { useHistory } from "react-router-dom"
import Url from '../../Utils/Url'



export default function SignInUpForm(props) {
  const [formName, setFormName] = useState(props.initialForm);
  const user = useContext(UserContext);
  const history = useHistory();
  
  useEffect(() => {
    if (user.signedIn) {
      let nextUrl = '';
      if (props.redirectOnSuccess) {
        nextUrl = props.redirectOnSuccess;
      } else if (Url.redirectUrlFromPath()) {
        nextUrl = Url.redirectUrlFromPath();
      };
      // history.push(nextUrl);
    }  
  }, [history, props.redirectOnSuccess, user.signedIn]);
  return (
    <div className='signInUpForm'>
      {formName === 'signIn' ? 
        <SignInForm 
          hideTitle={props.hideTitle}
          onToggleForm={()=>{setFormName('signUp')}}
          redirectOnSuccess={props.redirectOnSuccess} 
          buttonText={props.buttonText} />
      :
        <SignUpForm
          hideTitle={props.hideTitle}
          onToggleForm={()=>{setFormName('signIn')}}
          redirectOnSuccess={props.redirectOnSuccess}
          hideNewsletter={props.hideNewsletter}
          buttonText={props.buttonText} />
      }
    </div>
  )
}

SignInUpForm.defaultProps = {
  initialForm: 'signUp',
  hideTitle: false
}
