import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import { useIsVisible } from '../../Hooks/UseIsVisible'
import {ReactComponent as ComingSoonBadge} from '../../Svg/Coming-Soon-Badge.svg'
import TypefaceBadge from '../TypefaceBadge/TypefaceBadge';

export default function HomeFlashySection(props) {
  const ref = useRef();
  const {lazyClass} = useIsVisible(ref, "-25%", ()=>{}, props.typeface.slug);

  return (
    <div 
      ref={ref}
      className={`home--cards--${props.typeface.slug} home--cards--card home--cards--font ${lazyClass}`}>
      <div className='home--cards--lazyPlaceholder'>
      </div>
      <Link 
        to={props.typeface.path}
        className={'home--cards--fontExample ignoreScale'}>
        <div 
          className='home--cards--fontExample--content'
          dangerouslySetInnerHTML={{ __html: props.typeface.homeFlashyContent.exampleContent }}></div>
        {props.typeface.comingSoon ? 
          <ComingSoonBadge />
        : null}
        {props.typeface.badge ?
          <TypefaceBadge 
            typeface={props.typeface} />
        : null}
      </Link>
      <div className='home--cards--meta'>
        <Link 
          className='home--cards--name'
          to={props.typeface.path}>
          {props.typeface.name}
        </Link>
      </div>
    </div>
  )
}
