import React, {useContext, useState} from 'react'
import Api from '../../Utils/Api'
import { UserContext } from '../../Contexts/UserContext'
import BackendLayout from '../Layouts/BackendLayout'
import { usePagination } from '../../Hooks/UsePagination'
import DesignerAdminOrdersList from './DesignerAdminOrdersList'
import Typeface from '../../Data/Typeface'

export default function DesignerAdminTypefaceOrdersPage(props) {
  const user = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [ready, setReady] = useState(false);
  const typeface = Typeface.find(props.match.params.id);

  const fetchData = (page) => {
    Api.get.designerOrders({
      id: user.attributes.id,
      params: {
        page: page,
        typeface_id: props.match.params.id
      },
      success: (r)=>{
        if (r.status === 200){
          setOrders(r.orders);
          setTotalPages(r.total_pages);
        }
        setReady(true);
      },
      error: (r)=>{
        setReady(true);
      }
    })
  }
  
  const {renderPagination, setTotalPages} = usePagination(fetchData, 'main');
  return (
    <BackendLayout 
      mode='designerAdmin'
      expandedHeader={true}
      pageName='designerAdminTypefaces'
      showHomeThemeNav={true}>
        <h2>{typeface.name} Orders</h2>
        {ready ? 
          <div className='adminOrders--main'>
            <DesignerAdminOrdersList
              orders={orders} />
            {renderPagination()}
          </div>
        : null}
    </BackendLayout>
  )
}
