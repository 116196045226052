import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import Typeface from '../../Data/Typeface'
import { Link } from 'react-router-dom'
import Arr from '../../Utils/Arr'
import AppVars from '../../Utils/AppVars'
import {ReactComponent as ComingSoonBadge} from '../../Svg/Coming-Soon-Badge.svg'
import TypefaceBadge from '../TypefaceBadge/TypefaceBadge';
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function HomeBlocks() {
  useTrackPageHit('Fonts');
  const renderTypefaces = () => {
    const numRows2 = Math.ceil(Typeface.all.length / 2);
    const numRows3 = Math.ceil(Typeface.all.length / 3);
    return Arr.mapWithIndex(Typeface.all, (typeface, i)=>{
      const lastRow2Class = (Math.ceil((i+1) / 2) === numRows2) ? 'fontsPage--block--lastRow2' : '';
      const lastRow3Class = (Math.ceil((i+1) / 3) === numRows3) ? 'fontsPage--block--lastRow3' : '';
      
      return (
        <Link 
          to={typeface.path} 
          key={`t-${typeface.id}`}
          className={`fontsPage--block fontsPage--${typeface.slug} ${lastRow2Class} ${lastRow3Class}`}>
            <div
              className='fontsPage--sampleWrap'>
              {typeface.homeBlockImg ? 
                <div className='fontsPage--blockImgWrap'>
                  <img 
                    className={`fontsPage--blockImg ${typeface.homeBlockImg.monochrome ? 'monochromeImg' : ''}`}
                    src={`${AppVars.assetsUrl}${typeface.homeBlockImg.url}`}
                    alt={typeface.name} />
                </div>
              : 
                <div 
                  className='fontsPage--blockSampleText'
                  style={{
                    fontFamily: typeface.fontFamily
                  }}>
                  Rga
                </div>
              }
            </div>
            <div 
              to={typeface.path} 
              className='fontsPage--blockInfo'>
              <div 
                className='fontsPage--blockTitle'>
                {typeface.name}
              </div>
            </div>
            {typeface.comingSoon ? 
              <ComingSoonBadge />
            : null}
            {typeface.badge ?
              <TypefaceBadge 
                typeface={typeface} />
            : null}
        </Link>
      )
    })
  }
  const renderFillerBlocks = () => {
    const numBlocks = 3 - (Typeface.all.length % 3);
    return Arr.mapTimes(numBlocks, (i) => {
      return (
        <div 
          key={`fbl-${i}`}
          className={`fontsPage--block fontsPage--fillerBlock fontsPage--fillerBlock${i+1}`}>
        </div>
      )
    })
  }
  return (
    <MainLayout 
      expandedHeader={true}
      pageName='grid'
      showHomeThemeNav={true}>
      <div className='fontsPage'>
        {renderTypefaces()}
        {renderFillerBlocks()}
      </div>
    </MainLayout>
  )
}
