import React, {useState, useContext} from 'react'
import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api'
import Scroll from '../../Utils/Scroll'
import { UserContext } from '../../Contexts/UserContext'
import ErrorMessage from '../Forms/ErrorMessage'
import {validatePresence} from '../../Utils/Validations'
import Countries from '../../Data/Countries'
import SelectInputPlus from '../Forms/SelectInputPlus'
import Vat from '../../Data/Vat'

export default function CardFormFields(props) {
  const stripe = useStripe();
  const user = useContext(UserContext);
  const elements = useElements();

  const [formData, setFormData] = useState({
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    stripe_payment_method_id: null
  })
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleFieldChange = (attribute, value) => {
    setFormData({
      ...formData,
      [attribute]: value
    })
  }
  const handleError = (e, m) => {
    if (e && e.errors) {
      setErrors(e.errors);
    } else if (m) {
      setErrors({
        form: m
      })
    } else {
      setErrors({form: 'Sorry, something went wrong. Please try again.'});
    }
    Scroll.to('form');
  }
  const handleGenericError = () => {
    setSubmitting(false);
    handleError({errors: {form: 'Sorry, something went wrong. Please try again.'}});
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const validations = validatePresence(['name', 'address_1', 'city', 'country'], formData);
    if (validations.valid) {
      setSubmitting(true);
      setupStripeIntent();
    } else {
      handleError(validations);
    }
  }
  const setupStripeIntent = () => {
    Api.get.paymentMethodsSetupIntent({
      success: (r)=>{
        if (r.intent) {
          createStripePaymentMethod(r.intent);
        } else {
          handleGenericError();
        }
      },
      error: ()=>{
        handleGenericError();
      }
    })
  }
  const createStripePaymentMethod = (intent) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    stripe.confirmCardSetup(
        intent.client_secret,
      {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            address: {
              city: formData.city,
              country: formData.country,
              line1: formData.address_1,
              line2: formData.address_2,
              postal_code: formData.zip,
              state: formData.state,
            },
            email: user.attributes.email,
            name: formData.name
          }
        }
      }
    ).then((r) => {
      if (r.setupIntent){
        const newFormData = {
          ...formData,
          stripe_payment_method_id: r.setupIntent.payment_method
        }
        setFormData(newFormData)

        // need to pass it manually otherwise stripe payment method id won't be updated yet with useState
        createVectroPaymentMethod(newFormData);

      } else if (r.error) {
        setSubmitting(false)
        handleError({errors: {form: r.error.message}})
        
      } else {
        handleGenericError();
        setSubmitting(false)
      }
    });
  }
  const createVectroPaymentMethod = (paymentMethodData) => {
    Api.post.paymentMethod({
      body: {
        ...paymentMethodData
      },
      success: (r)=>{
        setSubmitting(false);
        if (r.status === 200) {
          handleCreatePaymentMethodSuccess(r);
        } else {
          handleGenericError();
        }
      },
      error: (m,r)=>{
        setSubmitting(false);
        handleError(r, m);
      }
    })
  }
  const handleCreatePaymentMethodSuccess = (r) => {
    props.onSuccess && props.onSuccess();
  }

  const stripeFieldOptions = {
    style: {
      base: {
        fontSize: '17px',
        fontFamily: '"VCTR Mono Web", "Helvetica Neue", "Helvetica", sans-serif',
        color: "#111111"
      }
    }
  };

  const shouldRenderVat = Vat.shouldCollect(formData.country);

  if (stripe) {
    return (
      <div className='cardForm--fields'>
        <form 
            name='cardForm'
            onSubmit={handleSubmit}>

          <ErrorMessage 
            name='form'
            errors={errors} />
          
          <div className='cardForm--billingFields form--inputGroup'>
            <TextInput
              name='name'
              label='Name on Card'
              defaultValue=''
              errors={errors}
              onChange={(v)=>{handleFieldChange('name', v)}} />

            <div className='cardForm--cardNumbersRow'>
              <div className='formInput stripeTextInput'>
                <label>Card Number</label>
                <CardNumberElement 
                  options={stripeFieldOptions} />
              </div>
    
              <div className='formInput stripeTextInput'>
                <label>Expires</label>
                <CardExpiryElement 
                  options={stripeFieldOptions} />
              </div>
              <div className='formInput stripeTextInput'>
                <label>CVC</label>
                <CardCvcElement options={stripeFieldOptions} />
              </div>
            </div>
          </div>
          <div className='cardForm--addressFields form--inputGroup form--inputGroup--last'>
            <h3>Billing Address</h3>
            <TextInput 
              key='address_1'
              name='address_1'
              label='Address 1'
              defaultValue={formData.address_1}
              errors={errors}
              onChange={(v)=>{handleFieldChange('address_1', v)}} />
            <TextInput 
              key='address_2'
              name='address_2'
              label='Address 2'
              defaultValue={formData.address_2}
              errors={errors}
              onChange={(v)=>{handleFieldChange('address_2', v)}} />
            <div className='form--splitInputRow'>
              <TextInput 
                key='city'
                name='city'
                label='City'
                defaultValue={formData.city}
                errors={errors}
                onChange={(v)=>{handleFieldChange('city', v)}} />
              <TextInput 
                key='state'
                name='state'
                label='State/Province'
                defaultValue={formData.state}
                errors={errors}
                onChange={(v)=>{handleFieldChange('state', v)}} />
            </div>
            <div className='form--splitInputRow'>
              <TextInput 
                key='zip'
                name='zip'
                label='Zip/Postal Code'
                defaultValue={formData.zip}
                errors={errors}
                onChange={(v)=>{handleFieldChange('zip', v)}} />
              <SelectInputPlus
                label='Country'
                name='country'
                options={Countries.all}
                valueKey='code'
                labelKey='name'
                searchKey='name'
                errors={errors}
                value={formData.country}
                onChange={(v)=>{handleFieldChange('country', v)}} />
              {shouldRenderVat ? 
                <TextInput 
                  key='vat'
                  name='vat'
                  label='VAT'
                  defaultValue={formData.vat}
                  errors={errors}
                  onChange={(v)=>{handleFieldChange('vat', v)}} />
                : null}
            </div>
          </div>
          <div className='form--actions'>
            <button 
              type='submit'
              className='btn'
              disabled={submitting}
              htmlFor='cardForm'>
                ADD
            </button>
          </div>
        </form>
      </div>
    )
  } else {
    return null;
  }
}