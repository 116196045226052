import React, {useContext} from 'react'
import CheckoutCurrentPaymentMethod from './CheckoutCurrentPaymentMethod'
import CheckoutNewPaymentMethod from './CheckoutNewPaymentMethod'
import Scroll from '../../Utils/Scroll'
import { CartContext } from '../../Contexts/CartContext'

export default function CheckoutPayment(props) {
  const cart = useContext(CartContext)
  const handleChangePaymentMethod = () => {
    cart.clearPaymentMethod();
    props.onClearPaymentMethod();
    Scroll.to('.checkout--payment');
  }
  const handleNewPaymentMethodSuccess = () => {
    cart.getPaymentMethod(()=>{
      setTimeout(()=>{
        props.onMoveToStep(props.step + 1);
      }, 200)
    });
  }
  return (
    <div className='checkout--payment'>
      {cart.paymentMethod ? 
        <CheckoutCurrentPaymentMethod
          paymentMethod={cart.paymentMethod}
          onChangePaymentMethodClick={handleChangePaymentMethod} />
      : 
        <CheckoutNewPaymentMethod
          onSuccess={handleNewPaymentMethodSuccess} />
      }
    </div>
  )
  
}
