import React, {useContext} from 'react'
import {UserContext} from '../../Contexts/UserContext'
import {
  Switch,
  Route
} from 'react-router-dom'
import UnauthorizedPage from './UnauthorizedPage'
import Routes from './Routes'



export default function CurrentRoute() {
  const user = useContext(UserContext)
  const routes = (user.ready) ? Routes.allWithPermissions(user.roles) : [];

  const renderRoutes = () => {
    return routes.map((route) => {
      return (
        <Route 
          key={route.name}
          path={route.path} 
          render={(routeProps)=>{
            const Cmp = route.canAccess ? route.component : UnauthorizedPage;
            return (
              <Cmp
                {...routeProps}
                {...route.extraProps}
                routeName={route.name} />
            );
          }}
        />
      );
    })  
  }

  if (user.ready) {
    return (
      <Switch>
        {renderRoutes()}
      </Switch>  
    )
  } else {
    return (
      <div className='appLoading'>
        loading
      </div>
    )
  }
  
}
