import React from 'react'
import ErrorMessage from './ErrorMessage'

export default function RangeInput(props) {
  const fieldClassName = `formInput--${props.name}`;
  const hasError = props.errors && props.errors[props.name];
  const errorClass = hasError ? 'formInput--hasError' : '';

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(parseFloat(e.target.value));
    }
  }
  return (
    <div className={`formInput rangeInput ${props.className} ${fieldClassName} ${errorClass}`}>
      {props.label ? 
        <label htmlFor={props.name}>
            {props.label} 
        </label>
      : null }
      {props.renderLabel ? 
        props.renderLabel()
      : null}
      <div className='rangeInput--inputWrapper'>
        {props.renderBeforeInput && props.renderBeforeInput()}
        <input 
          type="range" 
          name="lineHeight"
          min={props.min}
          max={props.max}
          value={props.value}
          step={props.step}
          onChange={handleChange} />
        {props.renderAfterInput && props.renderAfterInput()}
      </div>
      <ErrorMessage 
        name={props.name}
        errors={props.errors} />
    </div>
  )
}

RangeInput.defaultProps = {
  step: 'any',
  className: ''
}
