import React, {useState, useContext} from 'react'
import { UIContext } from '../../Contexts/UIContext'
import Arr from '../../Utils/Arr'
import AppVars from '../../Utils/AppVars'

export default function Slideshow(props) {
  const ui = useContext(UIContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const numSlides = props.slides.length;
  const currentSlide = props.slides[currentIndex];

  const handleNavPrev = () => {
    if (currentIndex <= 0 ) {
      setCurrentIndex(numSlides -1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }
  const handleNavNext = () => {
    if (currentIndex >= numSlides -1 ) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }
  const renderIndicators = () => {
    return Arr.mapWithIndex(props.slides, (slide, i)=>{
      const activeClass = currentIndex === i ? 'slideshow--indicator--active' : '';
      return (
        <button
          className={`btn slideshow--indicator ${activeClass}`}
          key={`slide-${i}`}
          onClick={()=>{setCurrentIndex(i)}}>
          {' '}
        </button>
      )
    });
  }
  let monochromeClass = currentSlide.monochrome ? 'monochromeImg' : '';
  let url = currentSlide.url;
  
  if (currentSlide.altDefaultThemeUrl && (ui.colorThemeIndex === 0)) {
    url = currentSlide.altDefaultThemeUrl;
    monochromeClass = currentSlide.altDefaultThemeMonochrome ? 'monochromeImg' : '';
  }
 
  return (
    <div className='slideshow'>
      <div className='slideshow--slide'>
        <img 
          src={`${AppVars.assetsUrl}${url}`}
          alt={currentSlide.caption}
          className={monochromeClass} />
        
        {numSlides > 1 ? 
          <div>
              <button
              className='btn slideshow--navBtns slideshow--prev'
              onClick={handleNavPrev}>
              <span>←</span>
            </button>
            <button
              className='btn slideshow--navBtns slideshow--next'
              onClick={handleNavNext}>
              <span>→</span>
            </button>
          </div>
        : null}
      </div>
      {numSlides > 1 ? 
        <div className='slideshow--indicators'>
          {renderIndicators()}
        </div>
      : null}
    </div>
  )
}
