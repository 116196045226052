import React from 'react'
import MainLayout from './MainLayout'
import BackendNav from './BackendNav'

export default function BackendLayout(props) {
  return (
    <MainLayout 
      {...props}
      layoutClassName={`backendLayout backendLayout--${props.mode}`}>
      <div className='backendLayout--inner'>
        <BackendNav
          mode={props.mode} />
        <div className='backendLayout--content'>
          {props.children}
        </div>
      </div>
    </MainLayout>
  )
}
