import React, {useState, useContext, useEffect, useRef, useCallback} from 'react'
import MainLayout from '../Layouts/MainLayout'
import Cart from '../Cart/Cart'
import CheckoutSection from './CheckoutSection'
import CheckoutAccount from './CheckoutAccount'
import CheckoutPayment from './CheckoutPayment'
import { UserContext } from '../../Contexts/UserContext'
import Scroll from '../../Utils/Scroll'
import CheckoutLicensee from './CheckoutLicensee'
import { CartContext } from '../../Contexts/CartContext'
import CheckoutSubmit from './CheckoutSubmit'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function CheckoutPage() {
  useTrackPageHit('Checkout');
  const cart = useContext(CartContext);
  const user = useContext(UserContext);
  const [initialStepSet, setInitialStepSet] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [agreedToEula, setAgreedToEula] = useState(false);

  const initialLoad = useRef(false);
  useEffect(() => {
    if (!initialLoad.current) {
      cart.getPaymentMethod();
      initialLoad.current = true;
    } 
  });
  const getInitialActiveStep = useCallback(() => {
    if (cart.paymentMethod) {
      return 3;
    } else if (user.signedIn) {
      return 2;
    } else {
      return 1;
    }
  },[cart.paymentMethod, user.signedIn]);
  useEffect(() => {
    if (cart.paymentMethodLoaded && !initialStepSet) {
      setInitialStepSet(true);
      setActiveStep(getInitialActiveStep());
    }
  }, [initialStepSet, cart.paymentMethodLoaded, user.signedIn, getInitialActiveStep]);

  const handleMoveToStep = (step) => {
    setActiveStep(step);
    Scroll.to(`.checkout--section${step}`);
  }
  const handleChangeAgreedToEula = (v) => {
    setAgreedToEula(v);
  }
  const handleClearPaymentMethod = () => {
    setActiveStep(2);
  }
  const ready = cart.paymentMethodLoaded;
  return (
    <MainLayout
      pageName='checkout'>
      <div className='checkout'>
        <h1>Checkout</h1>
        <div className='checkout--content'>
          {ready ? 
            <div className='checkout--main'>
              <div className='checkout--steps'>
                <CheckoutSection
                  key='account'
                  step={1}
                  activeStep={activeStep}
                  title='Account'>
                  <CheckoutAccount />
                </CheckoutSection>
                <CheckoutSection
                  key='credit-card'
                  step={2}
                  activeStep={activeStep}
                  title='Credit Card'>
                  <CheckoutPayment
                    onMoveToStep={handleMoveToStep}
                    onClearPaymentMethod={handleClearPaymentMethod} />
                </CheckoutSection>
                <CheckoutSection
                  key='licensee'
                  step={3}
                  activeStep={activeStep}
                  title='Licensee'>
                  <CheckoutLicensee
                    agreedToEula={agreedToEula}
                    onChangeAgreedToEula={handleChangeAgreedToEula} />
                </CheckoutSection>
              </div>
              <CheckoutSubmit
                agreedToEula={agreedToEula} />
            </div>
          : null }
          <div className='checkout--cart'>
            <Cart />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
