import React from 'react'
import Markdown from '../Markdown/Markdown'
import { Link } from 'react-router-dom'
import DateHelper from '../../Utils/DateHelper'
import Url from '../../Utils/Url'

export default function BlogThumbnail(props) {
  return (
    <div className='blogIndex--thumbnail'>
      <div className='blogIndex--thumbnail--meta'>
        {DateHelper.slashToAbbreviated(props.post.date)}
      </div>
      <div className='blogIndex--thumbnail--main'>
        <h2>
          <Link 
            to={props.post.path}>
            {props.post.title}
          </Link>
        </h2>
        {props.post.coverImgPath ? 
          <Link 
            to={props.post.path}>
            <img 
              src={Url.assetUrlFallback(props.post.coverImgPath)}
              className='blogIndex--thumbnail--coverImg' />
          </Link>
        : null}
          
        <Markdown 
          className='blogIndex--thumbnail--content'
          linkTarget={null}>
          {`${props.post.thumbnailContent} [Read More](${props.post.path})`}
        </Markdown>
      </div>
    </div>
  )
}
