import React, {useState, useEffect} from 'react'
import Arr from '../../Utils/Arr'


export default function ThreeDScreensaver() {
  const [animating, setAnimating] = useState(false);
  const renderText = () => {
    return Arr.mapTimes(30, (i)=>{
      return (
        <div 
          key={`3d-${i}`}
          className='threeDScreensaver--shadowTextLayer'>
          {TEXT_CONTENT}
        </div>
      );
    })
  }
  useEffect(() => {
    // Update the document title using the browser API
    setAnimating(true);
  }, [setAnimating]);
  const animatingClass = animating ? 'threeDScreensaver--animating' : '';
  return (
    <div className={`threeDScreensaver ${animatingClass}`}>
      <div className='threeDScreenSaver--container'>
        <div className='threeDScreensaver--background'></div>

        <div className='threeDScreensaver--shadow'>
          {renderText()}
        </div>
        <div className='threeDScreensaver--top'>
          <div className='threeDScreensaver--topText'>
            {TEXT_CONTENT}
          </div>
        </div>
      </div>
    </div>
  )
}

const TEXT_CONTENT = "VECTRO";
