import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import HomeScroller from './HomeScroller'
import HomeCards from './HomeCards'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function HomePage() {
  useTrackPageHit('Home');
  return (
    <MainLayout 
      expandedHeader={true}
      pageName='home'
      showHomeThemeNav={true}>
      <HomeScroller
        key='scroller' />
      <HomeCards
        key='cards' />
    </MainLayout>
  )
}