const main = {
  fields: [
    'payment_method_id',
    'licensee',
    'fonts_for'
  ],
  children: [
    {
      name: 'order_items',
      schemaName: 'order_item'
    }
  ]
}
export default main;