import Cookies from './Cookies';

export default class Auth {
  static getCSRFToken() {
    const metaInfo = Array.prototype.slice.call(document.querySelectorAll('meta'));
    const token = metaInfo.find((x)=>{
      return x.name === 'csrf-token';
    })
    return token ? token.content : null;
  }
  static getCookie(c_name) {
    return Cookies.get(c_name);
  }
  static getSessionCookie() {
    this.getCookie('vectro_key')
  }
}