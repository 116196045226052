function set(key, value, expireDays, expireHours, expireMinutes, expireSeconds) {
  var expireDate = new Date();
  if (expireDays) {
    expireDate.setDate(expireDate.getDate() + expireDays);
  }
  if (expireHours) {
    expireDate.setHours(expireDate.getHours() + expireHours);
  }
  if (expireMinutes) {
    expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
  }
  if (expireSeconds) {
    expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
  }
  document.cookie = key +"="+ escape(value) +
  ";domain="+ window.location.hostname +
  ";path=/"+
  ";SameSite=Strict"+
  ";expires="+expireDate.toUTCString();
}
function get(key) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(key + "=");
    if (c_start !== -1) {
      c_start = c_start + key.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}
function clear(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

const opts = {
  clear,
  get,
  set
}

export default opts