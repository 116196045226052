import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import Markdown from '../Markdown/Markdown'
import LicensingFAQ from '../../Data/LicensingTerms/LicensingFAQ.json'
import LicensingPageOverview from './LicensingPageOverview'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'

export default function LicensingPage() {
  useTrackPageHit('Licensing');
  
  const renderFAQQuestions = (section) => {
    return section.questions.map((question, i) => {
      return (
        <details 
          key={`q-${i}`}
          className='licensingPage--faqQuestion'>
          <summary>{question.question}</summary>
          <Markdown>
            {question.answer}
          </Markdown>
        </details>
      )
    });
  }
  const renderFAQ = () => {
    return LicensingFAQ.sections.map((section, i)=> {
      return (
        <div 
          key={`s-${i}`}
          className='licensingPage--faqSection'>
          {renderFAQQuestions(section)}
        </div>
      )
    })
  }
  return (
    <MainLayout
      pageName='licensingPage'>
      <div className='licensingPage'>
        <div className='licensingPage--main'>
          <h1>Licensing</h1>
          <div className='licensingPage--overview'>

            <LicensingPageOverview />
            
          </div>
          <div className='licensingPage--faq'>
            <h2>FAQ</h2>
            {renderFAQ()}
          </div>
          
        </div>
      </div>
    </MainLayout>
  )
}




// {
//   "question": "",
//   "answer": ""
// }