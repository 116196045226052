import Get from './Api/Get';
import Post from './Api/Post';
import Put from './Api/Put';
import Delete from './Api/Delete';

const opts = {
  get: Get,
  post: Post,
  put: Put,
  delete: Delete
}

export default opts