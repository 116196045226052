import {React, useState} from 'react'
import NarrowLayout from '../Layouts/NarrowLayout'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api'
import Url from '../../Utils/Url'
import AppVars from '../../Utils/AppVars'
import ErrorMessage from '../Forms/ErrorMessage'

export default function ResetPasswordCompletePage() {
  const [formData, setFormData] = useState({
    new_password: null,
    new_password_confirmation: null
  })
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (a,v) => {
    setFormData({
      ...formData,
      [a]: v
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    Api.put.resetPassword({
      id: Url.getUrlParams().t,
      body: formData,
      success: (r)=>{
        if (r.status === 200) {
          window.location = AppVars.rootUrl;
        } else {
          setErrors(r.errors);
          setSubmitting(false);
        }
      },
      error: (m, r)=>{
        setErrors({form: [m]});
        setSubmitting(false);
      }
    })
  }
  return (
    <NarrowLayout
      pagename='Reset Password Complete'>
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <ErrorMessage 
          name='form'
          errors={errors} />
        <TextInput
          name='new_password'
          type='password'
          label='New Password'
          onChange={(v)=>{handleChange('new_password', v)}}
          errors={errors} />
        <TextInput
          key='new_password_confirmation'
          name='new_password_confirmation'
          type='password'
          label='Confirm New Password'
          onChange={(v)=>{handleChange('new_password_confirmation', v)}}
          errors={errors} />
        <button 
          className='btn btn--fullWidth'
          disabled={submitting}>
          Submit
        </button>
      </form>
    </NarrowLayout>
  )
}
