import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import BlogPost from '../../Data/BlogPost'
import BlogThumbnail from './BlogThumbnail'
import { useTrackPageHit } from '../../Hooks/UseTrackPageHit'
import MetaData from '../../Data/MetaData'
import AppVars from '../../Utils/AppVars'

export default function BlogIndexPage() {
  useTrackPageHit('Blog');
  
  const renderBlogPosts = () => {
    const posts = AppVars.blogShowDraft ? BlogPost.all : BlogPost.allWithStatus('live');
    return posts.map((post)=> {
      return (
        <BlogThumbnail
          key={`blog-${post.id}`}
          post={post} />
      );
    })
  }
  
  return (
    <MainLayout 
      meta={MetaData.findByName('blog')}
      pageName='blog'>
      <div className='blogIndexPage'>
        <div className='blogIndex--posts'>
          {renderBlogPosts()}
        </div>
      </div>
    </MainLayout>
  )
}