import Url from '../../Utils/Url'
import AppVars from '../../Utils/AppVars'
import ReactMarkdown from 'react-markdown'

const assetUrlFallback = (inUrl) => {
  const imgProtocol = Url.getProtocol(inUrl)
  const url = imgProtocol ? inUrl : `${AppVars.assetsUrl}${inUrl}`;
  return url;
}

const videoRenderer = (src, props) => {
  const poster = getPosterUrl(src);
  const posterParam = poster ? {poster: poster} : {};
  return (
    <figure>
      <video 
        {...posterParam}
        width="320" 
        height="240" 
        controls>
        <source src={src}
          type="video/mp4" />
      </video>
      {props.alt ? 
        <figcaption>{props.alt}</figcaption>
      : null }
    </figure>
  );
}
const getPosterUrl = (src) => {
  const urlParams = Url.parseParams(src);
  if (urlParams) {
    return assetUrlFallback(urlParams.poster);
  }
}
const imageRenderer = (imgSrc, props) => {
  return (
    <figure>
      <a 
        href={imgSrc}
        target='_blank'
        rel="noreferrer" >
        <img
          src={imgSrc}
          alt={props.alt}
          title={props.alt} />
      </a>
      {props.title || props.alt ? 
        <figcaption>
          <ReactMarkdown
            linkTarget="_blank">
            {props.title || props.alt}
          </ReactMarkdown>
        </figcaption>
      : null }
    </figure>
  );
}
const mediaRenderer = (props) => {
  const imgSrc = assetUrlFallback(props.src)
  const fileType = imgSrc.split('?')[0].split('.').pop().toLowerCase();

  if (fileType === 'mp4') {
    return videoRenderer(imgSrc, props);
  } else {
    return imageRenderer(imgSrc, props);
  }
}
const paragraphRenderer = (props) => {
  const numImages = props.children.filter((child)=> {
    return child.type && ((child.type.name === "mediaRenderer") || (child.type.name === "img"));
  }).length;
  if (numImages > 0) {
    const multipleImgClass = numImages > 1 ? 'md--multipleImages' : '';
    const columnCount = numImages > 4 ? 4 : numImages;
    return (
      <div 
        className={`md--image--section ${multipleImgClass} md--images--${columnCount}col`} 
        {...props} />
    )
  } else {
    return (
      <p {...props} />
    )
  }
}

const markdownRenderers = {
  "p": paragraphRenderer,
  "img": mediaRenderer
}
export default markdownRenderers;
