import React, {useContext} from 'react'
import { UIContext } from '../../Contexts/UIContext'
import {ReactComponent as ColorburstIcon} from '../../Svg/Colorburst-Icon-Gradient.svg'
import {ReactComponent as RightTriangleIcon} from '../../Svg/Right-Triangle-Icon.svg'

export default function ThemeNav(props) {
  const ui = useContext(UIContext);

  const collapsedClass = ui.themeNavCollapsed ? 'themeNav--collapsed' : '';
  const homeActiveClass = props.showHomeThemeNav ? 'themeNav--homeActive' : '';
  const hiddenClass = ui.themeNavHidden ? 'themeNav--hidden' : '';

  return (
    <div className={`themeNav ${collapsedClass} ${homeActiveClass} ${hiddenClass}`}>
      <button 
        className={`btn--noStyle themeNav--toggleBtn`}
        onClick={ui.onThemeNavToggle}>
          <RightTriangleIcon
            className='themeNav--toggleBtnIcon' />
      </button>
      
      <button 
        className='themeNav--colorsBtn btn--noStyle'
        onClick={ui.onChangeColorTheme}>
        <ColorburstIcon />
      </button>
    </div>
  )
}


// {props.showHomeThemeNav ? 
//   <div className='themeNav--home'>
//     <Link 
//       to='/'
//       className={flashyActive() ? "active" : ''}>
//         <PentagonIcon />
//     </Link>
//     <NavLink 
//       to='/tester'>
//       <TesterIcon />
//     </NavLink>
//     <NavLink 
//       to='/fonts'>
//       <GridIcon />
//     </NavLink>
//   </div>
// : null }