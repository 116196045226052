import Api from './ApiCore'
import Auth from '../Auth'

export default class Post {
  static postIt (opts){
    opts.method = 'POST'
    Api.makeRequest(opts);
  }
  static session(opts){
    opts.url = 'sessions';
    opts.body = {
      ...opts.body,
      authenticity_token: Auth.getCSRFToken()
    }
    opts.credentials = 'same-origin';
    this.postIt(opts);
  }
  static user(opts){
    opts.url = 'users';
    opts.body = {
      user: opts.body,
      authenticity_token: Auth.getCSRFToken()
    }
    opts.credentials = 'same-origin';
    this.postIt(opts);
  }
  static paymentMethod(opts){
    opts.url = 'payment_methods';
    opts.body = {
      payment_method: opts.body
    }
    this.postIt(opts);
  }
  static order(opts){
    opts.url = 'orders';
    opts.body = {
      order: opts.body
    }
    this.postIt(opts);
  }
  static orderConfirmPayment(opts){
    opts.url = 'orders/confirm_payment';
    opts.body = {
      order: opts.body
    }
    this.postIt(opts);
  }
  static resetPassword(opts){
    opts.url = 'reset_password';
    opts.body = {
      user: opts.body
    }
    this.postIt(opts);
  }
  static subscribeToNewsletter(opts){
    opts.url = 'settings/subscribe_to_newsletter';
    this.postIt(opts);
  }
}
