import React, {useContext} from 'react'
import MainLayout from '../Layouts/MainLayout'
import { Link } from 'react-router-dom'
import { UserContext } from '../../Contexts/UserContext'

export default function UnauthorizedPage() {
  const user = useContext(UserContext);
  const successRedirectURL = window.location.href;
  return (
    <MainLayout 
      name='unauthorized'>
      <div className='unauthorizedPage'>
        <div className='unauthorizedPage--message'>
          Sorry, you aren’t allowed to be here. 
          {!user.signedIn ? 
            <div>
              You may need to <Link to={`/sign-in?r=${successRedirectURL}`}>sign in</Link> before viewing this page.
            </div>
          : null}
        </div>
      </div>
    </MainLayout>
  )
}
