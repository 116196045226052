import BlogPostsMeta from './BlogPostsMeta.json'
import KablammoProcess1 from './BlogPosts/KablammoProcess1.md'
import HowWeGotHere from './BlogPosts/HowWeGotHere.md'
import ChartwellWhatsNew from './BlogPosts/03-chartwell-whats-new.md'
import ChartwellProcessRepost from './BlogPosts/04ChartwellProcessRepost.md'
import AppVars from '../Utils/AppVars'

const findMeta = (id) => {
  return BlogPostsMeta.find((entry)=> {
    return entry.id === id;
  })
}
const allRaw = [
  {
    ...findMeta(4),
    getContent: (callback)=> {return getContent(ChartwellProcessRepost, callback)},
  },
  {
    ...findMeta(3),
    getContent: (callback)=> {return getContent(ChartwellWhatsNew, callback)},
  },
  {
    ...findMeta(2),
    getContent: (callback)=> {return getContent(KablammoProcess1, callback)},
  },
  {
    ...findMeta(1),
    getContent: (callback)=> {return getContent(HowWeGotHere, callback)},
  }
].sort((a,b) => {
  return b.id - a.id;
}).filter((post) => {
  return AppVars.blogShowDraft || post.status != 'draft';
})

const all = allRaw.map((post)=> {
  return {
    ...post,
    path: `/blog/${post.slug}`,
    url: `${AppVars.rootUrl}/blog/${post.slug}`
  }
})

const allWithStatus = (status) => {
  const statuses = status.split(',');
  return all.filter((post) => {
    return statuses.includes(post.status);
  });
}

const find = (id) => {
  return all.find((post)=> {
    return post.id === id
  })
}

const findBySlug = (slug) => {
  return all.find((post)=> {
    return post.slug === slug
  })
}
const getContent = (file, callback)=>{
  fetch(file)
  .then((res) => res.text())
  .then((md) => {
    callback(md);
  })
}
const main = {
  all,
  allWithStatus,
  findBySlug,
  find
}

export default main;
