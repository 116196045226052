import React from 'react'
import Table from '../Tables/Table'

export default function DesignerAdminTypefaceReportsList(props) {
  const combinedReports = [{
    current: true,
    ...props.currentTypefaceReport
    
  }].concat(props.typefaceReports);
  const formattedData = combinedReports.map((typefaceReport)=>{
    return {
      ...typefaceReport,
      rowTitle: () => {
        if (typefaceReport.current) {
          return 'Last 30 Days'
        } else {
          return typefaceReport.month_name
        }
      }
    }
  })
  
  return (
    <Table
      className='designerAdminTypefaceSales--typefaceReportsList'
      showHeader={true}
      cols={COLUMNS}
      data={formattedData}
      keyCol='id' />
  )
}

const COLUMNS = [
  {
    name: 'rowTitle',
    displayName: 'Date',
    isRich: true
  },
  {
    name: 'order_items_gross_total',
    displayName: 'Gross Sales',
    format: '$0,0.00'
  },
  {
    name: 'royalty_total',
    displayName: 'Total Royalties',
    format: '$0,0.00'
  }
]