import React, { useState, useEffect } from 'react'
import Api from '../Utils/Api'
import Url from '../Utils/Url'

export const defaultUserValue = null;

export const UserContext = React.createContext(
  defaultUserValue
);

const redirectToParamOrFallback = (fallbackUrl) => {
  if (Url.getUrlParams().r) {
    window.location = Url.getUrlParams().r
  } else {
    window.location = fallbackUrl
  }
}

export function UserContextProvider(props) {
  const [attributes, setAttributes] = useState(defaultUserValue);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);

  const getCurrentUser = () => {
    Api.get.currentUser({
      success: (res)=>{
        setAttributes(res.user)
        setLoading(false);
        setReady(true);
      },
      error: (res)=>{
        setLoading(false);
        setReady(true);
        // console.log('error', res);
      }
    })
  }
  const formatErrors = (r, m)=> {
    if (r && r.errors) {
      return r.errors;
    } else if (m) {
      return {
        form: [m]
      }
    } else {
      return {
        form: ['Something went wrong. Please try again.']
      }
    }
  }
  const handleSignIn = (values, opts) => {
    Api.post.session({
      body: values,
      success: (r) => {
        if (r.status === 200) {
          setAttributes(r.user);

          if (opts.onSuccess) {
            opts.onSuccess(r.user);
          } else if (opts.redirectOnSuccess) {
            window.location = opts.redirectOnSuccess;
          } else {
            redirectToParamOrFallback('/');
          }
        } else {
          opts.onError && opts.onError(formatErrors(r));
        }
      },
      error: (m, r) => {
        opts.onError && opts.onError(formatErrors(r, m));
      }
    })
  }
  const handleSignOut = () => {
    if (attributes) {
      Api.delete.session({
        success: (r) => {
          setAttributes(null)
          redirectToParamOrFallback('/sign-in');
        },
        error: (r) => {
          // console.log('error', r)
        }
      })
    }
  }
  const handleMergeAttributes = (newAttributes) => {
    setAttributes({
      ...attributes,
      ...newAttributes
    })
  }
  const signedIn = !!attributes;
  const roles = signedIn ? attributes.roles : [];

  useEffect(() => {
    getCurrentUser()
  }, []);

  return (
    <UserContext.Provider value={{
      attributes,
      signedIn,
      onSignIn: handleSignIn,
      onSignOut: handleSignOut,
      onMergeAttributes: handleMergeAttributes,
      ready: ready,
      roles: roles
    }}>
      {loading ? 
        null
      : props.children }
    </UserContext.Provider>
  )
}