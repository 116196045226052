import React from 'react'
import AppVars from '../../Utils/AppVars'

export default function LicensingPageOverview() {
  return (
    <div className='licensingPage--overview'>
      <div className='licensingPage--overview--intro'>
        <p>Our pricing and licenses are determined by the size of your organization. Rather than purchasing separate licenses for the web, desktop, or apps, you can purchase a single license for your organization, and use it with relatively few limitations on usage. We believe this offers fairer pricing, and more flexible and clearer usage terms.
        </p>
        <a 
          href={AppVars.eulaUrl} 
          className='btn btn--large btn--fullWidth btn--outline licensingPage--eulaBtn'
          target='_blank'
          rel='noreferrer'>Download End User License Agreement(EULA)</a>
      </div>
      <div className='licensingPage--overview--licenses'>
        <h2 className='licensingPage--overview--licensesHeader'>Licenses We Offer</h2>
        <ul className='licensingPage--overview--licensesList'>
          <li>
              <a href='#commercial'>Commercial License</a>
          </li>
          <li>
            <a href='#personal'>Personal License</a>
          </li>
          <li>
            <a href='#trial'>Trial License</a>
          </li>
          <li>
            <a href='#custom'>Custom License</a>
          </li>
        </ul>
        <div className='licensingPage--overview--licenseDetails'>

          <h3 id='commercial'>Commercial License</h3>
            <p>
              A commercial license is required if the fonts will be used by a business or organization, even if the project or usage has no direct financial value. An individual must purchase a commercial license if the usage has a financial incentive, even indirectly. The size of your license must adequately cover the number of employees within the company, even if they will not be installing, or using the fonts. 
            </p>
            <p>
              With a commercial license you may use the fonts on any number of computers or devices. You may use the fonts on the web, in apps, social media, or broadcast media without limits to number of viewers or users. There are a few uses which are not allowed with a commercial license. These are detailed in the section below entitled <a href="#special">Uses that require a special license</a>.
            </p>

            <h3 id='personal'>Personal License</h3>
            <p>
              A personal license is a discounted license that allows you  to use the fonts in non-commercial, or personal projects. You may use the fonts on social media, websites, documents, and other personal uses, as long as there is no financial motivation for the project—direct or indirect. Businesses and organizations are not permitted to purchase a personal license, and must purchase a commercial license.
            </p>

            <h3 id='trial'>Trial License</h3>
            <p>
              Our free trial fonts have a limited character set. You may use the fonts for evaluation and testing purposes, including for mock-ups and presentations to clients to showcase potential uses. Any other use requires a paid commercial or personal license.
            </p>
            <h3 id='custom'>Custom License</h3>
            <p>
              If you have a use that isn’t covered by our standard licenses, we are more than happy to work out a custom license to cover your needs. <a href='mailto:hello@vectrotype.com'>Reach out</a> to us with info about your project.
            </p>

        </div>
      </div>
      <div 
        id='special' 
        className='licensingPage--overview--special'>
        <h2>Uses That Require a Special License</h2>
        <p>
          Our licenses are meant to be fairly flexible, but there are a few uses that are not permitted with any of our standard licenses. If you would like to use the fonts in any of the below scenarios, please <a href='mailto:hello@vectrotype.com'>contact us</a>, and we can discuss a special license:
        </p>
        <ul className='asteriskList'>
          <li>
            <p><strong>Alphabet Products.</strong> You may not use the fonts in products or goods for sale, where the fonts are the primary design element, and the basis for the primary value of the product. This includes, but is not limited to house numbers, signage, stamp sets, rub-on letters, adhesive alphabet letters, alphabet punch and die sets, or other methods for use in making such products. This also can include t-shirts, posters, pins, and other merchandise unless it is part of a larger design context. If you’re unsure if your project falls in this category, <a href='mailto:hello@vectrotype.com'>contact us</a>.
            </p>
          </li>
          <li>
            <p>
              <strong>Redistribution.</strong> You shall not redistribute the fonts with other software packages or other means that make the raw fonts accessible to third-parties not covered by your license.
            </p>
          </li>
          <li>
            <p><strong>Apps with exportable content</strong>. You may not include the fonts in apps, software, or websites if the users can export documents, set in the fonts, which are meant to be used outside of the app.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}
