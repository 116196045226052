import React, {useState} from 'react'
import TextInput from '../Forms/TextInput'
import Api from '../../Utils/Api'
import ErrorMessage from '../Forms/ErrorMessage';

export default function NewsletterForm(props) {
  const [email, setEmail] = useState(null);
  const [ui, setUi] = useState({
    hasErrors: false,
    submitting: false,
    successful: false
  })
  const handleChange = (v) => {
    setEmail(v);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setUi({
      ...ui,
      submitting: true
    })
    Api.post.subscribeToNewsletter({
      body: {
        email: email
      },
      success: (r)=>{
        if (r.status === 200){
          setEmail('');
          setUi({
            ...ui,
            successful: true,
            hasError: false,
            submitting: false
          })
        } else {
          setUi({
            ...ui,
            hasError: true,
            submitting: false
          })
        }
      },
      error: (r)=>{
        setUi({
          ...ui,
          hasError: true,
          submitting: false
        })
      }
    });
  }
  const errors = ui.hasError ? {email: 'Something went wrong. Please try again.'} : {};
  return (
    <div className={`newsletterForm`}>
      <form onSubmit={handleSubmit}>
        <div className='form--inlineInputButton'>
          <TextInput
            name='email'
            placeholder='Your Email'
            label={props.label}
            onChange={handleChange} />
          <button
            type='submit'
            className='btn btn--inputSize'
            disabled={ui.submitting}>
            {props.buttonText}
          </button>
        </div>
        <ErrorMessage
          name='email'
          errors={errors} />
        {ui.successful ?
          <div className='newsletterForm--successful'>
            Thanks for signing up!
          </div>
        : null }
      </form>
    </div>
  )
}

NewsletterForm.defaultProps = {
  label: 'Newsletter:',
  buttonText: 'Subscribe'
}