import React from 'react'
import NarrowLayout from '../Layouts/NarrowLayout'
import SignUpForm from './SignUpForm'
import {useSignedInRedirect} from '../../Hooks/UseSignedInRedirect'

export default function SignupPage() {
  useSignedInRedirect('/');
  return (
    <NarrowLayout
      skipSignInRedirect={true}
      pagename='Sign Up'>
      <SignUpForm />
    </NarrowLayout>
  )
}
