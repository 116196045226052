import React from 'react'
import parse from 'html-react-parser'

export default function RadioButtonInputOption(props) {
  const isChecked = props.option.value === props.currentValue;
  const handleClick = () => {
    props.onChange(props.option);
  }
  const checkedClass = isChecked ? 'radioButton--option--checked' : '';
  return (
    <div
      key={`opt-${props.option.value}`}
      className={`radioButtons--option ${checkedClass}`}>
      <button 
        type='button'
        className='radioButtons--optionInput btn--normalLink'
        onClick={handleClick}>
        <span className='radioButton--optionIndicator'> </span>
      </button>
      <span 
        onClick={handleClick}
        className='radioButtons--optionLabel'>{parse(props.option.label)}</span>
      {props.option.hint ? 
        <div className='radioButtons--optionHint'>
          {props.option.hint}
        </div>
      : null}
    </div>
  )
}
