import React from 'react'
import NarrowLayout from '../Layouts/NarrowLayout'
import SignInUpForm from '../SignInUp/SignInUpForm'

export default function SignInPage() {
  return (
    <NarrowLayout
      skipSignInRedirect={true}
      pagename='Sign Up'>
      <SignInUpForm
        initialForm='signIn' />
    </NarrowLayout>
  )
}
