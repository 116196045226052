import Dom from "./Dom"
import {detect} from 'detect-browser';

export default class BrowserSniffer {
  static init() {
    this.identifyBrowser();
    this.identifyTouchDevice();
  }
  static identifyBrowser () {
    const browser = detect();
    if (browser) {
      const el = Dom.findFirst('html');
      if (el) {
        Dom.addClass(el, `browser-${browser.name}`);
      }
    }
  }
  static identifyTouchDevice () {
    const el = Dom.findFirst('html');
    if (el) {
      if (this.isTouchDevice()) {
        Dom.addClass(el, `isTouchDevice`);
      } else {
        Dom.addClass(el, `notTouchDevice`);
      }
    }
  }
  static isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }
}