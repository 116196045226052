import React from 'react'
import Numeral from 'numeral'

const TableRow = props => {
  const formatData = (col)=>{
    const val = props.data[col.name];
    if (col.format) {
      return Numeral(val).format(col.format);
    } else {
      return val
    }
  }
  const colContent = (col) => {
    const content = [
      col.prefix,
      formatData(col),
      col.suffix,
    ];
    if (col.wrapWith) {
      return React.createElement(
        col.wrapWith,
        null,
        content
      )
    } else {
      return content;
    }
  }
  const renderBasicCell = (col)=>{
    return  (
      <td 
        key={col.name}
        className={`td-${col.name}`}>
        {colContent(col)}
      </td>
    ) 
  }
  const renderLinkCell = (col)=>{
    return (
      <td 
        key={col.name}
        className={`td-${col.name}`}>
        <button 
          className='btn--normalLink'
          onClick={()=>{col.onClick(col, props.data)}}>
          {colContent(col)}
        </button>
      </td>
    );
  }
  const renderRichCell = (col)=>{
    return  (
      <td 
        key={col.name}
        className={`td-${col.name}`}>
        {props.data[col.name]()}
      </td>
    ) 
  }
  const renderCells = ()=>{
    return props.cols.map((col)=>{
      if (col.isRich) {
        return renderRichCell(col);
      } else if (col.onClick) {
        return renderLinkCell(col);
      } else {
        return renderBasicCell(col);
      }
    })
  }
  return (
    <tr>
      {renderCells()}
    </tr>
  );
};

export default TableRow;