import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import TermsContent from '../../Data/Legal/Terms.md'
import Markdown from '../Markdown/Markdown'


export default function StudioPage() {
  return (
    <MainLayout
      pageName='terms'>
      <div className='basicPage'>
        <div className='termsContent basicPage--content'>
          <Markdown file={TermsContent} />
        </div>
      </div>
    </MainLayout>
  )
}
